<template>
  <div id="credit-report-display" class="md:p-3">
    <div v-if="reportLoaded" class="container mx-auto max-w-7xl">
      <BureauTabs :bureau="bureau" @select-bureau="(newBureau) => (bureau = newBureau)" />
      <div class="p-3 mb-10 border border-t-0 rounded-b-md">

        <ReportSummary :bureau="bureau" />
        <Tradelines :bureau="bureau" />
        <Inquiries :bureau="bureau" />
      </div>
    </div>
    <MissingCreditReport v-else :creditReportError="store.creditReportError" />
  </div>
</template>

<script>
import { store } from "../store.ts";
import ReportSummary from './section_summary.vue'
import Tradelines from './section_tradelines.vue'
import Inquiries from './section_inquiries.vue'
import BureauTabs from "../bureau_tabs.vue";
import MissingCreditReport from "../missing_credit_report.vue";

export default {
  name: "CreditReportDisplay",
  props: [
  ],
  components: {
    ReportSummary,
    Tradelines,
    Inquiries,
    BureauTabs,
    MissingCreditReport,
  },
  computed: {
    reportLoaded: function(){
      return store.creditReport !== null && Object.keys(store.creditReport).length !== 0
    }
  },
  data: function(){
    return {
      bureau: "tuc",
      store
    }
  }
}
</script>
