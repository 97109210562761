<template>
  <div class="container mx-auto">
    <div class="flex gap-3xl">
      <div class="flex-grow">
        <div class="flex md:flex-row flex-col gap-xl">
          <div class="flex-shrink">
            <h3>Payment History</h3>
            <p class="my-sm">
              Timely and consistent payment history is one of the most critical factors that lenders consider when
              evaluating your creditworthiness. Your payment history accounts for a significant portion of your credit
              score and plays a crucial role in determining your ability to secure future loans and credit.
            </p>
          </div>
          <div style="min-width: 200px">
            <div v-if="store.creditReport">
              <FactorSummary
                :displayValue="totalOnTimePercentage"
                :statusText="store.scoreFactors.paymentHistory.status"
                :lastUpdated="store.creditReport.reportDate[bureau]"
              />
            </div>
          </div>
        </div>

        <div class="my-sm">
          <BureauTabs :bureau="bureau" @select-bureau="(newBureau) => (bureau = newBureau)" />

          <div v-if="store.creditReport" class="bg-white p-xl">
            <table class="table table-noborder w-full table-fixed">
              <thead>
                <tr>
                  <th class="comment !font-medium text-left">Account</th>
                  <th class="comment !font-medium text-right">Total</th>
                  <th class="comment !font-medium text-right">Missed</th>
                  <th class="comment !font-medium text-right">On Time</th>
                </tr>
              </thead>
              <tbody class="text-pure-black">
                <tr v-for="(tradeline, i) in tradelines" :key="i">
                  <td class="font-bold">{{ tradeline[this.bureau].creditorName }}</td>
                  <td class="text-right">
                    {{ tradeline[this.bureau].monthsReviewed }}
                  </td>
                  <td class="text-right">
                    {{
                      tradeline[this.bureau].late30Count +
                      tradeline[this.bureau].late60Count +
                      tradeline[this.bureau].late90Count
                    }}
                  </td>
                  <td class="text-right">{{ onTimePercentage(tradeline[this.bureau]) }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <h3 class="my-md text-black">How to maintain a strong<br />payment history:</h3>
        <div class="grid md:grid-cols-4 grid-cols-1 gap-sm">
          <div>
            <strong>Pay On Time</strong>
            <p>
              Ensure that you make all payments on or before their due dates, including credit card bills, loan
              installments, and utility bills.
            </p>
          </div>
          <div>
            <strong>Set Up Payment Reminders</strong>
            <p>Consider setting up payment reminders or automatic payments to avoid forgetting due dates.</p>
          </div>
          <div>
            <strong>Budget Wisely</strong>
            <p>Manage your finances effectively to ensure you have enough funds to cover your financial obligations.</p>
          </div>
          <div>
            <strong>Communicate with Lenders</strong>
            <p>
              If you're facing financial difficulties and anticipate payment issues, contact your lenders to discuss
              options or payment arrangements.
            </p>
          </div>
        </div>
      </div>
      <div style="min-width: 265px">
        <SideNav />

        <div class="border-l-4 border-gray-800 p-sm my-sm">
          <p class="comment-s font-bold mb-sm">
            Payment history can be estimated using the number of on time payments you made divided by the number of
            payments you were responsible to make.
          </p>

          <div class="flex gap-sm">
            <div class="text-center flex-grow">
              <div>On time payments</div>
              <hr style="border-color: black; margin: 2px 0" />
              <div>Total payments</div>
            </div>
            <div class="py-sm">=</div>
            <div class="text-center flex-grow">
              <div class="h4 -mt-3">%</div>
              <div>on time</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { store } from "../store";
import SideNav from "./side_nav.vue";
import BureauTabs from "../bureau_tabs.vue";
import FactorSummary from "./factor_summary.vue";

export default {
  components: {
    SideNav,
    BureauTabs,
    FactorSummary,
  },
  data() {
    return {
      bureau: "tuc",
      store,
    };
  },
  computed: {
    tradelines() {
      return store.creditReport.tradelines.filter((tradeline) => {
        return tradeline[this.bureau].paymentHistory && tradeline[this.bureau].paymentHistory.length > 0;
      });
    },
    totalOnTimePayments() {
      return this.tradelines.reduce((total, tradeline) => {
        return (
          total +
          tradeline[this.bureau].monthsReviewed -
          (tradeline[this.bureau].late30Count + tradeline[this.bureau].late60Count + tradeline[this.bureau].late90Count)
        );
      }, 0);
    },
    totalPayments() {
      return this.tradelines.reduce((total, tradeline) => {
        return total + tradeline[this.bureau].monthsReviewed;
      }, 0);
    },
    totalOnTimePercentage() {
      if (this.totalPayments > 0) {
        return `${Math.round((this.totalOnTimePayments * 100) / this.totalPayments)}%`;
      } else {
        return "-";
      }
    },
  },
  methods: {
    onTimePercentage(data) {
      if (data.monthsReviewed > 0) {
        const onTimeCount = data.monthsReviewed - (data.late30Count + data.late60Count + data.late90Count);
        return `${Math.round((onTimeCount * 100) / data.monthsReviewed)}%`;
      } else {
        return "-";
      }
    },
  },
};
</script>
