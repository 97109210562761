<template>
  <div id="credit-report-print-view" v-if="store.creditReport" class="p-1 md:p-5">
    <div class="my-5">
      <h1 class="text-3xl font-semibold text-center">Credit Report</h1>
      <div class="text-sm text-center text-gray-500">
        Fetched:
        <span data-field="general-report-date">{{ reportDate }}</span>
      </div>
    </div>

    <!-- Account Summary -->
    <div class="grid grid-cols-3 gap-md">
      <div class="card">
        <div class="card-body p-md">
          <h5>Account Summary</h5>

          <table>
            <tbody>
              <tr>
                <td>Open Accounts</td>
                <td class="text-right">{{ store.creditReport.summary.tradelineSummary.tuc.openAccounts }}</td>
              </tr>
              <tr>
                <td>Self-reported Accounts</td>
                <td class="text-right">0</td>
              </tr>
              <tr>
                <td>Accounts Ever Late</td>
                <td class="text-right">{{ store.creditReport.summary.tradelineSummary.tuc.delinquentAccounts }}</td>
              </tr>
              <tr>
                <td>Closed Accounts</td>
                <td class="text-right">{{ store.creditReport.summary.tradelineSummary.tuc.closedAccounts }}</td>
              </tr>
              <tr>
                <td>Collections</td>
                <td class="text-right">{{ openCollectionAccounts.length }}</td>
              </tr>
              <tr>
                <td>Oldest Account</td>
                <td class="text-right">{{ oldestOpenAccountAge }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="card" v-if="canCalculateUtilization">
        <div class="card-body p-md">
          <h5>Overall Credit Usage</h5>

          <h1 class="text-center my-xl">{{ totalUtilization }}</h1>

          <div class="text-center">
            <div>
              Credit used: <span class="font-semibold">{{ usd(totalRevolvingAccountBalance) }}</span>
            </div>
            <div>
              Credit available: <span class="font-semibold">{{ usd(totalRevolvingAccountLimit) }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-body p-md">
          <h5>Debt Summary</h5>

          <table>
            <tbody>
              <tr>
                <td>Credit card and credit line debt</td>
                <td class="text-right">{{ usd(totalRevolvingAccountBalance) }}</td>
              </tr>
              <tr>
                <td>Loan debt</td>
                <td class="text-right">{{ usd(totalLoansDebt) }}</td>
              </tr>
              <tr>
                <td>Collections debt</td>
                <td class="text-right">{{ usd(totalCollectionsDebt) }}</td>
              </tr>
              <tr>
                <td>Total debt</td>
                <td class="text-right">{{ usd(totalRevolvingAccountBalance + totalLoansDebt + totalCollectionsDebt) }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div class="pagebreak"></div>

    <h2 class="mt-5 text-2xl font-semibold section-title">Personal Information</h2>
    <div class="section-description">
      Below is your personal information as it appears in your credit file. This information includes your legal name,
      current and previous addresses, employment information and other details.
    </div>
    <div class="card mt-3">
      <div class="card-body p-md">
        <table class="table w-full" :class="store.creditReport.singleBureauReport ? '' : 'table-fixed'">
          <thead v-if="!store.creditReport.singleBureauReport">
            <tr>
              <th></th>
              <th v-for="(bureau, i) in bureaus" :key="i">{{ bureauNames[bureau] }}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="w-10 font-bold text-right whitespace-nowrap">Report Date:</td>
              <td v-for="(bureau, i) in bureaus" :key="i" :data-bureau="bureau" data-field="report-date">
                {{ formatDate(store.creditReport.reportDate[bureau], "MM/DD/YYYY") }}
              </td>
            </tr>
            <tr>
              <td class="w-10 font-bold text-right whitespace-nowrap">Name:</td>
              <td v-for="(bureau, i) in bureaus" :key="i" :data-bureau="bureau" data-field="borrower-name">
                {{ store.creditReport.borrower.name[bureau] }}
              </td>
            </tr>

            <tr>
              <td class="w-10 font-bold text-right whitespace-nowrap">Alias(es):</td>
              <td v-for="(bureau, i) in bureaus" :key="i">
                <div v-for="(alias, j) in store.creditReport.borrower.aliases[bureau]" :key="j"
                  data-field="borrower-alias" :data-bureau="bureau">
                  {{ alias }}
                </div>
                <div v-if="store.creditReport.borrower.aliases[bureau].length == 0">-</div>
              </td>
            </tr>
            <tr>
              <td class="w-10 font-bold text-right whitespace-nowrap">Date of Birth:</td>
              <td v-for="(bureau, i) in bureaus" :key="i" :data-bureau="bureau" data-field="borrower-date-of-birth">
                {{ formatDate(store.creditReport.borrower.dateOfBirth[bureau], "MM/DD/YYYY") }}
              </td>
            </tr>
            <tr>
              <td class="w-10 font-bold text-right whitespace-nowrap">Current Address(es):</td>
              <td v-for="(bureau, i) in bureaus" :key="i">
                <StreetAddress class="" :bureau="bureau" :address="store.creditReport.borrower.currentAddress[bureau]"
                  data-field="borrower-current-address" />
              </td>
            </tr>
            <tr>
              <td class="w-10 font-bold text-right whitespace-nowrap">Previous Address(es):</td>
              <td v-for="(bureau, i) in bureaus" :key="i">
                <template v-for="(address, j) in store.creditReport.borrower.previousAddresses[bureau]" :key="j">
                  <StreetAddress class="mb-3" :bureau="bureau" :address="address"
                    data-field="borrower-previous-address" />
                </template>
                <div v-if="store.creditReport.borrower.previousAddresses[bureau].length == 0">-</div>
              </td>
            </tr>
            <tr>
              <td class="w-10 font-bold text-right whitespace-nowrap">Employer(s):</td>
              <td v-for="(bureau, i) in bureaus" :key="i">
                <div data-field="borrower-employer" :data-bureau="bureau"
                  v-for="(employer, j) in store.creditReport.borrower.employers[bureau]" :key="j">
                  {{ employer.name }}
                </div>
                <div v-if="store.creditReport.borrower.employers[bureau].length == 0">-</div>
              </td>
            </tr>
          </tbody>
        </table>

      </div>
    </div>

    <div class="pagebreak"></div>

    <h2 class="mt-5 text-2xl font-semibold section-title">Credit Score</h2>
    <div class="section-description">
      Your Credit Score is a representation of your overall credit health. Most lenders utilize some form of credit
      scoring to help determine your credit worthiness.
    </div>

    <div class="card mt-3">
      <div class="card-body p-md">
        <table class="table w-full" :class="store.creditReport.singleBureauReport ? '' : 'table-fixed'">
          <thead v-if="!store.creditReport.singleBureauReport">
            <tr>
              <th></th>
              <th v-for="(bureau, i) in bureaus" :key="i">{{ bureauNames[bureau] }}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="w-10 font-bold text-right whitespace-nowrap">Credit Score:</td>
              <td v-for="(bureau, i) in bureaus" :key="i" :data-bureau="bureau" data-field="credit-score">
                {{ store.creditReport.creditScore[bureau] }}
              </td>
            </tr>
            <tr>
              <td class="w-10 font-bold text-right whitespace-nowrap">Score Assessment:</td>
              <td v-for="(bureau, i) in bureaus" :key="i" :data-bureau="bureau" data-field="credit-score-rank">
                {{ creditScoreStatus(store.creditReport.creditScore[bureau]) }}
              </td>
            </tr>
            <tr>
              <td class="w-10 font-bold text-right whitespace-nowrap">Score Scale:</td>
              <td v-for="(bureau, i) in bureaus" :key="i">300 - 850</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <h3 class="mt-5 text-xl font-semibold section-subtitle">Risk Factors</h3>


    <div class="card mt-3">
      <div class="card-body p-md">
        <div v-for="(bureau, i) in bureaus" :key="i">
          <h3 class="mb-5 text-lg font-semibold section-subtitle">{{ bureauNames[bureau] }}</h3>

          <ul class="ml-5 list-decimal list-style-outside">
            <li v-for="(creditScoreFactor, j) in store.creditReport.creditScoreFactors[bureau]" :key="j" class="mb-3"
              :data-bureau="bureau" data-field="risk-factor">
              <div class="mb-1 font-medium">{{ creditScoreFactor.factor }}</div>
              <div class="text-sm text-zinc-700" v-if="creditScoreFactor.explain">{{ creditScoreFactor.explain }}</div>
            </li>
          </ul>
          <div v-if="store.creditReport.creditScoreFactors[bureau].length == 0">No items to display</div>
        </div>
      </div>
    </div>
    <h2 class="mt-5 text-2xl font-semibold section-title">Summary</h2>
    <div class="section-description">
      Below is an overview of your present and past credit status including open and closed accounts and balance
      information across all bureaus.
    </div>
    <table class="table w-full" :class="store.creditReport.singleBureauReport ? '' : 'table-fixed'">
      <thead v-if="!store.creditReport.singleBureauReport">
        <tr>
          <th></th>
          <th v-for="(bureau, i) in bureaus" :key="i">{{ bureauNames[bureau] }}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="w-10 font-bold text-right whitespace-nowrap">Total Accounts:</td>
          <td v-for="(bureau, i) in bureaus" :key="i" :data-bureau="bureau" data-field="summary-total-accounts">
            {{ store.creditReport.summary.tradelineSummary[bureau].totalAccounts }}
          </td>
        </tr>
        <tr>
          <td class="w-10 font-bold text-right whitespace-nowrap">Open Accounts:</td>
          <td v-for="(bureau, i) in bureaus" :key="i" :data-bureau="bureau" data-field="summary-open-accounts">
            {{ store.creditReport.summary.tradelineSummary[bureau].openAccounts }}
          </td>
        </tr>
        <tr>
          <td class="w-10 font-bold text-right whitespace-nowrap">Closed Accounts:</td>
          <td v-for="(bureau, i) in bureaus" :key="i" :data-bureau="bureau" data-field="summary-closed-accounts">
            {{ store.creditReport.summary.tradelineSummary[bureau].closedAccounts }}
          </td>
        </tr>
        <tr>
          <td class="w-10 font-bold text-right whitespace-nowrap">Delinquent Accounts:</td>
          <td v-for="(bureau, i) in bureaus" :key="i" :data-bureau="bureau" data-field="summary-delinquent-accounts">
            {{ store.creditReport.summary.tradelineSummary[bureau].delinquentAccounts }}
          </td>
        </tr>
        <tr>
          <td class="w-10 font-bold text-right whitespace-nowrap">Derogatory Accounts:</td>
          <td v-for="(bureau, i) in bureaus" :key="i" :data-bureau="bureau" data-field="summary-derogatory-accounts">
            {{ store.creditReport.summary.tradelineSummary[bureau].derogatoryAccounts }}
          </td>
        </tr>
        <tr>
          <td class="w-10 font-bold text-right whitespace-nowrap">Total Balance:</td>
          <td v-for="(bureau, i) in bureaus" :key="i" :data-bureau="bureau" data-field="summary-total-balance">
            {{ usd(store.creditReport.summary.tradelineSummary[bureau].totalBalance) }}
          </td>
        </tr>
        <tr>
          <td class="w-10 font-bold text-right whitespace-nowrap">Total Monthly Payments:</td>
          <td v-for="(bureau, i) in bureaus" :key="i" :data-bureau="bureau" data-field="summary-total-monthly-payments">
            {{ usd(store.creditReport.summary.tradelineSummary[bureau].totalMonthlyPayments) }}
          </td>
        </tr>
      </tbody>
    </table>

    <div class="pagebreak"></div>

    <h2 class="mt-5 text-2xl font-semibold section-title">Public Records</h2>
    <div class="section-description">Information on your public records including bankruptcy filings, court records, tax
      liens & other monetary judgements is displayed below. This information typically remains in your credit report for
      up
      to 10 years.</div>

    <div class="card mt-3">
      <div class="card-body p-md">
        <div class="mt-5 public-records-details" v-for="(publicRecord, i) in store.creditReport.publicRecords" :key="i">
          <div class="p-2 text-lg bg-blue-200">
            <span data-field="public-record-general-type">{{ publicRecord.type }}</span>
          </div>
          <table class="table w-full" :class="store.creditReport.singleBureauReport ? '' : 'table-fixed'">
            <thead v-if="!store.creditReport.singleBureauReport">
              <tr>
                <th></th>
                <th v-for="(bureau, i) in bureaus" :key="i">{{ bureauNames[bureau] }}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="w-10 font-semibold text-right whitespace-nowrap">Type:</td>
                <td v-for="(bureau, i) in bureaus" :key="i" :data-bureau="bureau" data-field="public-record-type">
                  {{ publicRecord[bureau].type }}
                </td>
              </tr>
              <tr>
                <td class="w-10 font-semibold text-right whitespace-nowrap">Status:</td>
                <td v-for="(bureau, i) in bureaus" :key="i" :data-bureau="bureau" data-field="public-record-status">
                  {{ publicRecord[bureau].status }}
                </td>
              </tr>
              <tr>
                <td class="w-10 font-semibold text-right whitespace-nowrap">Date Filed/Reported:</td>
                <td v-for="(bureau, i) in bureaus" :key="i" :data-bureau="bureau" data-field="public-record-date-filed">
                  {{ formatDate(publicRecord[bureau].dateFiled, "MM/DD/YYYY") }}
                </td>
              </tr>
              <tr>
                <td class="w-10 font-semibold text-right whitespace-nowrap">Court:</td>
                <td v-for="(bureau, i) in bureaus" :key="i" :data-bureau="bureau" data-field="public-record-court">
                  {{ publicRecord[bureau].court }}
                </td>
              </tr>
              <tr>
                <td class="w-10 font-semibold text-right whitespace-nowrap">Reference:</td>
                <td v-for="(bureau, i) in bureaus" :key="i" :data-bureau="bureau" data-field="public-record-reference">
                  {{ publicRecord[bureau].reference }}
                </td>
              </tr>
              <tr>
                <td class="w-10 font-semibold text-right whitespace-nowrap">Asset Amount:</td>
                <td v-for="(bureau, i) in bureaus" :key="i" :data-bureau="bureau"
                  data-field="public-record-asset-amount">
                  {{ usd(publicRecord[bureau].assetAmount) }}
                </td>
              </tr>
              <tr>
                <td class="w-10 font-semibold text-right whitespace-nowrap">Liability Amount:</td>
                <td v-for="(bureau, i) in bureaus" :key="i" :data-bureau="bureau"
                  data-field="public-record-liability-amount">
                  {{ usd(publicRecord[bureau].liabilityAmount) }}
                </td>
              </tr>
              <tr>
                <td class="w-10 font-semibold text-right whitespace-nowrap">Exempt Amount:</td>
                <td v-for="(bureau, i) in bureaus" :key="i" :data-bureau="bureau"
                  data-field="public-record-exempt-amount">
                  {{ usd(publicRecord[bureau].exemptAmount) }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div v-if="store.creditReport.publicRecords.length == 0" class="text-center text-gray-500">
          There are no public records to display.
        </div>
      </div>
    </div>

    <div class="pagebreak"></div>

    <h2 class="mt-5 text-2xl font-semibold section-title">Account History</h2>
    <div class="section-description">Information on accounts you have opened in the past is displayed below.</div>

    <div class="mt-5 bg-white rounded-md p-md tradeline-details" v-for="(tradeline, i) in store.creditReport.tradelines"
      :key="i">
      <div class="grid grid-cols-2">
        <div>
          <div class="mb-2 font-semibold">
            <span data-field="tradeline-general-creditor-name">{{ tradelineCreditorName(tradeline) }}</span>
            <span v-if="tradelineOriginalCreditorName(tradeline)" class="ml-2 text-sm">
              <span>
                (Original Creditor:
                <span data-field="tradeline-general-original-creditor-name">
                  {{ tradelineOriginalCreditorName(tradeline) }}
                </span>
                )
              </span>
            </span>
          </div>
        </div>
        <div class="text-right">
          Balance: <span class="font-semibold">{{ usd(tradeline.tuc.balanceAmount) }}</span>
        </div>
        <div></div>
        <div class="text-right">
          Balance updated: <span class="font-semibold">{{ formatDate(tradeline.tuc.dateLastPayment, "MM/DD/YYYY")
            }}</span>
        </div>
      </div>

      <div class="border-t my-3"></div>

      <table class="table w-full text-xs" :class="store.creditReport.singleBureauReport ? '' : 'table-fixed'">
        <thead v-if="!store.creditReport.singleBureauReport">
          <tr>
            <th></th>
            <th v-for="(bureau, i) in bureaus" :key="i">{{ bureauNames[bureau] }}</th>
          </tr>
        </thead>
        <tbody class="print:table-compact">
          <tr>
            <td class="w-10 font-semibold text-right whitespace-nowrap">Creditor:</td>
            <td v-for="(bureau, i) in bureaus" :key="i" :data-bureau="bureau" data-field="tradeline-creditor-name">
              {{ tradeline[bureau].creditorName }}
            </td>
          </tr>
          <tr>
            <td class="w-10 font-semibold text-right whitespace-nowrap">Original Creditor:</td>
            <td v-for="(bureau, i) in bureaus" :key="i" :data-bureau="bureau" data-field="tradeline-creditor-name">
              {{ tradeline[bureau].originalCreditorName }}
            </td>
          </tr>
          <tr>
            <td class="w-10 font-semibold text-right whitespace-nowrap">Account #:</td>
            <td v-for="(bureau, i) in bureaus" :key="i" :data-bureau="bureau" data-field="tradeline-account-number">
              {{ tradeline[bureau].accountNumber }}
            </td>
          </tr>

          <tr>
            <td class="w-10 font-semibold text-right whitespace-nowrap">Account Type:</td>
            <td v-for="(bureau, i) in bureaus" :key="i" :data-bureau="bureau" data-field="tradeline-account-type">
              {{ tradeline[bureau].accountType }}
            </td>
          </tr>

          <tr>
            <td class="w-10 font-semibold text-right whitespace-nowrap">Type:</td>
            <td v-for="(bureau, i) in bureaus" :key="i" :data-bureau="bureau" data-field="tradeline-account-designator">
              {{ tradeline[bureau].accountDesignator }}
            </td>
          </tr>

          <tr>
            <td class="w-10 font-semibold text-right whitespace-nowrap">Account Condition:</td>
            <td v-for="(bureau, i) in bureaus" :key="i" :data-bureau="bureau" data-field="tradeline-account-condition">
              {{ tradeline[bureau].accountCondition }}
            </td>
          </tr>
          <tr>
            <td class="w-10 font-semibold text-right whitespace-nowrap">Credit Type:</td>
            <td v-for="(bureau, i) in bureaus" :key="i" :data-bureau="bureau" data-field="tradeline-credit-type">
              {{ tradeline[bureau].creditType }}
            </td>
          </tr>
          <tr>
            <td class="w-10 font-semibold text-right whitespace-nowrap">Loan Type:</td>
            <td v-for="(bureau, i) in bureaus" :key="i" :data-bureau="bureau" data-field="tradeline-loan-type">
              {{ tradeline[bureau].loanType }}
            </td>
          </tr>
          <tr>
            <td class="w-10 font-semibold text-right whitespace-nowrap">Payment Status:</td>
            <td v-for="(bureau, i) in bureaus" :key="i" :data-bureau="bureau">
              <div :class="tradeline[bureau].paymentStatus == 'Current' ? 'text-lime-600' : 'text-red-600'"
                data-field="tradeline-payment-status">
                {{ tradeline[bureau].paymentStatus }}
              </div>
            </td>
          </tr>
          <tr>
            <td class="w-10 font-semibold text-right whitespace-nowrap">Account Status:</td>
            <td v-for="(bureau, i) in bureaus" :key="i" :data-bureau="bureau"
              data-field="tradeline-account-open-closed">
              {{ tradeline[bureau].accountOpenClosed }}
            </td>
          </tr>
          <tr>
            <td class="w-10 font-semibold text-right whitespace-nowrap">Monthly Payment Amount:</td>
            <td v-for="(bureau, i) in bureaus" :key="i" :data-bureau="bureau"
              data-field="tradeline-monthly-payment-amount">
              {{ usd(tradeline[bureau].monthlyPaymentAmount) }}
            </td>
          </tr>
          <tr>
            <td class="w-10 font-semibold text-right whitespace-nowrap">Date Opened:</td>
            <td v-for="(bureau, i) in bureaus" :key="i" :data-bureau="bureau" data-field="tradeline-date-opened">
              {{ formatDate(tradeline[bureau].dateOpened, "MM/DD/YYYY") }}
            </td>
          </tr>
          <tr>
            <td class="w-10 font-semibold text-right whitespace-nowrap">Date Closed:</td>
            <td v-for="(bureau, i) in bureaus" :key="i" :data-bureau="bureau" data-field="tradeline-date-closed">
              {{ formatDate(tradeline[bureau].dateClosed, "MM/DD/YYYY") }}
            </td>
          </tr>
          <tr>
            <td class="w-10 font-semibold text-right whitespace-nowrap">Date Reported:</td>
            <td v-for="(bureau, i) in bureaus" :key="i" :data-bureau="bureau" data-field="tradeline-date-reported">
              {{ formatDate(tradeline[bureau].dateReported, "MM/DD/YYYY") }}
            </td>
          </tr>
          <tr>
            <td class="w-10 font-semibold text-right whitespace-nowrap">Last Payment Date:</td>
            <td v-for="(bureau, i) in bureaus" :key="i" :data-bureau="bureau" data-field="tradeline-date-last-payment">
              {{ formatDate(tradeline[bureau].dateLastPayment, "MM/DD/YYYY") }}
            </td>
          </tr>
          <tr>
            <td class="w-10 font-semibold text-right whitespace-nowrap">Last Active Date:</td>
            <td v-for="(bureau, i) in bureaus" :key="i" :data-bureau="bureau" data-field="tradeline-date-last-active">
              {{ formatDate(tradeline[bureau].dateLastActive, "MM/DD/YYYY") }}
            </td>
          </tr>
          <tr>
            <td class="w-10 font-semibold text-right whitespace-nowrap">Balance Amount:</td>
            <td v-for="(bureau, i) in bureaus" :key="i" :data-bureau="bureau" data-field="tradeline-balance-amount">
              {{ usd(tradeline[bureau].balanceAmount) }}
            </td>
          </tr>
          <tr>
            <td class="w-10 font-semibold text-right whitespace-nowrap">Term Duration (Months):</td>
            <td v-for="(bureau, i) in bureaus" :key="i" :data-bureau="bureau"
              data-field="tradeline-term-duration-months">
              {{ tradeline[bureau].termDurationMonths }}
            </td>
          </tr>
          <tr>
            <td class="w-10 font-semibold text-right whitespace-nowrap">High Balance Amount:</td>
            <td v-for="(bureau, i) in bureaus" :key="i" :data-bureau="bureau"
              data-field="tradeline-high-balance-amount">
              {{ usd(tradeline[bureau].highBalanceAmount) }}
            </td>
          </tr>
          <tr>
            <td class="w-10 font-semibold text-right whitespace-nowrap">High Credit Amount:</td>
            <td v-for="(bureau, i) in bureaus" :key="i" :data-bureau="bureau" data-field="tradeline-high-credit-amount">
              {{ usd(tradeline[bureau].highCreditAmount) }}
            </td>
          </tr>
          <tr>
            <td class="w-10 font-semibold text-right whitespace-nowrap">Credit Limit Amount:</td>
            <td v-for="(bureau, i) in bureaus" :key="i" :data-bureau="bureau"
              data-field="tradeline-credit-limit-amount">
              {{ usd(tradeline[bureau].creditLimitAmount) }}
            </td>
          </tr>
          <tr>
            <td class="w-10 font-semibold text-right whitespace-nowrap">Past Due Amount:</td>
            <td v-for="(bureau, i) in bureaus" :key="i" :data-bureau="bureau" data-field="tradeline-past-due-amount">
              {{ usd(tradeline[bureau].pastDueAmount) }}
            </td>
          </tr>
          <tr>
            <td class="w-10 font-semibold text-right whitespace-nowrap">Comments:</td>
            <td v-for="(bureau, i) in bureaus" :key="i" :data-bureau="bureau" data-field="tradeline-remarks">
              <div class="mb-1" v-for="(remark, j) in tradeline[bureau].remarks" :key="j">{{ remark }}</div>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="p-3 tradeline-payment-history" v-if="tradeline.paymentHistoryDates.length > 0">
        <div class="font-bold">Payment History</div>

        <div class="screen:overflow-x-auto">
          <table class="table w-auto mt-3 text-sm">
            <thead>
              <tr>
                <th class="px-2 text-xs text-center border-r !border-gray-300 whitespace-nowrap"></th>

                <template v-for="(date, i) in tradeline.paymentHistoryDates" :key="i">
                  <th class="px-2 text-xs text-center border-r !border-gray-300 whitespace-nowrap">
                    {{ formatDate(date, "MMM 'YY") }}
                  </th>
                </template>
              </tr>
            </thead>
            <tbody class="table-compact">
              <tr v-for="(bureau, i) in bureaus" :key="i">
                <td class="px-2 text-xs font-bold border-r !border-gray-300 whitespace-nowrap">
                  {{ bureauNames[bureau] }}
                </td>

                <template v-for="(date, i) in tradeline.paymentHistoryDates" :key="i">
                  <td :data-payment-history-date="date" :data-bureau="bureau"
                    data-field="tradeline-payment-history-status" class="px-2 text-center border-r"
                    :class="paymentStatusClass(statusForDate(tradeline[bureau].paymentHistory, date))">
                    {{ paymentStatusText(statusForDate(tradeline[bureau].paymentHistory, date)) }}
                  </td>
                </template>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="p-3">
        <div class="font-bold">Contact Info</div>

        <table>
          <tr>
            <td class="font-semibold">Address</td>
            <td>
              <StreetAddress bureau="tuc" :address="tradeline.tuc.contactAddress" />
            </td>
          </tr>
          <tr>
            <td class="font-semibold">Phone Number</td>
            <td>{{ formatPhone(tradeline.tuc.contactPhone) }}</td>
          </tr>
        </table>
      </div>


      <div class="pagebreak"></div>
    </div>
    <h2 class="mt-5 text-2xl font-semibold section-title">Inquiries</h2>
    <div class="section-description">
      Below are the names of people and/or organizations who have obtained a copy of your Credit Report. Inquiries such
      as these can remain on your credit file for up to two years.
    </div>
    <div v-for="(bureau, i) in bureaus" :key="i" class="print:break-inside-avoid-page">
      <h3 class="mt-5 text-lg font-semibold section-subtitle">{{ bureauNames[bureau] }}</h3>
      <table class="table w-full" :class="store.creditReport.singleBureauReport ? '' : 'table-fixed'">
        <thead>
          <tr>
            <th>Creditor Name</th>
            <th>Industry</th>
            <th>Date</th>
          </tr>
        </thead>
        <tbody>
          <tr class="inquiry-details" v-for="(inquiry, i) in store.creditReport.inquiries[bureau]" :key="i"
            :data-bureau="bureau">
            <td data-field="inquiry-name">{{ inquiry.name }}</td>
            <td data-field="inquiry-industry">{{ inquiry.industry || "-" }}</td>
            <td data-field="inquiry-date">{{ formatDate(inquiry.date, "MM/DD/YYYY") }}</td>
          </tr>
        </tbody>
        <tfoot v-if="store.creditReport.inquiries[bureau].length == 0">
          <tr>
            <td colspan="3" class="text-center text-gray-500">There are no recent inquiries in your credit report.</td>
          </tr>
        </tfoot>
      </table>
    </div>
    <!-- <h2 class="mt-5 text-2xl font-semibold section-title">Public Information</h2>
    <div class="section-description">
      Below is an overview of your public records and can include details of bankruptcy filings, court records, tax
      liens and other monetary judgments. Public records typically remain on your Credit Report for 7 - 10 years.
    </div>
    <h2 class="mt-5 text-2xl font-semibold section-title">Creditor Contacts</h2>
    <div class="section-description">
      Information about how to contact people and/or organizations that appear on this credit report is listed below.
    </div> -->
    <div class="my-6 text-xs text-center text-gray-500">
      Report Reference:
      <span data-field="report-reference">{{ store.profile.currentReportID }}</span>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { store } from "../store.ts";
import PaymentStatusDetails from "../../../lib/payment_status_details";
import StreetAddress from "./street_address.vue";

export default {
  name: "CreditReportPrintView",
  components: {
    StreetAddress,
  },
  data: function () {
    return {
      bureauNames: {
        tuc: "TransUnion",
        eqf: "Equifax",
        exp: "Experian",
      },
      store,
    };
  },
  computed: {
    bureaus() {
      if (store.creditReport.singleBureauReport) {
        return ["tuc"];
      } else {
        return ["tuc", "eqf", "exp"];
      }
    },
    reportDate() {
      return this.formatDate(store.profile.reportDate, "MMMM D, YYYY");
    },
    canCalculateUtilization() {
      return this.totalRevolvingAccountLimit > 0;
    },
    totalRevolvingAccountBalance() {
      return this.openRevolvingAccounts
        .map((c) => c.balanceAmount)
        .filter(Number)
        .reduce((a, b) => a + b, 0);
    },
    totalCollectionsDebt() {
      if (!store.creditReport) {
        return 0
      }

      return this.openCollectionAccounts.map((c) => c.balanceAmount)
        .filter(Number)
        .reduce((a, b) => a + b, 0);
    },
    totalLoansDebt() {
      if (!store.creditReport) {
        return 0
      }

      return store.creditReport.tradelines
        .filter(
          (tradeline) =>
            tradeline.tuc.creditType === "Installment Account" &&
            tradeline.tuc.accountOpenClosed === "Open"
        )
        .map((tradeline) => tradeline.tuc.balanceAmount)
        .filter(Number)
        .reduce((a, b) => a + b, 0);
    },
    totalRevolvingAccountLimit() {
      return this.openRevolvingAccounts
        .map((c) => c.creditLimitAmount)
        .filter(Number)
        .reduce((a, b) => a + b, 0);
    },
    totalUtilization() {
      if (this.totalRevolvingAccountLimit > 0) {
        return `${Math.round((this.totalRevolvingAccountBalance * 100) / this.totalRevolvingAccountLimit)}%`;
      } else {
        return "-";
      }
    },
    openCollectionAccounts() {
      if (!store.creditReport) {
        return [];
      }

      return store.creditReport.tradelines
        .filter(
          (tradeline) =>
            tradeline.tuc.creditType === "Collection Account" &&
            tradeline.tuc.accountOpenClosed === "Open"
        )
        .map((tradeline) => tradeline.tuc);
    },
    openRevolvingAccounts() {
      if (!store.creditReport) {
        return [];
      }

      return store.creditReport.tradelines
        .filter(
          (tradeline) =>
            tradeline.tuc.creditType === "Revolving Account" &&
            tradeline.tuc.accountOpenClosed === "Open" &&
            tradeline.tuc.creditLimitAmount > 0
        )
        .map((tradeline) => tradeline.tuc);
    },
    openTradelines() {
      if (!store.creditReport) {
        return [];
      }

      return store.creditReport.tradelines.filter((x) => x.tuc.accountOpenClosed == "Open");
    },
    oldestOpenAccountDate() {
      // Since the dateOpened string is always in YYYY-MM-DD format, we can just
      // sort the array and return the first element.
      return this.openTradelines.map((x) => x.tuc.dateOpened).sort()[0];
    },
    oldestOpenAccountAge() {
      return this.distanceOfTime(this.oldestOpenAccountDate);
    },
  },
  methods: {
    distanceOfTime(isoDateString) {
      if (!isoDateString || !isoDateString.match(/^\d{4}-\d{2}-\d{2}$/)) {
        return null;
      }

      const d = moment(isoDateString);
      return moment().diff(d, "years", true).toFixed(1) + " years";
    },
    tradelineCreditorName(tradeline) {
      return tradeline.tuc.creditorName || tradeline.eqf.creditorName || tradeline.exp.creditorName || "N/A";
    },
    tradelineOriginalCreditorName(tradeline) {
      return (
        tradeline.tuc.originalCreditorName || tradeline.eqf.originalCreditorName || tradeline.exp.originalCreditorName
      );
    },
    statusForDate(paymentHistoryData, date) {
      return paymentHistoryData.find((paymentData) => {
        return paymentData.monthDate == date;
      })?.status;
    },
    paymentStatusClass(status) {
      return PaymentStatusDetails[status]?.class || "text-gray-400";
    },
    paymentStatusText(status) {
      return PaymentStatusDetails[status]?.text || "";
    },
    paymentStatusDescription(status) {
      return PaymentStatusDetails[status]?.details || "Unknown";
    },
    creditScoreStatus(creditScore) {
      if (!creditScore) {
        return "-";
      }

      if (creditScore > 719) {
        return "Good";
      } else if (creditScore > 629) {
        return "Fair";
      } else {
        return "Poor";
      }
    },
  },
};
</script>
