<template>
  <div class="container mx-auto">
    <div class="grid grid-cols-1 md:gap-4 md:mt-12 md:grid-cols-3">
      <div class="col-span-2">
        <div>
          <div class="mb-3 text-4xl font-semibold">Credit Utilization</div>
          <p>
            Credit utilization is a crucial factor in your credit score and is a measure of how much of your available
            credit you are currently using. It's typically expressed as a percentage and is calculated by dividing your
            credit card balances by your credit card limits. Lenders do indeed pay close attention to your credit
            utilization because it can provide insights into your financial responsibility and risk as a borrower.
          </p>
        </div>

        <div class="my-6 text-sm font-bold text-gray-500">
          <span>Factors</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="inline-block w-3 h-3 mx-2">
            <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3" />
          </svg>
          <span>Credit Utilization</span>
        </div>

        <div class="text-xl font-semibold">How is your credit utilization calculated?</div>
        <p>
          All of the credit card balances are added up, and divided by the sum of credit card limits, to yield a
          percentage of usage.
        </p>

        <div class="my-5 text-center">
          <div class="flex justify-center gap-2">
            <div>
              <div class="text-2xl text-gray-500" v-if="canCalculateUtilization">
                {{ usd(totalBalance) }}
              </div>
              <div class="font-semibold">Credit card balance</div>
            </div>
            <div class="flex flex-col"><div class="mt-auto">/</div></div>
            <div>
              <div class="text-2xl text-gray-500" v-if="canCalculateUtilization">
                {{ usd(totalLimit) }}
              </div>
              <div class="font-semibold">Credit card limit</div>
            </div>
            <div class="flex flex-col"><div class="mt-auto">=</div></div>
            <div>
              <div class="text-2xl text-gray-500" v-if="canCalculateUtilization">
                {{ totalUtilization }}
              </div>
              <div class="font-semibold">Credit Utilization</div>
            </div>
          </div>
        </div>

        <p class="mb-5">To maintain a healthy credit utilization rate:</p>
        <p class="mb-5">
          <strong>Pay Credit Card Balances:</strong>
          Pay your credit card balances in full or keep them as low as possible. A utilization rate below 30% is
          generally recommended, but lower is better.
        </p>
        <p class="mb-5">
          <strong>Monitor Credit Limits:</strong>
          Regularly review your credit limits to ensure they accurately reflect your credit needs. You can request
          credit limit increases if necessary, which can help lower your utilization rate.
        </p>
        <p class="mb-5">
          <strong>Spread Out Expenses:</strong>
          Try to spread out your expenses across multiple credit cards rather than maxing out a single card. This can
          help keep individual card utilization low.
        </p>
        <p class="mb-5">
          <strong>Regularly Check Your Credit:</strong>
          Monitor your credit reports to ensure that your utilization rate is accurately reported. Correct any errors if
          you find discrepancies.
        </p>

        <div v-if="creditReport">
          <div class="my-3 text-xl font-semibold">Credit Utilization by Account</div>

          <table class="table w-full table-fixed">
            <thead>
              <tr>
                <th class="text-left">Account</th>
                <th class="text-right">Balance</th>
                <th class="text-right">Limit</th>
                <th class="text-right">Utilization</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(creditCard, i) in openRevolvingAccounts" :key="i">
                <td class="font-semibold">{{ creditCard.creditorName }}</td>
                <td class="text-right">
                  {{ usd(creditCard.balanceAmount) }}
                </td>
                <td class="text-right">
                  {{ usd(creditCard.creditLimitAmount) }}
                </td>
                <td class="text-right">{{ utilization(creditCard) }}</td>
              </tr>
            </tbody>
            <tfoot v-if="openRevolvingAccounts.length == 0">
              <td colspan="4" class="py-3 text-center">We weren't able to calculate a utilization for any accounts on your credit report.</td>
            </tfoot>
          </table>
        </div>
      </div>
      <div class="order-first md:order-last">
        <div v-if="canCalculateUtilization">
          <div class="rounded-md">
            <BureauTabs
              :bureau="bureau"
              @select-bureau="(newBureau) => (bureau = newBureau)" />
            <Transition name="fade" mode="out-in">
              <div
                v-bind:class="`text-center py-5 rounded-b-md border border-t-0 bg-gradient-${store.scoreFactors.utilization.status}`"
                :key="bureau">
                <div class="my-3 text-4xl font-semibold">{{ totalUtilization }}</div>
                <div class="text-sm">Updated: {{ formatDate(store.creditReport.reportDate[bureau], "MMM D, YYYY") }}</div>
                <div
                  v-bind:class="`my-3 credit-score-factor-status credit-score-factor-status-${store.scoreFactors.utilization.status}`">
                  {{ store.scoreFactors.utilization.status }}
                </div>
              </div>
            </Transition>
          </div>
        </div>
        <div v-else class="px-5 py-12 text-center text-gray-500 border rounded-md">
          We weren't able to calculate a utilization for any accounts on your credit report.
        </div>
        <SideNav />
      </div>
    </div>
  </div>
</template>

<script>
import { store } from "../store"
import SideNav from "./side_nav.vue";
import BureauTabs from "../bureau_tabs.vue";

export default {
  components: {
    SideNav,
    BureauTabs,
  },
  data() {
    return {
      bureau: "tuc",
      store
    };
  },
  methods: {
    utilization(creditCard) {
      if (creditCard.creditLimitAmount > 0) {
        return `${Math.round((creditCard.balanceAmount * 100) / creditCard.creditLimitAmount)}%`;
      } else {
        return "-";
      }
    },
  },
  computed: {
    canCalculateUtilization(){
      return this.totalLimit > 0
    },
    totalBalance() {
      return this.openRevolvingAccounts
        .map((c) => c.balanceAmount)
        .filter(Number)
        .reduce((a, b) => a + b, 0);
    },
    totalLimit() {
      return this.openRevolvingAccounts
        .map((c) => c.creditLimitAmount)
        .filter(Number)
        .reduce((a, b) => a + b, 0);
    },
    totalUtilization() {
      if (this.totalLimit > 0) {
        return `${Math.round((this.totalBalance * 100) / this.totalLimit)}%`;
      } else {
        return "-";
      }
    },
    openRevolvingAccounts() {
      if (!store.creditReport) {
        return [];
      }

      return store.creditReport.tradelines
        .filter(
          (tradeline) =>
            tradeline[this.bureau].creditType === "Revolving Account" &&
            tradeline[this.bureau].accountOpenClosed === "Open" &&
            tradeline[this.bureau].creditLimitAmount > 0
        )
        .map((tradeline) => tradeline[this.bureau]);
    },
  },
};
</script>
