<template>
  <div class="mb-3 ccs-auth-question">
    <div :id="`ccs-auth-question-text:${index}`" class="font-semibold ccs-auth-question-text">
      {{ index + 1 }}. {{ question.text }}
    </div>

    <div v-for="(answer, answer_index) in question.answers" :key="answer.id" class="ml-3 ccs-auth-question-answer-choice">
      <div class="my-1 ccs-auth-question-answer-radio" v-if="answer.type == 'radio'">
        <label :for="`ccs-auth-question-answer-input:${index}:${answer_index}`"
          :id="`ccs-auth-question-answer-text:${index}:${answer_index}`">
          <input :id="`ccs-auth-question-answer-input:${index}:${answer_index}`" type="radio"
            :name="`questions[${question.id}]`" :value="answer.id" required @click="$emit('select-answer', answer.id)" />
          <span class="ml-3 font-normal ccs-auth-question-answer-text">{{ answer.text }}</span>
        </label>
      </div>
      <div class="my-1 ccs-auth-question-answer-input" v-if="answer.type == 'input'">
        <input :id="`ccs-auth-question-answer-input:${index}:${answer_index}`" type="hidden"
          :name="`questions[${question.id}]`" :value="answer.id" />
        <input :id="`ccs-auth-question-answer-text:${index}:${answer_index}`" type="text"
          class="ccs-auth-question-answer-input" :name="`user_inputs[${question.id}]`"
          @input="(ev) => $emit('user-input', { answer_id: answer.id, user_input: ev.target.value })" required
          autofocus />

        <div class="text-sm">You will have 3 attempts to enter the correct passcode within 15 minutes.</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["question", "index"],
  emits: ["select-answer", "user-input"],
};
</script>
