<template>
  <div class="grid grid-cols-1 md:grid-cols-2" v-if="store.creditReport">
    <div class="flex gap-3">
      <div class="flex flex-col rounded-md" :class="`text-${creditScoreStatus} bg-gradient-${creditScoreStatus}`">
        <div class="px-10 my-10 text-center md:my-auto">
          <div class="text-5xl font-bold">{{ store.creditReport.creditScore[bureau] }}</div>
          <div class="font-sans text-xs font-medium uppercase">Your Credit Score</div>
        </div>
      </div>
      <div class="mt-3 md:mt-0">
        <div class="px-3 py-2 md:py-3">
          <div class="text-lg font-semibold md:text-xl text-zinc-500">
            {{ formatDate(store.creditReport.reportDate[bureau], "MMM D, YYYY") }}
          </div>
          <div class="font-sans text-xs uppercase">Report Date</div>
        </div>
        <div class="px-3 py-2 md:py-3">
          <div class="text-lg font-semibold md:text-xl text-zinc-500">{{ store.creditReport.borrower.name[bureau] }}</div>
          <div class="font-sans text-xs uppercase">Full Name</div>
        </div>
      </div>
    </div>
    <div class="py-5 md:px-5">
      <div class="my-1">
        <a id="btn-personal-information" class="inline-block btn btn-xl btn-primary" @click="showPersonalInfoModal = true">
          <UserIcon class="inline-block w-4 h-4 mr-2" />
          Personal Information
        </a>
      </div>
      <div class="my-1">
        <a id="btn-print-report" href="#/credit-report/print" class="inline-block btn btn-xl btn-primary" @click="triggerPrint()">
          <PrinterIcon class="inline-block w-4 h-4 mr-2" />
          Print Report
        </a>
      </div>
      <div class="my-1">
        <a id="btn-download-report" href="#/credit-report/print" class="inline-block btn btn-xl btn-primary" @click="triggerReportDownload()">
          <ArrowDownTrayIcon class="inline-block w-4 h-4 mr-2" />
          Download Report
        </a>
      </div>
    </div>
    <PersonalInfoModal
      v-if="showPersonalInfoModal"
      :bureau="bureau"
      @ss-close-modal="showPersonalInfoModal = false"></PersonalInfoModal>
  </div>
</template>

<script>
import { store } from "../store";
import PersonalInfoModal from "./personal_info_modal.vue";
import DateFormatter from "../../../lib/date_formatter";
import { UserIcon, PrinterIcon, ArrowDownTrayIcon } from "@heroicons/vue/24/solid";

export default {
  components: {
    PersonalInfoModal,
    PrinterIcon,
    ArrowDownTrayIcon,
    UserIcon,
  },
  props: ["bureau"],
  data() {
    return {
      showPersonalInfoModal: false,
      store
    };
  },
  computed: {
    creditScoreStatus() {
      if (store.creditReport.creditScore[this.bureau] > 719) {
        return "good";
      } else if (store.creditReport.creditScore[this.bureau] > 629) {
        return "fair";
      } else {
        return "poor";
      }
    },
  },
  methods: {
    formatDate(s, f = null) {
      if (f) {
        return DateFormatter.format(s, f);
      } else {
        return DateFormatter.format(s);
      }
    },
    triggerPrint() {
      window.setTimeout(function () {
        window.print();
      }, 700);
    },
    downloadReport(){
      // Create a new HTML document
      var newHTML = document.implementation.createHTMLDocument("Credit Report | Standard Scores");

      // Add a link for a stylesheet with the url of the current host + "/portal/stylesheet"
      var link = newHTML.createElement("link");
      link.href = window.location.origin + "/portal/stylesheet";
      link.rel = "stylesheet";
      link.type = "text/css";
      newHTML.head.appendChild(link);

      newHTML.body.classList.add("p-3")

      // Add the contents of #credit-report-print-view to the body of the new document
      newHTML.body.innerHTML = document.getElementById("credit-report-print-view").innerHTML;

      // Create a new blob containing the contents of the new document
      var blob = new Blob([newHTML.documentElement.outerHTML], {type: "text/html"});
      var url = URL.createObjectURL(blob);

      var a = document.createElement("a");
      a.href = url;
      a.download = "credit-report-" + new Date().toISOString().slice(0, 10) + ".html";
      a.click();

      // Clean up
      URL.revokeObjectURL(url);
      a.remove();
    },
    triggerReportDownload(){
      window.setTimeout(() => {
        this.downloadReport();
        window.location.hash = "#/credit-report";
      }, 700);
    }
  },
};
</script>
