<template>
  <svg viewBox="7 7 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1489_81307)">
      <path
        d="M21.625 25.5H12.875C12.7092 25.5 12.5503 25.4342 12.4331 25.3169C12.3158 25.1997 12.25 25.0408 12.25 24.875V13.625C12.25 13.4592 12.3158 13.3003 12.4331 13.1831C12.5503 13.0658 12.7092 13 12.875 13H19.125L22.25 16.125V24.875C22.25 25.0408 22.1842 25.1997 22.0669 25.3169C21.9497 25.4342 21.7908 25.5 21.625 25.5Z"
        stroke="currentColor"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round" />
      <path
        d="M14.75 13V11.125C14.75 10.9592 14.8158 10.8003 14.9331 10.6831C15.0503 10.5658 15.2092 10.5 15.375 10.5H21.625L24.75 13.625V22.375C24.75 22.5408 24.6842 22.6997 24.5669 22.8169C24.4497 22.9342 24.2908 23 24.125 23H22.25"
        stroke="currentColor"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round" />
      <path
        d="M15.375 19.875H19.125"
        stroke="currentColor"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round" />
      <path
        d="M15.375 22.375H19.125"
        stroke="currentColor"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_1489_81307">
        <rect width="20" height="20" fill="white" transform="translate(8.5 8)" />
      </clipPath>
    </defs>
  </svg>
</template>
<script>
export default {
  name: "DocumentsIcon",
};
</script>
