<template>
  <div>
    <div class="my-3 font-semibold">
      <span>Curious about your scores?</span>
      &nbsp;
      <a href="#/education/payment-history" class="orange">Review insights</a>
    </div>

    <div class="my-3 font-semibold" v-if="store.profile.unreadAlertCount > 0">
      <span>You have {{ store.profile.unreadAlertCount }} new {{ store.profile.unreadAlertCount > 1 ? "alerts" : "alert" }}</span>
      &nbsp;
      <a href="#/alerts" class="orange">View alerts</a>
    </div>

    <div class="p-3 border rounded-md">
      <div class="text-xl font-semibold">Areas that may impact your scores</div>

      <div class="grid grid-cols-3 mt-3 border-b">
        <div :class="tabClasses('credit_usage')" @click="selectedTab = 'credit_usage'">
          Credit Usage
          <span v-if="store.creditReport.creditScoreFactors.tuc.length > 0">
            ({{ store.creditReport.creditScoreFactors.tuc.length }})
          </span>
        </div>
        <div :class="tabClasses('new_activity')" @click="selectedTab = 'new_activity'">New Activity</div>
        <div :class="tabClasses('negative_items')" @click="selectedTab = 'negative_items'">
          Negative Items
          <span v-if="negativeItems.length > 0">({{ negativeItems.length }})</span>
        </div>
      </div>

      <div v-if="selectedTab == 'credit_usage'">
        <div v-if="store.creditReport.creditScoreFactors.tuc.length === 0">
          <div class="flex gap-2 mt-3">
            <div class="p-1 mt-3">
              <svg width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M6 10.8876L8 12.8876L12.5 8.38757M17 11.3876C17 16.296 11.646 19.866 9.69799 21.0024C9.4766 21.1316 9.3659 21.1962 9.20968 21.2297C9.08844 21.2557 8.91156 21.2557 8.79032 21.2297C8.6341 21.1962 8.5234 21.1316 8.30201 21.0024C6.35396 19.866 1 16.296 1 11.3876V6.60517C1 5.80566 1 5.4059 1.13076 5.06227C1.24627 4.75871 1.43398 4.48784 1.67766 4.2731C1.9535 4.03001 2.3278 3.88964 3.0764 3.60891L8.4382 1.59824C8.6461 1.52028 8.75005 1.4813 8.85698 1.46585C8.95184 1.45214 9.04816 1.45214 9.14302 1.46585C9.24995 1.4813 9.3539 1.52028 9.5618 1.59824L14.9236 3.60891C15.6722 3.88964 16.0465 4.03001 16.3223 4.2731C16.566 4.48784 16.7537 4.75871 16.8692 5.06227C17 5.4059 17 5.80566 17 6.60517V11.3876Z"
                  stroke="#82D0A1"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round" />
              </svg>
            </div>
            <div>
              <div class="mt-1 text-lg font-semibold">There are no areas that impact your scores</div>
              <div class="text-sm text-gray-500">We will monitor for activity that could affect your scores</div>
            </div>
          </div>

          <div>
            <img src="/images/credit_report_monitoring.png" />
          </div>

          <div class="text-center">
            <div class="mt-1 text-lg font-semibold">Identity Theft Coverage</div>
            <div class="text-sm text-gray-500">
              Up to $1 million ID theft insurance to help with the cost
              <br />
              and restoring your good name.
            </div>
          </div>
        </div>
        <div v-else>
          <div
            class="flex gap-2 mt-3"
            v-for="factor in store.creditReport.creditScoreFactors.tuc"
            v-bind:key="factor.bureau_code">
            <div class="p-1 mt-3">
              <ShieldCheckIcon v-if="factor.scoreSentiment === 'Positive'" class="w-6 h-6 text-lime-500" />
              <ShieldExclamationIcon v-else class="w-6 h-6 text-red-500" />
            </div>
            <div class="overflow-hidden">
              <div class="mt-1 text-lg text-gray-500">{{ factor.factor }}</div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="selectedTab == 'new_activity'">
        <div class="mt-3">
          <em>No new activity to display</em>
        </div>
      </div>

      <div v-if="selectedTab == 'negative_items'">
        <div class="mt-3" v-if="negativeItems.length == 0">
          <em>No negative items to display</em>
        </div>
        <div class="p-2 mt-3" v-else>
          <table class="table text-sm">
            <thead>
              <tr>
                <th>Creditor Name</th>
                <th>Account</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(tradeline, i) in negativeItems" :key="i">
                <td>{{ tradeline.tuc.creditorName }}</td>
                <td>{{ tradeline.tuc.accountNumber }}</td>
                <td>
                  {{ tradeline.tuc.isDelinquent ? "Delinquent" : "" }}
                  {{ tradeline.tuc.isDerogatory ? "Derogatory" : "" }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {store} from "../store"
import { ShieldCheckIcon, ShieldExclamationIcon } from "@heroicons/vue/20/solid";

export default {
  components: {ShieldCheckIcon, ShieldExclamationIcon},
  methods: {
    tabClasses(tabName) {
      if (this.selectedTab == tabName) {
        return "p-2 cursor-pointer text-sm font-semibold text-gray-900";
      } else {
        return "p-2 cursor-pointer text-sm text-gray-400";
      }
    },
  },
  computed: {
    negativeItems(){
      // Tradelines whose TUC account condition is Derogatory or Delinquent
      return store.creditReport.tradelines.filter((tradeline) => {
        return tradeline.tuc.isDelinquent || tradeline.tuc.isDerogatory
      });
    },
  },
  data: function () {
    return {
      selectedTab: "credit_usage",
      store
    };
  },
};
</script>
