<template>
  <div id="dashboard-container" class="container mx-auto">
    <div class="card" v-if="store.creditReport != null">
      <div class="card-body md:p-xl p-sm">
        <div class="grid md:grid-cols-2 grid-cols-1 gap-3">
          <DashboardSummary />
          <div>
            <div class="my-md">
              <div class="comment">TransUnion</div>
              <ProgressBar
                :progress="creditScorePercentage(store.creditReport.creditScore.tuc)"
                :text="store.creditReport.creditScore.tuc"
                :barCss="creditScoreCss(store.creditReport.creditScore.tuc)"
              />
            </div>
            <div class="my-md" v-if="!singleBureauReport">
              <div class="comment">Equifax</div>
              <ProgressBar
                :progress="creditScorePercentage(store.creditReport.creditScore.eqf)"
                :text="store.creditReport.creditScore.eqf"
                :barCss="creditScoreCss(store.creditReport.creditScore.eqf)"
              />
            </div>
            <div class="my-md" v-if="!singleBureauReport">
              <div class="comment">Experian</div>
              <ProgressBar
                :progress="creditScorePercentage(store.creditReport.creditScore.exp)"
                :text="store.creditReport.creditScore.exp"
                :barCss="creditScoreCss(store.creditReport.creditScore.exp)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="md:my-xl my-sm md:px-0 px-sm" v-if="store.creditReport != null">
      <div class="grid md:grid-cols-3 grid-cols-1 gap-md">
        <div>
          <h5 class="md:!font-bold md:my-md my-sm md:h5 h3">
            Notifications
            <BellAlertIcon class="inline-block ml-2 h-6 w-6 align-text-bottom" />
          </h5>

          <div>
            <div class="my-sm" v-for="(alert, i) in store.profile.recentAlerts" :key="i">
              <div class="comment">{{ alert.alert_details || alert.alert_type }}</div>
              <div class="comment-s text-muted">{{ formatDate(alert.created_at, "MMMM D, YYYY") }}</div>
            </div>
          </div>

          <a href="#/alerts" class="btn btn-outline w-full">
            <span>Go to All Alerts</span>
            <ArrowRightIcon class="inline-block ml-2 h-5 w-5 align-text-bottom" />
          </a>
        </div>
        <div>
          <h5 class="md:!font-bold md:my-md my-sm md:h5 h3">
            Monthly Payments
            <CreditCardIcon class="inline-block ml-2 h-6 w-6 align-text-bottom" />
          </h5>

          <div class="card">
            <div class="card-body md:p-md p-0">
              <table>
                <thead>
                  <tr>
                    <th>Account</th>
                    <th class="text-right">Amount</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(account, i) in openRevolvingAccounts" :key="i">
                    <td>{{ account.creditorName }}</td>
                    <td class="text-right">{{ usd(account.monthlyPaymentAmount) }}</td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr class="font-bold">
                    <td>Total</td>
                    <td class="text-lg text-right">
                      {{ usd(openRevolvingAccounts.map((x) => x.monthlyPaymentAmount)) }}
                    </td>
                    <td></td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
        <div>
          <h5 class="md:!font-bold md:my-md my-sm md:h5 h3">
            Your Total Credit Amount
            <CurrencyDollarIcon class="inline-block ml-2 h-6 w-6 align-text-bottom" />
          </h5>

          <div class="card">
            <div class="card-body md:p-md p-0">
              <h3 class="mb-sm">{{ usd(totalCreditBalance) }}</h3>
              <table>
                <thead>
                  <tr>
                    <th>Account</th>
                    <th class="text-right">Amount</th>
                    <th class="text-right">Limit</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(account, i) in openRevolvingAccounts" :key="i">
                    <td>{{ account.accountNumber }}</td>
                    <td class="text-right">{{ usd(account.balanceAmount) }}</td>
                    <td class="text-right">{{ usd(account.creditLimitAmount) }}</td>
                  </tr>
                </tbody>
              </table>

              <a href="#/education/credit-utilization" class="btn btn-outline w-full">
                See Full Summary
                <DocumentTextIcon class="inline-block ml-2 h-5 w-5 align-text-bottom" />
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="md:my-xl my-sm">
        <div class="flex gap-sm md:my-md my-sm md:flex-row flex-col">
          <h5 class="md:!font-bold md:h5 h3">
            Score Factors
            <BoltIcon class="inline-block ml-2 h-6 w-6 align-text-bottom" />
          </h5>
          <div class="comment-s">Here you can check what impacts<br />your credit score</div>
        </div>

        <div class="grid md:grid-cols-4 grid-cols-1 gap-xs">
          <div class="card cursor-pointer" @click="factorModalView = 'paymentHistory'">
            <div class="card-body p-sm">
              <div class="flex gap-sm">
                <h6 class="comment">Payment History</h6>
                <div class="ml-auto"><InformationCircleIcon class="h-6 w-6" /></div>
              </div>
              <div class="comment-s text-muted">{{ statusText(store.scoreFactors.paymentHistory.status) }}</div>
              <div class="flex gap-sm pt-sm font-medium text-lg">
                <div v-if="positiveFactorCount('paymentHistory') > 0" class="text-green">
                  <span>{{ positiveFactorCount('paymentHistory') }}</span>
                  <ArrowUpIcon class="inline-block h-4 w-4" />
                </div>
                <div v-if="negativeFactorCount('paymentHistory') > 0" class="text-primary">
                  <span>{{ negativeFactorCount('paymentHistory') }}</span>
                  <ArrowDownIcon class="inline-block h-4 w-4" />
                </div>
                <div v-if="factorCount('paymentHistory') == 0" class="text-muted">
                  None
                </div>
              </div>
            </div>
          </div>
          <div class="card cursor-pointer" @click="factorModalView = 'availableCredit'">
            <div class="card-body p-sm">
              <div class="flex gap-sm">
                <h6 class="comment">Available Credit</h6>
                <div class="ml-auto"><InformationCircleIcon class="h-6 w-6" /></div>
              </div>
              <div class="comment-s text-muted">{{ statusText(store.scoreFactors.availableCredit.status) }}</div>
              <div class="flex gap-sm pt-sm font-medium text-lg">
                <div v-if="positiveFactorCount('availableCredit') > 0" class="text-green">
                  <span>{{ positiveFactorCount('availableCredit') }}</span>
                  <ArrowUpIcon class="inline-block h-4 w-4" />
                </div>
                <div v-if="negativeFactorCount('availableCredit') > 0" class="text-primary">
                  <span>{{ negativeFactorCount('availableCredit') }}</span>
                  <ArrowDownIcon class="inline-block h-4 w-4" />
                </div>
                <div v-if="factorCount('availableCredit') == 0" class="text-muted">
                  None
                </div>
              </div>
            </div>
          </div>
          <div class="card cursor-pointer" @click="factorModalView = 'ageOfCredit'">
            <div class="card-body p-sm">
              <div class="flex gap-sm">
                <h6 class="comment">Age of Credit</h6>
                <div class="ml-auto"><InformationCircleIcon class="h-6 w-6" /></div>
              </div>
              <div class="comment-s text-muted">{{ statusText(store.scoreFactors.ageOfCredit.status) }}</div>
              <div class="flex gap-sm pt-sm font-medium text-lg">
                <div v-if="positiveFactorCount('ageOfCredit') > 0" class="text-green">
                  <span>{{ positiveFactorCount('ageOfCredit') }}</span>
                  <ArrowUpIcon class="inline-block h-4 w-4" />
                </div>
                <div v-if="negativeFactorCount('ageOfCredit') > 0" class="text-primary">
                  <span>{{ negativeFactorCount('ageOfCredit') }}</span>
                  <ArrowDownIcon class="inline-block h-4 w-4" />
                </div>
                <div v-if="factorCount('ageOfCredit') == 0" class="text-muted">
                  None
                </div>
              </div>
            </div>
          </div>
          <div class="card cursor-pointer" @click="factorModalView = 'utilization'">
            <div class="card-body p-sm">
              <div class="flex gap-sm">
                <h6 class="comment">Utilization</h6>
                <div class="ml-auto"><InformationCircleIcon class="h-6 w-6" /></div>
              </div>
              <div class="comment-s text-muted">{{ statusText(store.scoreFactors.utilization.status) }}</div>
              <div class="flex gap-sm pt-sm font-medium text-lg">
                <div v-if="positiveFactorCount('utilization') > 0" class="text-green">
                  <span>{{ positiveFactorCount('utilization') }}</span>
                  <ArrowUpIcon class="inline-block h-4 w-4" />
                </div>
                <div v-if="negativeFactorCount('utilization') > 0" class="text-primary">
                  <span>{{ negativeFactorCount('utilization') }}</span>
                  <ArrowDownIcon class="inline-block h-4 w-4" />
                </div>
                <div v-if="factorCount('utilization') == 0" class="text-muted">
                  None
                </div>
              </div>
            </div>
          </div>
          <div class="card cursor-pointer" @click="factorModalView = 'creditInquiries'">
            <div class="card-body p-sm">
              <div class="flex gap-sm">
                <h6 class="comment">Inquiries</h6>
                <div class="ml-auto"><InformationCircleIcon class="h-6 w-6" /></div>
              </div>
              <div class="comment-s text-muted">{{ statusText(store.scoreFactors.creditInquiries.status) }}</div>
              <div class="flex gap-sm pt-sm font-medium text-lg">
                <div v-if="positiveFactorCount('creditInquiries') > 0" class="text-green">
                  <span>{{ positiveFactorCount('creditInquiries') }}</span>
                  <ArrowUpIcon class="inline-block h-4 w-4" />
                </div>
                <div v-if="negativeFactorCount('creditInquiries') > 0" class="text-primary">
                  <span>{{ negativeFactorCount('creditInquiries') }}</span>
                  <ArrowDownIcon class="inline-block h-4 w-4" />
                </div>
                <div v-if="factorCount('creditInquiries') == 0" class="text-muted">
                  None
                </div>
              </div>
            </div>
          </div>
          <div class="card cursor-pointer" @click="factorModalView = 'negativeMarks'">
            <div class="card-body p-sm">
              <div class="flex gap-sm">
                <h6 class="comment">Negative Marks</h6>
                <div class="ml-auto"><InformationCircleIcon class="h-6 w-6" /></div>
              </div>
              <div class="comment-s text-muted">{{ statusText(store.scoreFactors.negativeMarks.status) }}</div>
              <div class="flex gap-sm pt-sm font-medium text-lg">
                <div v-if="positiveFactorCount('negativeMarks') > 0" class="text-green">
                  <span>{{ positiveFactorCount('negativeMarks') }}</span>
                  <ArrowUpIcon class="inline-block h-4 w-4" />
                </div>
                <div v-if="negativeFactorCount('negativeMarks') > 0" class="text-primary">
                  <span>{{ negativeFactorCount('negativeMarks') }}</span>
                  <ArrowDownIcon class="inline-block h-4 w-4" />
                </div>
                <div v-if="factorCount('negativeMarks') == 0" class="text-muted">
                  None
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ScoreFactorModal :category="factorModalView" :bureau="bureau" @close-modal="factorModalView = null" v-if="factorModalView != null" />

    <MissingCreditReport v-if="!store.creditReport" :creditReportError="store.creditReportError" />
  </div>
</template>

<script>
import { store } from "../store.ts";
import moment from "moment";
import DashboardSummary from "./summary.vue";
import MissingCreditReport from "../missing_credit_report.vue";
import ProgressBar from "../progress_bar.vue";
import ScoreFactorModal from "./score_factor_modal.vue"

import {
  CreditCardIcon,
  ArrowRightIcon,
  BellAlertIcon,
  BoltIcon,
  CurrencyDollarIcon,
  DocumentTextIcon,
  ArrowDownIcon,
  ArrowUpIcon,
  InformationCircleIcon
} from "@heroicons/vue/24/outline";

export default {
  components: {
    DashboardSummary,
    MissingCreditReport,
    ScoreFactorModal,
    ProgressBar,
    ArrowRightIcon,
    BellAlertIcon,
    CurrencyDollarIcon,
    DocumentTextIcon,
    BoltIcon,
    CreditCardIcon,
    ArrowDownIcon,
    ArrowUpIcon,
    InformationCircleIcon
  },
  props: [],
  computed: {
    // if reportRefreshDate is present and equal to or before today
    refreshReportAvailable() {
      return this.reportRefreshDate && this.reportRefreshDate.isSameOrBefore(new Date(), "day");
    },
    reportRefreshDate() {
      if (store.profile.reportRefreshDate) {
        return moment(store.profile.reportRefreshDate);
      } else {
        return null;
      }
    },
    openRevolvingAccounts() {
      if (!store.creditReport) {
        return [];
      }

      return store.creditReport.tradelines
        .filter(
          (tradeline) =>
            tradeline[this.bureau].creditType === "Revolving Account" &&
            tradeline[this.bureau].accountOpenClosed === "Open" &&
            tradeline[this.bureau].creditLimitAmount > 0
        )
        .map((tradeline) => tradeline[this.bureau]);
    },
    totalCreditBalance() {
      return this.openRevolvingAccounts
        .map((c) => c.balanceAmount)
        .filter(Number)
        .reduce((a, b) => a + b, 0);
    },
    singleBureauReport() {
      return store.creditReport.singleBureauReport;
    },
  },
  methods: {
    fetchNewReport() {
      // POST to /portal/credit-report/refresh
      this.fetching = true;
      fetch("/portal/credit-report/refresh", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": document.querySelector("meta[name=csrf-token]").content,
        },
        body: JSON.stringify({}),
      })
        .then((response) => response.text())
        .then(() => {
          window.location = "/portal";
        })
        .catch((error) => {
          console.log(error);
          this.fetchError = true;
          this.fetching = false;
        });
    },
    statusText(status) {
      if (status === "good") {
        return "Good";
      } else if (status === "fair") {
        return "Fair";
      } else if (status === "bad") {
        return "Poor";
      } else {
        return "";
      }
    },
    creditScorePercentage(score) {
      return Math.round(((score - 300) / 500) * 100);
    },
    creditScoreCss(score) {
      if (!score) {
        return "";
      }

      if (score > 719) {
        return "bg-green";
      } else if (score > 629) {
        return "bg-primary";
      } else {
        return "bg-primary";
      }
    },
    positiveFactorCount(factorCategoryName){
      if(!store.creditReport){
        return 0;
      }

      return store.creditReport.creditScoreFactors[this.bureau].filter((factor) => {
        return factor.category === factorCategoryName && factor.scoreSentiment === "Positive";
      }).length;
    },
    negativeFactorCount(factorCategoryName){
      if(!store.creditReport){
        return 0;
      }

      return store.creditReport.creditScoreFactors[this.bureau].filter((factor) => {
        return factor.category === factorCategoryName && factor.scoreSentiment === "Negative";
      }).length;
    },
    factorCount(factorCategoryName){
      if(!store.creditReport){
        return 0;
      }

      return store.creditReport.creditScoreFactors[this.bureau].filter((factor) => {
        return factor.category === factorCategoryName;
      }).length;
    },
  },
  data: function () {
    return {
      fetching: false,
      fetchError: false,
      bureau: "tuc",
      store,
      factorModalView: null
    };
  },
};
</script>
