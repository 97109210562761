import ahoy from "ahoy.js";

const config = {
    isDevOrLocal: false,
    platform: "web",
    cookieDomain: null,
};

function configure(options) {
    Object.assign(config, options);

    if (config.cookieDomain == null) {
        config.cookieDomain =
            (config.isDevOrLocal ? null : window.location.hostname.replace("app.", ""))
    }
}
function getCurrentMarketingParams()
{
    // get utm params from query string
    const params = new URLSearchParams(window.location.search);

    const AD_NETWORKS_CLICK_ID_NAMES = ["gclid", "fbclid", "msclkid"];
    const AD_NETWORKS_NAMES = ["google", "facebook", "bing"];

    const marketingParams = {
        utm_id: params.get('utm_id'),
        ad_click_id: null,
        ad_click_network: null,
        ss_utm_partner: params.get('ss_utm_partner')
    };

    const index = AD_NETWORKS_CLICK_ID_NAMES.findIndex((click_id_name) => params.get(click_id_name));

    if (index > -1) {
        marketingParams['ad_click_id'] = params.get(AD_NETWORKS_CLICK_ID_NAMES[index]);
        marketingParams['ad_click_network'] = AD_NETWORKS_NAMES[index];
    }

    return marketingParams;
}

function didKeyMarketingParametersChange() {
    const currentParams = new URLSearchParams(window.location.search);
    const priorParams = new URLSearchParams(getCookie("ss_last_visit_params"));

    let changeOccurred = false;

    // check if ss_utm_partner changed and we should record a new visit
    const currentPartner = currentParams.get("ss_utm_partner");
    if (currentPartner != null && currentPartner != "") {
        changeOccurred = (currentPartner != priorParams.get("ss_utm_partner"));
    }

    if (changeOccurred) {
        setCookie("ss_last_visit_params", window.location.search, config.cookieDomain, 60 * 4);
    }

    return changeOccurred;
}

function shine() {
    window['ahoy'] = ahoy;
    const originalStart = ahoy.start;
    const ahoyConfig = {
        urlPrefix: "",
        visitsUrl: "/ahoy/visits",
        eventsUrl: "/ahoy/events",
        page: null,
        platform: config.platform,
        useBeacon: true,
        startOnReady: false,
        trackVisits: true,
        cookies: true,
        cookieDomain: config.cookieDomain,
        headers: {},
        visitParams: {},
        withCredentials: true,
        visitDuration: 4 * 60, // default 4 hours
        visitorDuration: 2 * 365 * 24 * 60 // default 2 years
    };

    ahoyConfig["visitParams"] = Object.assign({},
        ahoyConfig["visitParams"], getCurrentMarketingParams());
    ahoy.configure(ahoyConfig);

    if (config.isDevOrLocal) {
        console.log("ahoy_config", ahoyConfig);
    }

    ahoy.ready(() => {
        // snapshot url search params
        console.log("snapshot",window.location.search);
    });

    // track visit
    ahoy.start();

    if (didKeyMarketingParametersChange()) {
        // erase visit
        deleteCookie("ahoy_visit", "/", config.cookieDomain);
        // call ahoy.start() again, need to use original start because ahoy.start() converts to a noop after first call
        originalStart();
    }


}

function getCookie(name){
    let i, c;
    const nameEQ = name + "=";
    const ca = document.cookie.split(';');
    for (i = 0; i < ca.length; i++) {
        c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1, c.length);
        }
        if (c.indexOf(nameEQ) === 0) {
            return decodeURIComponent(c.substring(nameEQ.length, c.length));
        }
    }
    return null;
}

function setCookie( name, value, domain, ttl ) {
    let expires = "";
    let cookieDomain = "";
    if (ttl) {
        const date = new Date();
        date.setTime(date.getTime() + (ttl * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    if (domain) {
        cookieDomain = "; domain=" + domain;
    }
    document.cookie = name + "=" + encodeURIComponent(value) + expires + cookieDomain + "; path=/; samesite=lax";
}

function deleteCookie( name, path, domain ) {
    if( getCookie( name ) ) {
        document.cookie = name + "=" +
            ((path) ? ";path="+path:"")+
            ((domain)?";domain="+domain:"") +
            ";expires=Thu, 01 Jan 1970 00:00:01 GMT";
    }
}

const northstar = window['northstar'] || {};

northstar.getCurrentMarketingParams = getCurrentMarketingParams;
northstar.configure = configure;
northstar.shine = shine;


export default northstar;