<template>
  <div class="container mx-auto">
    <div>
      <div>
        <div class="mb-3 text-4xl font-medium">Billing</div>

        <div class="my-3">
          <h3 class="my-3 text-lg font-medium">Subscriptions</h3>

          <table class="table w-full">
            <thead>
              <tr>
                <th>Status</th>
                <th>Plan</th>
                <th>Start Date</th>
                <th>Next Billing Date</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(subscription, i) in subscriptions" :key="i">
                <td>
                  <div v-if="subscription.status == 'Active'" class="badge badge-success">Active</div>
                  <div v-else class="badge badge-gray">{{ subscription.status }}</div>
                </td>
                <td>{{ subscription.plan_name }}</td>
                <td>{{ formatDate(subscription.start_date, "MM/DD/YYYY") }}</td>
                <td>{{ formatDate(subscription.next_billing_on, "MM/DD/YYYY") || "-" }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="mt-6">
          <h3 class="my-3 text-lg font-medium">Credit Card</h3>

          <table class="table w-full">
            <thead>
              <tr>
                <th>Card Type</th>
                <th>Number</th>
              </tr>
            </thead>
            <tbody v-if="creditCardPresent">
              <tr>
                <td>{{ formattedCardType(creditCard.card_type) }}</td>
                <td>*******{{ creditCard.last_4 }}</td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td class="italic text-gray-500">No credit card on file</td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="my-6 md:max-w-lg" v-if="!creditCardAdded">
          <div class="mb-3 text-xl font-semibold leading-8">Add Credit Card</div>
          <div>Credit Card Number</div>
          <div id="spreedly-number" style="height: 38px" class="w-full p-1 text-lg border border-slate-200"></div>

          <div class="grid grid-cols-1 gap-2 mt-3 md:grid-cols-2">
            <div>
              <div>Expiry</div>
              <input id="registration-form.expiry" type="text" inputmode="numeric" :value="ccExpiry"
                @input="maskCCExpiry($event.target.value)" class="w-full text-lg border-slate-200"
                style="font-family: Arial, sans-serif" placeholder="MM/YY" />
            </div>

            <div>
              <div>CVV</div>
              <div id="spreedly-cvv" style="height: 38px" class="w-full p-1 text-lg border border-slate-200"></div>
            </div>
          </div>

          <div v-if="errors">
            <div v-for="error in errors" :key="error" class="text-red-700">{{ error }}</div>
          </div>

          <button type="button" class="my-3 btn btn-primary" @click="getPaymentToken()" v-if="!showSpinner">
            Add Credit Card
          </button>

          <div v-if="showSpinner" class="my-3">
            <Spinner />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* global Spreedly */
import { inject } from "vue";
import { store } from "./store";
import Spinner from "../shared/spinner_svg.vue";

export default {
  setup() {
    const spreedlyEnvironmentId = inject("spreedly.environmentId");
    return { spreedlyEnvironmentId };
  },
  components: {
    Spinner,
  },
  data: () => {
    return {
      subscriptions: [],
      creditCard: null,
      errors: [],
      ccExpiry: "",
      showSpinner: false,
      paymentFieldsReady: false,
      paymentToken: "",
      spreedlyInitialized: false,
      creditCardAdded: false,
    };
  },
  computed: {
    creditCardPresent() {
      // check if creditCard is not a blank object
      return this.creditCard && this.creditCard.card_type;
    },
    ccExpiryMonth() {
      if (!this.ccExpiry.match(/\d\d\/\d\d/)) {
        return "";
      }

      return this.ccExpiry.substring(0, 2);
    },
    ccExpiryYear() {
      if (!this.ccExpiry.match(/\d\d\/\d\d/)) {
        return "";
      }

      // This code will need to be adjusted in another 1000 years
      return `20${this.ccExpiry.substring(3)}`;
    },
  },
  methods: {
    formattedCardType(string) {
      if (string == "visa") {
        return "Visa"
      } else if (string == "master") {
        return "Mastercard"
      } else if (string == "american_express") {
        return "American Express"
      } else if (string == "discover") {
        return "Discover"
      } else {
        // Titleize the string
        return string.replace(/_/g, " ").replace(/\w\S*/g, (txt) => {
          return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });
      }
    },
    maskCCExpiry(string) {
      const upToFourDigits = string.replace(/\D/g, "").substring(0, 4);

      if (upToFourDigits.length < 2) {
        this.ccExpiry = upToFourDigits;
      } else {
        // Add a slash after the 2nd character
        this.ccExpiry = upToFourDigits.substring(0, 2) + "/" + upToFourDigits.substring(2);
      }
    },
    validateCCExpiry() {
      if (!this.ccExpiry.match(/\d\d\/\d\d/) || parseInt(this.ccExpiryYear) < new Date().getFullYear()) {
        this.errors = ["Please enter a valid expiry date"];
        return false;
      }
      return true;
    },
    fetchBillingDetails() {
      fetch("/portal/billing")
        .then((response) => response.json())
        .then((data) => {
          this.subscriptions = data.subscriptions;
          this.creditCard = data.credit_card;
        });
    },
    getPaymentToken() {
      if (!this.validateCCExpiry()) {
        return;
      }

      this.showSpinner = true;
      let data = {
        full_name: `${store.profile.firstName} ${store.profile.lastName}`,
        month: this.ccExpiryMonth,
        year: this.ccExpiryYear,
      };

      Spreedly.tokenizeCreditCard(data);
    },
    submitPaymentToken(token) {
      this.showSpinner = true;

      fetch("/portal/billing/add-credit-card", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": document.querySelector("meta[name=csrf-token]").content,
        },
        body: JSON.stringify({ payment_method_token: token }),
      })
        .then((response) => response.json())
        .then((data) => {
          this.showSpinner = false;
          if (data.errors) {
            this.errors = data.errors;
          } else {
            this.creditCardAdded = true;
            this.fetchBillingDetails();
          }
        });
    },
    initializeSpreedly() {
      if (this.paymentFieldsReady) {
        return;
      }
      if (this.spreedlyInitialized) {
        return;
      }

      this.spreedlyInitialized = true;
      this.showSpinner = true;

      Spreedly.init(this.spreedlyEnvironmentId, {
        numberEl: "spreedly-number",
        cvvEl: "spreedly-cvv",
      });

      Spreedly.on("ready", () => {
        this.paymentFieldsReady = true;
        this.showSpinner = false;

        Spreedly.setFieldType("number", "text");
        Spreedly.setNumberFormat("prettyFormat");
        Spreedly.setStyle("number", "font-size: 1.125rem; line-height: 1.75rem; width: 100%");
        Spreedly.setStyle("cvv", "font-size: 1.125rem; line-height: 1.75rem;");
      });

      Spreedly.on("errors", (errors) => {
        this.errors = errors.map((e) => e.message);
        this.showSpinner = false;
      });

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      Spreedly.on("paymentMethod", (token, pmData) => {
        this.submitPaymentToken(token);
      });
    },
  },
  mounted() {
    this.fetchBillingDetails();
    this.initializeSpreedly();
  },
};
</script>
