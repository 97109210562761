<template>
  <div class="w-full h-full p-2">
    <form id="registration-form" action="/checkout" v-on:submit.prevent="nextStep" v-on:keyup.enter="nextStep">
      <div class="w-full mx-auto mt-12 registration-step md:max-w-xl" v-if="currentStep == 'address'">
        <h4 class="text-3xl font-bold text-center">What is your current street address?</h4>

        <div class="my-6 text-center">
          <input
            id="user-address-line-1"
            type="text"
            class="w-4/5 m-1 text-lg border-slate-200"
            v-model="address.addressLine1"
            placeholder="Address Line 1"
            autofocus />
          <input
            id="user-address-line-2"
            type="text"
            class="w-4/5 m-1 text-lg border-slate-200"
            v-model="address.addressLine2"
            placeholder="Address Line 2" />
          <input
            id="user-city"
            type="text"
            class="w-4/5 m-1 text-lg border-slate-200"
            v-model="address.city"
            placeholder="City" />
          <StateSelect id="user-state" v-model="address.state" class="w-4/5 m-1 text-lg border-slate-200" />
          <input
            id="user-zip"
            type="text"
            class="w-4/5 m-1 text-lg border-slate-200"
            v-model="address.zip"
            placeholder="Zip Code" />
        </div>

        <div class="my-6 text-center">
          <label class="block">
            <input
              type="checkbox"
              class="m-1"
              v-model="useCurrentAddress" />
            I have resided at this address for at least 6 months
          </label>
        </div>

        <div class="my-6 text-center" v-if="usePreviousAddress">
          <h4 class="">Please enter your previous street address</h4>
          <input
            id="user-previous-address-line-1"
            type="text"
            class="w-4/5 m-1 text-lg border-slate-200"
            v-model="previousAddress.addressLine1"
            placeholder="Address Line 1"
            autofocus />
          <input
            id="user-previous-address-line-2"
            type="text"
            class="w-4/5 m-1 text-lg border-slate-200"
            v-model="previousAddress.addressLine2"
            placeholder="Address Line 2" />
          <input
            id="user-previous-city"
            type="text"
            class="w-4/5 m-1 text-lg border-slate-200"
            v-model="previousAddress.city"
            placeholder="City" />
          <StateSelect id="user-previous-state" v-model="previousAddress.state" class="w-4/5 m-1 text-lg border-slate-200" />
          <input
            id="user-previous-zip"
            type="text"
            class="w-4/5 m-1 text-lg border-slate-200"
            v-model="previousAddress.zip"
            placeholder="Zip Code" />
        </div>
      </div>

      <div class="w-full mx-auto my-12 registration-step md:max-w-xl" v-if="currentStep == 'name'">
        <h4 class="text-3xl font-bold text-center">What is your name?</h4>

        <div class="my-6 text-center">
          <input
            type="text"
            class="w-4/5 m-1 text-lg border-slate-200"
            v-model="firstName"
            placeholder="First Name"
            id="user-first-name" />
          <input
            type="text"
            class="w-4/5 m-1 text-lg border-slate-200"
            v-model="lastName"
            placeholder="Last Name"
            id="user-last-name" />
        </div>
      </div>

      <div class="w-full mx-auto my-12 registration-step md:max-w-xl" v-if="currentStep == 'date-of-birth'">
        <h4 class="text-3xl font-bold text-center">When were you born?</h4>

        <div class="grid grid-cols-3 gap-2 my-6">
          <select class="text-lg border-slate-200" v-model="dobMonth" id="user-dob-month">
            <option value="" disabled>Month</option>
            <option value="1">January</option>
            <option value="2">February</option>
            <option value="3">March</option>
            <option value="4">April</option>
            <option value="5">May</option>
            <option value="6">June</option>
            <option value="7">July</option>
            <option value="8">August</option>
            <option value="9">September</option>
            <option value="10">October</option>
            <option value="11">November</option>
            <option value="12">December</option>
          </select>
          <input
            id="user-dob-day"
            type="text"
            class="text-lg border-slate-200"
            inputmode="numeric"
            v-model="dobDay"
            placeholder="Day" />
          <input
            id="user-dob-year"
            type="text"
            inputmode="numeric"
            class="text-lg border-slate-200"
            v-model="dobYear"
            placeholder="Year" />
        </div>
      </div>

      <div class="w-full mx-auto my-12 registration-step md:max-w-xl" v-if="currentStep == 'account-info'">
        <h4 class="text-3xl font-bold text-center">Account Details</h4>

        <div class="my-6">
          <div class="mb-3">
            <label class="block text-sm">Email</label>
            <input
              type="text"
              class="w-full m-1 text-lg border-slate-200"
              v-model="email"
              placeholder="Enter email"
              id="user-email" />
          </div>

          <div class="mb-3">
            <label class="block text-sm">Phone</label>
            <input
              id="user-phone-number"
              type="text"
              inputmode="numeric"
              class="w-full m-1 text-lg border-slate-200"
              v-model="phone"
              placeholder="Enter phone number" />
          </div>

          <div class="mb-3">
            <label class="block text-sm">Password</label>
            <input
              id="user-password"
              type="password"
              class="w-full m-1 text-lg border-slate-200"
              v-model="password" />
          </div>

          <div class="mb-3">
            <label class="block text-sm">Confirm Password</label>
            <input
              id="user-password-confirmation"
              type="password"
              class="w-full m-1 text-lg border-slate-200"
              v-model="passwordConfirmation" />
          </div>
        </div>
      </div>

      <div class="w-full mx-auto text-center md:max-w-xl">
        <div v-if="errors" id="error-messages">
          <div v-for="error in errors" :key="error" class="text-red-700">{{ error }}</div>
        </div>
      </div>

      <div class="grid w-full grid-cols-1 gap-2 mx-auto mt-3 md:max-w-xl md:grid-cols-2" v-if="!showSpinner">
        <div class="order-last md:order-first">
          <button
            id="registration-back-button"
            type="button"
            class="w-full p-3 text-lg font-semibold bg-white border hover:bg-slate-100"
            v-on:click="prevStep()"
            v-if="currentStep != 'address'">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="inline-block w-5 h-5 align-sub">
              <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75" />
            </svg>
            Back
          </button>
        </div>

        <div class="order-first md:order-last">
          <button
            id="registration-continue-button"
            type="button"
            class="w-full p-3 text-lg font-semibold bg-amber-400 hover:bg-amber-500"
            v-on:click="nextStep()">
            Continue
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="inline-block w-5 h-5 align-sub">
              <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75" />
            </svg>
          </button>
        </div>
      </div>

      <div v-if="showSpinner" class="text-center">
        <Spinner />
      </div>
    </form>
  </div>
</template>

<script>
import axios from "axios";
import RegistrationValidator from "./registration_validator.ts";
import Spinner from "../shared/spinner_svg.vue";
import StateSelect from "./state_select.vue";

export default {
  components: {
    StateSelect,
    Spinner,
  },
  data: function () {
    return {
      steps: [
        "address",
        "name",
        "date-of-birth",
        "account-info"
      ],
      currentStep: "address",
      validationErrors: [],
      errors: null,
      showSpinner: false,
      paymentFieldsReady: false,
      email: "",
      password: "",
      passwordConfirmation: "",
      firstName: "",
      lastName: "",
      phone: "",
      useCurrentAddress: true,
      address: {
        addressLine1: "",
        addressLine2: "",
        city: "",
        state: "",
        zip: ""
      },
      previousAddress: {
        addressLine1: "",
        addressLine2: "",
        city: "",
        state: "",
        zip: ""
      },
      dobDay: null,
      dobMonth: "",
      dobYear: null,
      ccExpiry: "",
      paymentToken: "",
      paymentCompleted: false,
      spreedlyInitialized: false,
      allowRefocus: false,
      preferredPlan: "",
    };
  },
  computed: {
    isFinalStep() {
      return this.currentStep == this.steps[this.steps.length - 1];
    },
    usePreviousAddress() {
      return !this.useCurrentAddress;
    },
    registrationData() {
      return {
        email: this.email,
        phone: this.phone,
        first_name: this.firstName,
        last_name: this.lastName,
        password: this.password,
        password_confirmation: this.passwordConfirmation,
        address: {
          address_line_1: this.address.addressLine1,
          address_line_2: this.address.addressLine2,
          city: this.address.city,
          state: this.address.state,
          zip: this.address.zip,
        },
        use_previous_address: this.usePreviousAddress,
        previous_address: {
          address_line_1: this.previousAddress.addressLine1,
          address_line_2: this.previousAddress.addressLine2,
          city: this.previousAddress.city,
          state: this.previousAddress.state,
          zip: this.previousAddress.zip,
        },
        dob_month: this.dobMonth,
        dob_day: this.dobDay,
        dob_year: this.dobYear,
        preferred_plan: this.preferredPlan,
      };
    },
    storableData() {
      return {
        email: this.email,
        phone: this.phone,
        first_name: this.firstName,
        last_name: this.lastName,
        address: this.address,
        previousAddress: this.previousAddress,
        dob_month: this.dobMonth,
        dob_day: this.dobDay,
        dob_year: this.dobYear,
        preferred_plan: this.preferredPlan,
      };
    },
  },
  methods: {
    nextStep() {
      const currentStepIndex = this.steps.findIndex((x) => x == this.currentStep);

      const validation = RegistrationValidator.validate(this.currentStep, this.registrationData);

      if (validation.status == "valid") {
        this.errors = [];
      } else {
        this.errors = validation.errors;
        return;
      }

      if (this.isFinalStep) {
        this.submitRegistrationForm();
      } else {
        this.currentStep = this.steps[currentStepIndex + 1];
        this.allowRefocus = true;
      }

      // Store data in local storage in case the user abandons and wants to resume later
      localStorage.setItem("registrationData", JSON.stringify(this.storableData));

      history.pushState({}, "", `/registration/${this.currentStep}`);
    },
    prevStep() {
      const currentStepIndex = this.steps.findIndex((x) => x == this.currentStep);

      if (currentStepIndex > 0) {
        this.currentStep = this.steps[currentStepIndex - 1];
        this.allowRefocus = true;
      }

      history.pushState({}, "", `/registration/${this.currentStep}`);
    },
    submitRegistrationForm() {
      this.showSpinner = true;

      axios
        .post("/registration/complete.json", this.registrationData)
        .then((response) => {
          if (response.data.result == "success") {
            window.location = response.data.redirect_path;
          } else {
            this.showSpinner = false;
            this.errors = response.data.errors;
          }
        })
        .catch((e) => {
          window.Sentry.captureException(e);
          this.showSpinner = false;
          this.errors = ["An error occurred while attempting to process your request"];
        });
    },
    preloadFormData() {
      // Check if we have stored data to load
      const storedData = localStorage.getItem("registrationData");
      if (storedData) {
        const parsedData = JSON.parse(storedData);
        this.email = parsedData.email;
        this.phone = parsedData.phone;
        this.firstName = parsedData.first_name;
        this.lastName = parsedData.last_name;
        this.addressLine1 = parsedData.address_line_1;
        this.addressLine2 = parsedData.address_line_2;
        this.city = parsedData.city;
        this.state = parsedData.state;
        this.zip = parsedData.zip;
        this.dobMonth = parsedData.dob_month;
        this.dobDay = parsedData.dob_day;
        this.dobYear = parsedData.dob_year;
        this.preferredPlan = parsedData.preferred_plan;
      } else if (window.location.href.indexOf("localhost:4000") > -1) {
        this.email = `rohitn+${new Date().getTime()}@creditsage.com`;
        this.phone = `4045049006`;
        this.firstName = "Mister";
        this.lastName = "Person";
        this.addressLine1 = "42nd Random Place";
        this.addressLine2 = "";
        this.city = "New York";
        this.state = "NY";
        this.zip = "10005";
        this.dobMonth = "6";
        this.dobDay = "04";
        this.dobYear = "1950";
        this.password = "StrongPass123!";
        this.passwordConfirmation = "StrongPass123!";
      }
    },
    setPreferredPlanFromUrl() {
      const urlParams = new URLSearchParams(window.location.search);

      const planSlug = urlParams.get("plan");
      if (planSlug) {
        this.preferredPlan = planSlug;
      }
    },
    resumeFromUrlStep(){
      const path = window.location.pathname;
      const stepFromPath = path.split("/").pop();

      if (this.steps.includes(stepFromPath)) {
        // Iterate through the steps in order, validating each one and stopping
        // if either the step is invalid or we reach the step from the URL path
        for (const step of this.steps) {
          const validation = RegistrationValidator.validate(step, this.registrationData);

          if (validation.status == "valid") {
            this.errors = [];
          } else {
            this.errors = validation.errors;
            return;
          }

          if (step == stepFromPath) {
            this.currentStep = step;
            return;
          }
        }
      }
    }
  },
  mounted() {
    this.preloadFormData();
    this.setPreferredPlanFromUrl()
    this.resumeFromUrlStep()
  },
  updated() {
    if (this.allowRefocus && this.currentStep == "name") {
      document.getElementById("user-first-name").focus();
      this.allowRefocus = false;
    } else if (this.allowRefocus && this.currentStep == "dob") {
      document.getElementById("user-dob-month").focus();
      this.allowRefocus = false;
    } else if (this.allowRefocus && this.currentStep == "email") {
      document.getElementById("user-email").focus();
      this.allowRefocus = false;
    }
  },
};
</script>
