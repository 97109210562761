<template>
  <div class="container mx-auto">
    <div class="flex gap-3xl">
      <div class="flex-grow">
        <div class="flex md:flex-row flex-col gap-xl">
          <div class="flex-shrink">
            <h3>Negative Marks</h3>
            <p class="my-sm">
              Accounts that appear as delinquent or derogatory on your credit report can significantly impact your
              credit score and your ability to secure new credit.
            </p>
          </div>
          <div style="min-width: 200px">
            <div v-if="store.creditReport">
              <FactorSummary
                :displayValue="negativeMarks"
                :statusText="store.scoreFactors.negativeMarks.status"
                :lastUpdated="store.creditReport.reportDate[bureau]"
              />
            </div>
          </div>
        </div>

        <div class="my-sm">
          <BureauTabs :bureau="bureau" @select-bureau="(newBureau) => (bureau = newBureau)" />

          <div v-if="store.creditReport" class="bg-white p-xl">
            <table class="table table-noborder w-full table-fixed">
              <thead>
                <tr>
                  <th class="text-left">Account</th>
                  <th class="text-right">Status</th>
                </tr>
              </thead>
              <tbody class="text-pure-black">
                <tr v-for="(tradeline, i) in negativeAccounts" :key="i">
                  <td class="font-bold">{{ tradeline.creditorName }}</td>
                  <td class="text-right">{{ tradeline.isDelinquent ? "Delinquent" : "Derogatory" }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div>
          <div>
            <h3 class="my-md text-black">Delinquent Accounts</h3>
            <ul class="ml-5 list-disc list-outside">
              <li>
                Delinquent accounts are those that are past due on payments. When you fail to make payments on time, the
                account becomes delinquent.
              </li>
              <li>
                Delinquencies are typically categorized by the number of days past due, such as 30, 60, 90, or 120+
                days.
              </li>
              <li>
                Even a single late payment can have a negative impact on your credit report, although the severity of
                the impact may increase with the length of the delinquency.
              </li>
            </ul>
            <h3 class="my-md text-black">Derogatory Accounts</h3>
            <ul class="ml-5 list-disc list-outside">
              <li>
                Derogatory accounts are more severe than delinquent accounts. They often have more serious negative
                marks against them, such as charge-offs, collections, bankruptcies, foreclosures, or tax liens.
              </li>
              <li>
                These accounts can significantly damage your credit score and stay on your credit report for several
                years, making it difficult to obtain new credit.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div style="min-width: 265px">
        <SideNav />

        <div class="border-l-4 border-gray-800 p-sm my-sm">
          <p class="comment-s font-bold mb-sm">
            We count all accounts that have had a late payment or negative mark in the last 2 years
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { store } from "../store";
import SideNav from "./side_nav.vue";
import BureauTabs from "../bureau_tabs.vue";
import FactorSummary from "./factor_summary.vue";

export default {
  components: {
    SideNav,
    BureauTabs,
    FactorSummary,
  },
  data() {
    return {
      bureau: "tuc",
      store,
    };
  },
  computed: {
    delinquentAccounts: function () {
      return parseInt(store.creditReport.summary.tradelineSummary[this.bureau].delinquentAccounts);
    },
    derogatoryAccounts: function () {
      return parseInt(store.creditReport.summary.tradelineSummary[this.bureau].derogatoryAccounts);
    },
    negativeMarks: function () {
      return this.delinquentAccounts + this.derogatoryAccounts;
    },
    negativeAccounts() {
      if (!store.creditReport) {
        return [];
      }

      return store.creditReport.tradelines
        .filter((tradeline) => tradeline[this.bureau].isDelinquent || tradeline[this.bureau].isDerogatory)
        .map((tradeline) => tradeline[this.bureau]);
    },
  },
};
</script>
