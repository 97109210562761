<template>
  <div class="w-full h-full p-2">
    <form id="ccs-auth" action="/ccs-auth" v-on:submit.prevent="submitSSN">
      <div class="w-full mx-auto my-12 registration-step md:max-w-xl">
        <h4 class="text-3xl font-bold text-center">Verification</h4>

        <div class="my-6 text-center" v-if="mode == 'last4'">
          <div>Please enter the last 4 digits of your SSN.</div>
          <input
            id="ssn-last-4"
            type="password"
            v-model="ssnLast4"
            inputmode="numeric"
            pattern="[0-9]*"
            @input="formatSSNLast4"
            class="my-3"
            data-lpignore="true"
            autocomplete="off"
            autofocus />
          <div class="text-sm text-slate-400">
            (This will NOT affect your credit. Your SSN is used to verify your identity.)
          </div>
        </div>

        <div class="my-6 text-center" v-if="mode == 'full'">
          <div id="enrichment-failure-error" v-if="enrichmentFailed">We were unable to verify your identity - Please enter your complete SSN.</div>
          <SsnInput :ssn="ssn" @ssn-input="(inputSsn) => (ssn = inputSsn)" />
          <div v-if="ssn.length > 9" class="text-sm text-red-700">SSN cannot exceed 9 digits</div>
          <div v-if="/[^\d]/.test(ssn)" class="text-sm text-red-700">Please enter digits only</div>

          <div class="text-sm text-slate-400">
            (This will NOT affect your credit. Your SSN is used to verify your identity.)
          </div>
        </div>

        <div class="text-sm text-slate-800">
          By clicking "Continue below, you agree to and accept the Terms and Conditions and Privacy Policy, and confirm
          your authorization for Standard Scores to obtain your credit profile from any consumer reporting agency for
          your own use, to confirm your identity, to notify you of credit opportunities and other products that may be
          available, and to monitor your credit for changes.
        </div>
      </div>

      <div class="w-full mx-auto mb-3 text-center md:max-w-xl">
        <div v-if="errors" id="error-messages">
          <div v-for="error in errors" :key="error" class="text-red-700">{{ error }}</div>
        </div>
      </div>

      <div class="w-full mx-auto md:max-w-xl" v-if="!showSpinner">
        <button
          id="btn-continue"
          type="button"
          class="block w-64 p-3 mx-auto text-lg font-semibold bg-amber-400 hover:bg-amber-500"
          v-on:click="submitSSN()">
          Continue
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="inline-block w-5 h-5 align-sub">
            <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75" />
          </svg>
        </button>
      </div>

      <div v-if="showSpinner" class="text-center">
        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" class="inline-block">
          <path d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,19a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z" opacity=".25" />
          <path
            d="M10.14,1.16a11,11,0,0,0-9,8.92A1.59,1.59,0,0,0,2.46,12,1.52,1.52,0,0,0,4.11,10.7a8,8,0,0,1,6.66-6.61A1.42,1.42,0,0,0,12,2.69h0A1.57,1.57,0,0,0,10.14,1.16Z">
            <animateTransform
              attributeName="transform"
              type="rotate"
              dur="0.75s"
              values="0 12 12;360 12 12"
              repeatCount="indefinite" />
          </path>
        </svg>
      </div>
    </form>
  </div>
</template>

<script>
import axios from "axios";
import SsnInput from "./ssn_input.vue";

export default {
  components: {
    SsnInput,
  },
  data: function () {
    return {
      errors: null,
      enrichmentFailed: false,
      showSpinner: false,
      mode: "last4",
      ssn: "",
      ssnLast4: "",
      trustBlackbox: "",
    };
  },
  computed: {},
  methods: {
    formatSSNLast4() {
      this.ssnLast4 = this.ssnLast4.replace(/[^0-9]/g, "").slice(0, 4);
    },
    validateSSNLast4() {
      if (this.ssnLast4.match(/^\d{4}$/)) {
        return true;
      } else {
        this.errors = ["Please enter the last 4 digits of your SSN"];
        return false;
      }
    },
    validateSSN() {
      if (this.ssn.match(/^\d{9}$/)) {
        return true;
      } else {
        this.errors = ["Please enter your complete SSN"];
        return false;
      }
    },
    attemptEnrichment: function () {
      if (!this.validateSSNLast4()) {
        return;
      }
      this.errors = null;
      this.showSpinner = true;
      axios
        .post("/ccs-auth/enrich", {
          ssn_last4: this.ssnLast4,
          trust_blackbox: this.trustBlackbox,
        })
        .then((response) => {
          if (response.data.result == "success") {
            window.location = response.data.redirect_path;
          } else {
            this.showSpinner = false;
            // SSN Last 4 enrichment failed - let's ask for the full SSN
            this.enrichmentFailed = true;
            this.mode = "full";
          }
        })
        .catch((e) => {
          window.Sentry.captureException(e);
          this.showSpinner = false;
          this.errors = ["An error occurred while attempting to process your request"];
        });
    },
    submitFullSSN: function () {
      if (!this.validateSSN()) {
        return;
      }
      this.showSpinner = true;
      axios
        .post("/ccs-auth/enrich", {
          ssn: this.ssn,
          trust_blackbox: this.trustBlackbox,
        })
        .then((response) => {
          if (response.data.result == "success") {
            window.location = response.data.redirect_path;
          } else {
            this.showSpinner = false;
            if (response.data.redirect_path) {
              window.location = response.data.redirect_path;
            } else {
              this.errors = response.data.errors;
            }
          }
        })
        .catch((e) => {
          window.Sentry.captureException(e);
          this.showSpinner = false;
          this.errors = ["An error occurred while attempting to process your request"];
        });
    },
    setTrustBlackbox: function () {
      try {
        this.trustBlackbox = window.IGLOO.getBlackbox().blackbox;
      } catch (e) {
        this.trustBlackbox = "";
      }
    },
    submitSSN: function () {
      this.setTrustBlackbox();
      if (this.mode == "last4") {
        this.attemptEnrichment();
      } else if (this.mode == "full") {
        this.submitFullSSN();
      }
    },
  },
  mounted(){
    if (window.location.search.includes("full")) {
      this.mode = "full";
    }
  }
};
</script>
