import { reactive } from 'vue'

export const store = reactive({
  // App related
  reportLoadAttempts: 0,
  creditReportError: false,
  scoreFetched: false,

  // User related
  creditReport: null,
  creditScoreHistory: [],
  scoreFactors: [],
  profile: {
    email: "",
    firstName: "",
    lastName: "",
    fullName: "",
    unreadAlertCount: 0,
    reportDate: null,
    reportRefreshDate: null,
    currentReportID: null,
    recentAlerts: []
  }
})
