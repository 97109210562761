<template>
  <div class="svg-container">
    <svg viewBox="0 0 100 70" xmlns="http://www.w3.org/2000/svg" class="svg-content" v-if="score > 0">
      <circle
        cx="50"
        cy="50"
        v-bind:r="circleRadius"
        fill="transparent"
        stroke="#DDDDDD"
        stroke-width="1"
        stroke-dasharray="6 2" />
      <path v-bind:d="arcPath" v-bind:stroke="arcColor" fill="transparent" stroke-width="3" v-if="score"></path>
      <image :x="bureauLogoX" :y="bureauLogoY" width="30" :href="bureauLogo" />
      <text x="28" y="50" font-size="0.8em" v-bind:fill="arcColor" font-weight="semibold" font-family="Helvetica, Arial, sans-serif">
        {{ score }}
      </text>
      <text x="52" y="49" font-size="0.45em" fill="#BBBBBB">/ 850</text>
      <text x="50" y="63" font-size="0.35em" v-bind:fill="arcColor" font-weight="semibold" style="text-anchor: middle">
        {{ scoreAdjective }}
      </text>
    </svg>
    <div class="my-12 text-xs text-center text-gray-500" v-else>No credit score information to display</div>
  </div>
</template>

<script>
export default {
  computed: {
    arcPath: function () {
      const angle = Math.round(((this.score - 300) / 550) * 240);

      return this.describeArc(50, 50, this.circleRadius, this.arcStartAngle, angle + this.arcStartAngle);
    },
    arcColor: function () {
      if (this.score < 629) {
        return "#d65951";
      } else if (this.score < 719) {
        return "#e49b48";
      } else {
        return "#4ab954";
      }
    },
    scoreAdjective: function () {
      if (this.score < 629) {
        return "POOR";
      } else if (this.score < 719) {
        return "FAIR";
      } else {
        return "GOOD";
      }
    },
    bureauLogo(){
      if (this.bureau == "eqf") {
        return "/images/bureau_logos/equifax.svg";
      } else if (this.bureau == "exp") {
        return "/images/bureau_logos/experian.svg";
      } else if (this.bureau == "tuc") {
        return "/images/bureau_logos/transunion.svg";
      } else {
        return "";
      }
    },
    bureauLogoX(){
      if (this.bureau == "eqf") {
        return 37;
      } else if (this.bureau == "exp") {
        return 35;
      } else if (this.bureau == "tuc") {
        return 36;
      } else {
        return 0;
      }
    },
    bureauLogoY(){
      if (this.bureau == "eqf") {
        return 28;
      } else if (this.bureau == "exp") {
        return 25;
      } else if (this.bureau == "tuc") {
        return 25;
      } else {
        return 0;
      }
    },
  },
  methods: {
    polarToCartesian: function (centerX, centerY, radius, angleInDegrees) {
      var angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;

      return {
        x: centerX + radius * Math.cos(angleInRadians),
        y: centerY + radius * Math.sin(angleInRadians),
      };
    },
    describeArc: function (x, y, radius, startAngle, endAngle) {
      var start = this.polarToCartesian(x, y, radius, endAngle);
      var end = this.polarToCartesian(x, y, radius, startAngle);

      var largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";

      var d = ["M", start.x, start.y, "A", radius, radius, 0, largeArcFlag, 0, end.x, end.y].join(" ");

      return d;
    },
  },
  data: function () {
    return {
      circleRadius: 40,
      arcStartAngle: -120,
    };
  },
  props: [
    "bureau",
    "score", // MUST be between 300 and 850
  ],
};
</script>
