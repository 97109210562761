<template>
  <div class="w-full h-full p-2">
    <form id="update-personal-info-form" action="/update-personal-info">
      <div class="w-full mx-auto my-12 registration-step md:max-w-xl">
        <h4 class="text-3xl font-bold text-center">Update Personal Information</h4>

        <div class="w-full mx-auto my-6 registration-step md:max-w-xl">
          <h4 class="text-xl font-semibold">Personal Details</h4>

          <div class="my-3">
            <div>
              <label class="block text-sm">First Name</label>
              <input id="update-personal-info-form.first_name" type="text" class="w-full my-1 text-lg border-slate-200" v-model="firstName" placeholder="First Name" />
            </div>

            <div>
              <label class="block text-sm">Last Name</label>
              <input id="update-personal-info-form.last_name" type="text" class="w-full my-1 text-lg border-slate-200" v-model="lastName" placeholder="Last Name" />
            </div>

            <div>
              <label class="block text-sm">Phone</label>
              <input id="update-personal-info-form.phone" type="text" class="w-full my-1 text-lg border-slate-200" v-model="phone" placeholder="Enter phone number" />
            </div>
          </div>
        </div>

        <div>
          <div class="w-full mx-auto mt-6 registration-step md:max-w-xl">
            <h4 class="text-xl font-semibold">Address</h4>

            <div class="my-3">
              <input id="update-personal-info-form.address_line_1" type="text" class="w-full my-1 text-lg border-slate-200" v-model="address_line_1" placeholder="Address Line 1" autofocus />
              <input id="update-personal-info-form.address_line_2" type="text" class="w-full my-1 text-lg border-slate-200" v-model="address_line_2" placeholder="Address Line 2" />
              <input id="update-personal-info-form.city" type="text" class="w-full my-1 text-lg border-slate-200" v-model="city" placeholder="City" />
              <StateSelect v-model="state" class="w-full text-lg border-slate-200" />
              <input id="update-personal-info-form.zip" type="text" class="w-full my-1 text-lg border-slate-200" v-model="zip" placeholder="Zip Code" />
            </div>
          </div>

        </div>

        <div class="w-full mx-auto my-6 registration-step md:max-w-xl">
          <h4 class="text-xl font-semibold">Social Security Number</h4>

          <div class="my-3">
            <SsnInput :ssn="ssn" @ssn-input="(inputSsn) => ssn = inputSsn" />
          </div>
        </div>

        <div class="w-full mx-auto text-center md:max-w-xl">
          <div v-if="errors">
            <div v-for="error in errors" :key="error" class="text-red-700">{{ error }}</div>
          </div>
        </div>

        <div v-if="showSpinner" class="text-center">
          <Spinner />
        </div>

        <button type="button" class="w-full p-3 mt-3 text-lg font-semibold bg-brand hover:bg-amber-400" v-on:click="submit()" v-if="!showSpinner">
          Submit
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="inline-block w-5 h-5 align-sub">
            <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75" />
          </svg>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import axios from 'axios'
import SsnInput from "../ccs_auth/ssn_input.vue"
import Spinner from "../shared/spinner_svg.vue"
import StateSelect from "./state_select.vue"

export default {
  components: {
    SsnInput,
    Spinner,
    StateSelect
  },
  data(){
    return {
      showSpinner: false,
      errors: ["Please recheck your information to ensure that it's accurate, and submit the form again"],
      firstName: "",
      lastName: "",
      phone: "",
      address_line_1: "",
      address_line_2: "",
      city: "",
      state: "",
      zip: "",
      ssn: "",
      trustBlackbox: ""
    }
  },
  methods: {
    submit(){
      this.showSpinner = true
      this.errors = []
      try {
        this.trustBlackbox = window.IGLOO.getBlackbox().blackbox
      } catch (e) {
        this.trustBlackbox = ""
      }

      axios.post("/update-personal-info", {
        first_name: this.firstName,
        last_name: this.lastName,
        phone: this.phone,
        address_line_1: this.address_line_1,
        address_line_2: this.address_line_2,
        city: this.city,
        state: this.state,
        zip: this.zip,
        ssn: this.ssn,
        trust_blackbox: this.trustBlackbox
      }).then((response) => {
        this.showSpinner = false
        if(response.data.result == "success"){
          // Registration completed, we need to start going through the
          // auth/enrollment process.
          window.location = "/ccs-auth"
        }else{
          this.errors = response.data.errors
        }
      }).catch((e) => {
        window.Sentry.captureException(e)
        this.showSpinner = false
        this.errors = ["An error occurred while attempting to process your request"]
      })
    }
  },
  mounted(){
    axios.get(
      "/personal-info"
    ).then((response) => {
      this.firstName = response.data.first_name
      this.lastName = response.data.last_name
      this.phone = response.data.phone
      this.address_line_1 = response.data.address_line_1
      this.address_line_2 = response.data.address_line_2
      this.city = response.data.city
      this.state = response.data.state
      this.zip = response.data.zip
    })
  }
}
</script>
