<template>
  <div v-bind:data-bureau="bureau">
    <span data-field="address-street">{{ sanitizedAddress.street }}</span>
    <span v-if="sanitizedAddress.street">, </span>
    <span data-field="address-city">{{ sanitizedAddress.city }}</span>
    <span>, </span>
    <span data-field="address-state">{{ sanitizedAddress.state }}</span>
    <span>, </span>
    <span data-field="address-zip">{{ sanitizedAddress.zip }}</span>
  </div>
</template>

<script>
export default {
  props: {
    address: {
      type: Object,
      required: false
    },
    bureau: {
      type: String,
      required: true
    }
  },
  computed: {
    sanitizedAddress() {
      if (this.address) {
        return {
          street: this.address.street || '',
          city: this.address.city || '',
          state: this.address.state || '',
          zip: this.address.zip || ''
        }
      } else {
        return {
          street: '',
          city: '',
          state: '',
          zip: ''
        }
      }
    }
  }
}
</script>
