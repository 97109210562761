<template>
  <svg viewBox="6 6 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.668 17.9993C12.668 14.7777 15.2796 12.166 18.5013 12.166M22.2512 14.2493L18.5012 17.9993M26.8346 17.9993C26.8346 22.6017 23.1036 26.3327 18.5013 26.3327C13.8989 26.3327 10.168 22.6017 10.168 17.9993C10.168 13.397 13.8989 9.66602 18.5013 9.66602C23.1036 9.66602 26.8346 13.397 26.8346 17.9993ZM19.3346 17.9993C19.3346 18.4596 18.9616 18.8327 18.5013 18.8327C18.0411 18.8327 17.668 18.4596 17.668 17.9993C17.668 17.5391 18.0411 17.166 18.5013 17.166C18.9616 17.166 19.3346 17.5391 19.3346 17.9993Z"
      stroke="currentColor"
      stroke-width="1.25"
      stroke-linecap="round"
      stroke-linejoin="round" />
  </svg>
</template>
<script>
export default {
  name: "GaugeIcon",
};
</script>
