import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = ["href"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.store.scoreFactors, (factor) => {
      return (_openBlock(), _createElementBlock("div", {
        key: factor.path
      }, [
        _createElementVNode("a", {
          href: factor.path,
          class: "block my-md comment text-black"
        }, [
          _createElementVNode("div", {
            class: _normalizeClass({
            'font-bold': $options.isCurrentHash(factor.path),
          })
          }, _toDisplayString(factor.label), 3 /* TEXT, CLASS */)
        ], 8 /* PROPS */, _hoisted_1)
      ]))
    }), 128 /* KEYED_FRAGMENT */))
  ]))
}