<template>
  <div class="container mx-auto">
    <div class="grid grid-cols-1 md:gap-4 md:mt-12 md:grid-cols-3">
      <div class="col-span-2">
        <div>
          <div class="mb-3 text-4xl font-semibold">Payment History</div>
          <p>
            Timely and consistent payment history is one of the most critical factors that lenders consider when
            evaluating your creditworthiness. Your payment history accounts for a significant portion of your credit
            score and plays a crucial role in determining your ability to secure future loans and credit.
          </p>
        </div>

        <div class="my-6 text-sm font-bold text-gray-500">
          <span>Factors</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="inline-block w-3 h-3 mx-2">
            <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3" />
          </svg>
          <span>Payment History</span>
        </div>

        <div class="text-xl font-semibold">How is your payment history calculated?</div>
        <p>
          Payment history can be estimated using the number of on time payments you made divided by the number of
          payments you were responsible to make.
        </p>
        <div class="my-5 text-center">
          <div class="flex justify-center gap-2">
            <div>
              <Transition name="fade" mode="out-in">
                <div class="text-2xl text-gray-500" v-if="store.creditReport" :key="bureau">
                  {{ totalOnTimePayments }}
                </div>
              </Transition>
              <div class="font-semibold">On Time Payments</div>
            </div>
            <div class="flex flex-col"><div class="mt-auto">/</div></div>
            <div>
              <Transition name="fade" mode="out-in">
                <div class="text-2xl text-gray-500" v-if="store.creditReport" :key="bureau">
                  {{ totalPayments }}
                </div>
              </Transition>
              <div class="font-semibold">Total Payments</div>
            </div>
            <div class="flex flex-col"><div class="mt-auto">=</div></div>
            <div>
              <Transition name="fade" mode="out-in">
                <div class="text-2xl text-gray-500" v-if="store.creditReport" :key="bureau">
                  {{ totalOnTimePercentage }}
                </div>
              </Transition>
              <div class="font-semibold">On Time (%)</div>
            </div>
          </div>
        </div>

        <p class="mb-5">To maintain a strong payment history:</p>
        <p class="mb-5">
          <strong>Pay On Time:</strong>
          Ensure that you make all payments on or before their due dates, including credit card bills, loan
          installments, and utility bills.
        </p>
        <p class="mb-5">
          <strong>Set Up Payment Reminders:</strong>
          Consider setting up payment reminders or automatic payments to avoid forgetting due dates.
        </p>
        <p class="mb-5">
          <strong>Budget Wisely:</strong>
          Manage your finances effectively to ensure you have enough funds to cover your financial obligations.
        </p>
        <p class="mb-5">
          <strong>Communicate with Lenders:</strong>
          If you're facing financial difficulties and anticipate payment issues, contact your lenders to discuss options
          or payment arrangements.
        </p>

        <div v-if="store.creditReport">
          <div class="my-3 text-xl font-semibold">Payments by Account</div>

          <table class="table w-full table-fixed">
            <thead>
              <tr>
                <th class="text-left">Account</th>
                <th class="text-right">Total</th>
                <th class="text-right">Missed</th>
                <th class="text-right">On Time</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(tradeline, i) in tradelines" :key="i">
                <td class="font-semibold">{{ tradeline[this.bureau].creditorName }}</td>
                <td class="text-right">
                  {{ tradeline[this.bureau].monthsReviewed }}
                </td>
                <td class="text-right">
                  {{
                    tradeline[this.bureau].late30Count +
                    tradeline[this.bureau].late60Count +
                    tradeline[this.bureau].late90Count
                  }}
                </td>
                <td class="text-right">{{ onTimePercentage(tradeline[this.bureau]) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="order-first md:order-last">
        <div v-if="store.creditReport">
          <div class="rounded-md">
            <BureauTabs :bureau="bureau" @select-bureau="(newBureau) => (bureau = newBureau)" />
            <Transition name="fade" mode="out-in">
              <div
                v-bind:class="`text-center py-5 rounded-b-md border border-t-0 bg-gradient-${store.scoreFactors.paymentHistory.status}`"
                :key="bureau">
                <div class="my-3 text-4xl font-semibold">{{ totalOnTimePercentage }}</div>
                <div class="text-sm">
                  Updated: {{ formatDate(store.creditReport.reportDate[this.bureau], "MMM D, YYYY") }}
                </div>
                <div
                  v-bind:class="`my-3 credit-score-factor-status credit-score-factor-status-${store.scoreFactors.paymentHistory.status}`">
                  {{ store.scoreFactors.paymentHistory.status }}
                </div>
              </div>
            </Transition>
          </div>
        </div>
        <SideNav v-bind:scoreFactors="store.scoreFactors" />
      </div>
    </div>
  </div>
</template>

<script>
import { store } from "../store"
import SideNav from "./side_nav.vue";
import BureauTabs from "../bureau_tabs.vue";

export default {
  components: {
    SideNav,
    BureauTabs,
  },
  data() {
    return {
      bureau: "tuc",
      store
    };
  },
  computed: {
    tradelines() {
      return store.creditReport.tradelines.filter((tradeline) => {
        return tradeline[this.bureau].paymentHistory && tradeline[this.bureau].paymentHistory.length > 0;
      });
    },
    totalOnTimePayments() {
      return this.tradelines.reduce((total, tradeline) => {
        return (
          total +
          tradeline[this.bureau].monthsReviewed -
          (tradeline[this.bureau].late30Count + tradeline[this.bureau].late60Count + tradeline[this.bureau].late90Count)
        );
      }, 0);
    },
    totalPayments() {
      return this.tradelines.reduce((total, tradeline) => {
        return total + tradeline[this.bureau].monthsReviewed;
      }, 0);
    },
    totalOnTimePercentage() {
      if (this.totalPayments > 0) {
        return `${Math.round((this.totalOnTimePayments * 100) / this.totalPayments)}%`;
      } else {
        return "-";
      }
    },
  },
  methods: {
    onTimePercentage(data) {
      if (data.monthsReviewed > 0) {
        const onTimeCount = data.monthsReviewed - (data.late30Count + data.late60Count + data.late90Count);
        return `${Math.round((onTimeCount * 100) / data.monthsReviewed)}%`;
      } else {
        return "-";
      }
    },
  },
};
</script>
