import moment from "moment";

const factors = {
  paymentHistory: {
    label: "Payment History",
    path: "#/education/payment-history",
    importance: "80%",
  },
  utilization: {
    label: "Utilization",
    path: "#/education/credit-utilization",
    importance: "45%",
  },
  ageOfCredit: {
    label: "Age of Credit",
    path: "#/education/age-of-credit",
    importance: "45%",
  },
  negativeMarks: {
    label: "Negative Marks",
    path: "#/education/negative-marks",
    importance: "20%",
  },
  availableCredit: {
    label: "Available Credit",
    path: "#/education/available-credit",
    importance: "7%",
  },
  creditInquiries: {
    label: "Credit Inquiries",
    path: "#/education/credit-inquiries",
    importance: "7%",
  },
};

// count of negative items
// if count == 0 then green
// if count > 0 and <= 2 then yellow
// if count > 2 then red
function negativeItemsStatus(reportSummary: object): string {
  const delinquentAccounts = reportSummary["tradelineSummary"]?.all?.delinquentAccounts ?? 0;
  const derogatoryAccounts = reportSummary["tradelineSummary"]?.all?.derogatoryAccounts ?? 0;
  const totalNegativeItems = delinquentAccounts + derogatoryAccounts;

  if (totalNegativeItems == 0) {
    return "good";
  } else if (totalNegativeItems <= 2) {
    return "fair";
  } else {
    return "bad";
  }
}

// count all inquiries in the last 6 months
// if count < 5 then green
// if count >= 5 and < 10 yellow
// if count >= 10 then red
function inquiryStatus(inquiries: Array<object>): string {
  let inquiriesLast6Months = 0;
  const sixMonthsAgo = moment().subtract(6, "months");

  inquiries.forEach((inquiry) => {
    const inquiryDate = moment(inquiry["date"]);
    if (inquiryDate.isAfter(sixMonthsAgo)) {
      inquiriesLast6Months++;
    }
  });

  if (inquiriesLast6Months < 5) {
    return "good";
  } else if (inquiriesLast6Months < 10) {
    return "fair";
  } else {
    return "bad";
  }
}

// Sum the remaining available credit on all open revolving accounts
// if the number is < $10k then red
// if the number is >= $10k < $35k then yellow
// if the number is >= $35k the green

function availableCreditStatus(tradelines): string {
  const totalAvailableCredit = tradelines
    .filter(
      (tradeline) =>
        tradeline.tuc.creditType === "Revolving Account" &&
        tradeline.tuc.accountOpenClosed === "Open" &&
        tradeline.tuc.creditLimitAmount > 0
    )
    .map((tradeline) => tradeline.tuc)
    .reduce((acc, cur) => acc + cur.creditLimitAmount - cur.balanceAmount, 0);

  if (totalAvailableCredit < 10000) {
    return "bad";
  } else if (totalAvailableCredit < 35000) {
    return "fair";
  } else {
    return "good";
  }
}

// Average how long all open accounts have been open for
// if avg >= 11 years then green
// if avg >= 6 and < 11 years then yellow
// if avg < 6 then red

function ageOfCreditStatus(tradelines): string {
  const openAccountAges = tradelines
    .filter((tradeline) => tradeline.tuc.accountOpenClosed === "Open")
    .map((tradeline) => {
      const accountAge = moment().diff(moment(tradeline.tuc.dateOpened), "years", true);
      return accountAge;
    });

  const totalAccountAge = openAccountAges.reduce((acc, cur) => acc + cur, 0);
  const avgAccountAge = totalAccountAge / openAccountAges.length;

  if (avgAccountAge >= 11) {
    return "good";
  } else if (avgAccountAge >= 6) {
    return "fair";
  } else {
    return "bad";
  }
}

// Sum the limits on all open revolving accounts
// Sum the balances on all open revolving accounts
// Divide the sum of balances by the sum of limits
// if the quotient is < .2 then green
// if the quotient is >= .2 and < .3 then yellow
// if the quotient is >= .3 then red

function creditUtilizationStatus(tradelines) {
  const openRevolvingAccounts = tradelines
    .filter(
      (tradeline) =>
        tradeline.tuc.creditType === "Revolving Account" &&
        tradeline.tuc.accountOpenClosed === "Open" &&
        tradeline.tuc.creditLimitAmount > 0
    )
    .map((tradeline) => tradeline.tuc);

  const totalCreditLimit = openRevolvingAccounts.reduce((acc, cur) => acc + cur.creditLimitAmount, 0);
  const totalBalance = openRevolvingAccounts.reduce((acc, cur) => acc + cur.balanceAmount, 0);

  // The || 0 is to prevent NaN if there are no open credit cards
  const creditUtilization = totalBalance / totalCreditLimit || 0;

  if (creditUtilization < 0.2) {
    return "good";
  } else if (creditUtilization < 0.3) {
    return "fair";
  } else {
    return "bad";
  }
}

// No late payments on any account in the last 2 years = Green
// Any late payments on any account in the last 2 years = Yellow
// Currently late on any accounts = Red

function paymentHistoryStatus(tradelines): string {
  const currentlyLatePayment = tradelines.find((tradeline) => tradeline.tuc.pastDueAmount > 0);

  if (currentlyLatePayment) {
    return "bad";
  }

  const twoYearsAgo = moment().subtract(2, "years");
  const lateInTheLast2Years = tradelines.find((tradeline) => {
    return tradeline.tuc.paymentHistory.some((payment) => {
      const paymentDate = moment(payment.date);
      return paymentDate.isAfter(twoYearsAgo) && ["1", "2", "3"].includes(payment.status);
    });
  });

  if (lateInTheLast2Years) {
    return "fair";
  } else {
    return "good";
  }
}

export default function (reportData) {
  factors.paymentHistory["status"] = paymentHistoryStatus(reportData?.tradelines || []);
  factors.utilization["status"] = creditUtilizationStatus(reportData?.tradelines || []);
  factors.ageOfCredit["status"] = ageOfCreditStatus(reportData?.tradelines || []);
  factors.negativeMarks["status"] = negativeItemsStatus(reportData?.summary || {});
  factors.availableCredit["status"] = availableCreditStatus(reportData?.tradelines || []);
  factors.creditInquiries["status"] = inquiryStatus(reportData?.inquiries?.tuc || []);

  return factors;
}
