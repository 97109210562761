<template>
  <svg viewBox="6 6 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.5846 18.0007H13.4029C13.9739 18.0007 14.4959 18.3232 14.7513 18.834C15.0067 19.3447 15.5287 19.6673 16.0997 19.6673H20.903C21.474 19.6673 21.996 19.3447 22.2513 18.834C22.5066 18.3232 23.0286 18.0007 23.5996 18.0007H26.418M15.9734 11.334H21.0291C21.9266 11.334 22.3752 11.334 22.7714 11.4706C23.1217 11.5915 23.4408 11.7887 23.7056 12.0479C24.0049 12.3412 24.2056 12.7425 24.6069 13.5451L26.4123 17.1561C26.5698 17.4711 26.6486 17.6286 26.7041 17.7936C26.7535 17.9402 26.7891 18.0911 26.8105 18.2442C26.8346 18.4167 26.8346 18.5927 26.8346 18.9449V20.6673C26.8346 22.0675 26.8346 22.7675 26.5621 23.3023C26.3225 23.7727 25.9401 24.1552 25.4696 24.3948C24.9348 24.6673 24.2348 24.6673 22.8346 24.6673H14.168C12.7678 24.6673 12.0678 24.6673 11.533 24.3948C11.0626 24.1552 10.6801 23.7727 10.4405 23.3023C10.168 22.7675 10.168 22.0675 10.168 20.6673V18.9449C10.168 18.5927 10.168 18.4167 10.1921 18.2442C10.2136 18.0911 10.2492 17.9402 10.2985 17.7936C10.354 17.6286 10.4328 17.4711 10.5903 17.1561L12.3957 13.5451C12.7971 12.7425 12.9977 12.3412 13.2971 12.0479C13.5618 11.7887 13.8809 11.5915 14.2312 11.4706C14.6273 11.334 15.076 11.334 15.9734 11.334Z"
      stroke="currentColor"
      stroke-width="1.25"
      stroke-linecap="round"
      stroke-linejoin="round" />
  </svg>
</template>
<script>
export default {
  name: "AlertsIcon",
};
</script>
