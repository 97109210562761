<template>
  <div class="container mx-auto">
    <div class="mt-12">
      <div>
        <div class="mb-3 text-4xl font-semibold">Alerts</div>
      </div>

      <div v-if="alerts.length == 0" class="my-6 text-gray-600">You have no alerts yet.</div>

      <div v-for="alert in alerts" class="flex p-3 my-3 border rounded-md" v-bind:key="alert.id">
        <div class="p-2">
          <div class="w-12 h-12 p-1 text-center border rounded-full shadow-sm">
            <BellIcon class="mx-auto mt-2 text-gray-400 h-7" />
          </div>
        </div>
        <div class="ms-2">
          <div class="mb-1">
            <span class="text-lg font-semibold">{{ alert.bureau }}</span>
            <span class="text-sm text-gray-600 ms-3">{{ formatDateTime(alert.created_at) }}</span>
          </div>
          <div class="text-gray-600">{{ alert.message }}</div>
        </div>
      </div>

      <div v-if="showSpinner" class="mt-12 text-center">
        <Spinner />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import Spinner from "../shared/spinner_svg.vue";
import { BellIcon } from "@heroicons/vue/20/solid";

export default {
  components: {
    Spinner,
    BellIcon,
  },
  props: [],
  computed: {},
  methods: {
    formatDateTime: function (datetime) {
      return moment(datetime).format("MMM D YYYY, h:mm A");
    },
  },
  data: function () {
    return {
      alerts: [],
      showSpinner: true,
    };
  },
  mounted: function () {
    axios
      .get("/portal/alerts.json")
      .then((response) => {
        this.alerts = response.data;
        window.dispatchEvent(
          new CustomEvent("ss-alerts-read")
        );
      })
      .catch((error) => {
        window.Sentry.captureException(error)
        window.dispatchEvent(
          new CustomEvent("ss-global-alert", {
            detail: "There was an error while fetching your alerts.",
          })
        );
      })
      .finally(() => {
        this.showSpinner = false;
      });
  },
};
</script>
