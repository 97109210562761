<template>
  <div class="container mx-auto">
    <div class="card">
      <div class="card-body md:p-xl p-sm">
        <h2>Alerts</h2>

        <div v-if="alerts.length == 0" class="my-6 text-gray-600">You have no alerts yet.</div>

        <div v-for="alert in alerts" class="my-sm" v-bind:key="alert.id">
          <div class="ms-2">
            <div class="mb-1">
              <span class="text-lg font-semibold">{{ alert.bureau }}</span>
              <span class="text-sm text-gray-600 ms-3">{{ formatDateTime(alert.created_at) }}</span>
            </div>
            <div class="text-gray-600">{{ alert.message ? alert.message : alert.alert_details }}</div>
          </div>
        </div>

        <div v-if="showSpinner" class="mt-12 text-center">
          <Spinner />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import Spinner from "../shared/spinner_svg.vue";

export default {
  components: {
    Spinner,
  },
  props: [],
  computed: {},
  methods: {
    formatDateTime: function (datetime) {
      return moment(datetime).format("MMM D YYYY, h:mm A");
    },
  },
  data: function () {
    return {
      alerts: [],
      showSpinner: true,
    };
  },
  mounted: function () {
    axios
      .get("/portal/alerts.json")
      .then((response) => {
        this.alerts = response.data;
        window.dispatchEvent(
          new CustomEvent("ss-alerts-read")
        );
      })
      .catch((error) => {
        window.Sentry.captureException(error)
        window.dispatchEvent(
          new CustomEvent("ss-global-alert", {
            detail: "There was an error while fetching your alerts.",
          })
        );
      })
      .finally(() => {
        this.showSpinner = false;
      });
  },
};
</script>
