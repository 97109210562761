export default {
  "C": {
    class: "bg-lime-200 text-lime-600",
    class_v2: "text-green font-bold",
    text: "C",
    description: "Current",
  },
  "U": {
    class: "text-gray-400",
    class_v2: "text-muted",
    text: "U",
    description: "Unknown",
  },
  "1": {
    class: "bg-red-200 font-semibold text-red-600",
    class_v2: "text-primary font-bold",
    text: "30D",
    description: "30 Days Late",
  },
  "2": {
    class: "bg-red-200 font-semibold text-red-600",
    class_v2: "text-primary font-bold",
    text: "60D",
    description: "60 Days Late",
  },
  "3": {
    class: "bg-red-200 font-semibold text-red-600",
    class_v2: "text-primary font-bold",
    text: "90D",
    description: "90 Days Late",
  },
  "4": {
    class: "bg-red-200 font-semibold text-red-600",
    class_v2: "text-primary font-bold",
    text: "120D",
    description: "120 Days Late",
  },
  "7": {
    class: "bg-red-200 font-semibold text-red-600",
    class_v2: "text-primary font-bold",
    text: "W",
    description: "Wage Earner Plan",
  },
  "8R": {
    class: "bg-red-200 font-semibold text-red-600",
    class_v2: "text-primary font-bold",
    text: "R",
    description: "Repossession",
  },
  "9": {
    class: "bg-red-200 font-semibold text-red-600",
    class_v2: "text-primary font-bold",
    text: "CC",
    description: "Collection Chargeoff",
  }
};
