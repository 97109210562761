<template>
  <div>
    <h3>Hey {{ firstName }} 👋</h3>

    <div class="comment-s">Your credit score is {{ creditScoreStatus }}.</div>

    <div class="factoid-xl text-green">
      {{ store.creditReport.creditScore.tuc }}
    </div>

    <div class="flex gap-md">
      <div v-if="reportDate">
        <div class="comment-s !font-bold">{{ reportDate("tuc").format("MMMM D") }}</div>
        <div class="comment-s font-medium">Your Present Score Update</div>
      </div>
      <div v-if="reportRefreshDate">
        <div class="comment-s font-medium">{{ reportRefreshDate.format("MMMM D") }}</div>
        <div class="comment-s font-medium" v-if="refreshReportAvailable">Next Report Available!</div>
        <div class="comment-s font-medium" v-else>Next Report & Scores Expected</div>

        <div class="mt-3 text-sm" v-if="refreshReportAvailable && !fetchError">
          <div v-if="fetching">
            <Spinner />
          </div>
          <div v-else>
            <div class="my-1"><button id="btn-fetch-new-report" class="btn btn-primary" @click="fetchNewReport()">Fetch
                New Report</button></div>
          </div>
        </div>
        <div v-else-if="fetchError" class="my-1 text-sm text-red-600">
          An error occurred while fetching your new report. Please try again later.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { store } from "../store.ts";
import moment from "moment";
import Spinner from "../../shared/spinner_svg.vue";

export default {
  name: "DashboardSummary",
  components: {
    Spinner,
  },
  props: {
    bureau: {
      type: String,
      default: "tuc",
    },
  },
  data() {
    return {
      store,
      fetching: false,
      fetchError: false,
    };
  },
  computed: {
    firstName() {
      return store.profile.firstName;
    },
    // if reportRefreshDate is present and equal to or before today
    refreshReportAvailable() {
      return this.reportRefreshDate && this.reportRefreshDate.isSameOrBefore(new Date, "day");
    },
    reportRefreshDate() {
      return moment(store.profile.reportRefreshDate);
    },
    creditScoreStatus() {
      const score = store.creditReport.creditScore[this.bureau];

      if (!score) {
        return "unknown";
      }

      if (score >= 700) {
        return "good";
      } else if (score >= 600) {
        return "fair";
      } else {
        return "poor";
      }
    },
  },
  methods: {
    reportDate(bureau) {
      if (store.profile.reportDate) {
        return moment(store.profile.reportDate[bureau]);
      } else {
        return null;
      }
    },
    fetchNewReport() {
      // POST to /portal/credit-report/refresh
      this.fetching = true;
      fetch("/portal/credit-report/refresh", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": document.querySelector("meta[name=csrf-token]").content,
        },
        body: JSON.stringify({}),
      })
        .then((response) => response.text())
        .then(() => {
          window.location = "/portal";
        })
        .catch((error) => {
          console.log(error)
          this.fetchError = true;
          this.fetching = false;
        })
    },
  },
};
</script>
