<template>
  <div class="container mx-auto">
    <div class="grid grid-cols-1 md:gap-4 md:mt-12 md:grid-cols-3">
      <div class="col-span-2">
        <div>
          <div class="mb-3 text-4xl font-semibold">Age of Credit</div>
          <p>
            The age of the oldest active account on your credit report is an important factor in your credit score and
            is commonly referred to as your "credit age" or "length of credit history." Lenders do indeed like to see a
            long and healthy credit history because it demonstrates your ability to manage credit responsibly over time.
          </p>
        </div>

        <div class="my-6 text-sm font-bold text-gray-500">
          <span>Factors</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="inline-block w-3 h-3 mx-2">
            <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3" />
          </svg>
          <span>Age of Credit</span>
        </div>

        <div class="text-xl font-semibold">How is your age of credit calculated?</div>
        <p>Your age of credit is calculated by using the age of your oldest open account.</p>

        <div class="my-5 text-center">
          <div class="flex justify-center gap-2">
            <div>
              <div class="text-2xl text-gray-500" v-if="canShowAgeOfCredit">
                {{ formattedDate() }}
              </div>
              <div class="font-semibold">Current Date</div>
            </div>
            <div class="flex flex-col"><div class="mt-auto">-</div></div>
            <div>
              <div class="text-2xl text-gray-500" v-if="canShowAgeOfCredit">
                {{ formattedDate(oldestOpenAccountAge) }}
              </div>
              <div class="font-semibold">Account Opened</div>
            </div>
            <div class="flex flex-col"><div class="mt-auto">=</div></div>
            <div>
              <div class="text-2xl text-gray-500" v-if="canShowAgeOfCredit">
                {{ ageOfCredit }}
              </div>
              <div class="font-semibold">Age of Credit</div>
            </div>
          </div>
        </div>
        <p class="mb-5">
          <strong>Credit History Length:</strong>
          The longer your credit history, the more data lenders have to assess your creditworthiness. This can be seen
          as a positive sign, especially if you've managed your credit well over an extended period.
        </p>
        <p class="mb-5">
          <strong>Credit Age and Credit Score:</strong>
          The age of your oldest account is one of the factors that contribute to your credit score. It typically
          accounts for about 15% of your FICO credit score. A longer credit history can positively affect your credit
          score.
        </p>
        <p class="mb-5">
          <strong>Established Creditworthiness:</strong>
          Older accounts demonstrate that you have a history of responsible credit management. Lenders are more likely
          to trust borrowers with a proven track record of paying their debts on time.
        </p>
        <p class="mb-5">
          <strong>Aging Accounts:</strong>
          Keeping older accounts open and in good standing can positively impact your credit score, even if you don't
          use them regularly. Closing these accounts can shorten your credit history, which might negatively affect your
          score.
        </p>

        <div v-if="store.creditReport">
          <div class="my-3 text-xl font-semibold">Age of your accounts</div>

          <table class="table w-full table-fixed">
            <thead>
              <tr>
                <th class="py-2 text-left">Account</th>
                <th class="py-2 text-left">Opened</th>
                <th class="py-2 text-left">Utilization</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(tradeline, i) in openTradelines" :key="i">
                <td class="font-semibold">{{ tradeline[bureau].creditorName }}</td>
                <td>
                  {{ formatDate(tradeline[bureau].dateOpened, "MMM D, YYYY") }}
                </td>
                <td>{{ distanceOfTime(tradeline[bureau].dateOpened) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="order-first md:order-last">
        <div v-if="canShowAgeOfCredit">
          <div class="rounded-md">
            <BureauTabs :onlyTuc="store.creditReport.singleBureauReport" :bureau="bureau" @select-bureau="(newBureau) => (bureau = newBureau)" />
            <Transition name="fade" mode="out-in">
              <div
                v-bind:class="`text-center py-5 rounded-b-md border border-t-0 bg-gradient-${store.scoreFactors.ageOfCredit.status}`"
                :key="bureau">
                <div class="my-3 text-4xl font-semibold">{{ ageOfCredit }}</div>
                <div class="text-sm">Updated: {{ formatDate(store.creditReport.reportDate[bureau], "MMM D, YYYY") }}</div>
                <div
                  v-bind:class="`my-3 credit-score-factor-status credit-score-factor-status-${store.scoreFactors.ageOfCredit.status}`">
                  {{ store.scoreFactors.ageOfCredit.status }}
                </div>
              </div>
            </Transition>
          </div>
        </div>
        <div v-else class="px-5 py-12 text-center text-gray-500 border rounded-md">
          We weren't able to calculate an age of credit for accounts on your credit report.
        </div>
        <SideNav />
      </div>
    </div>
  </div>
</template>

<script>
import { store } from "../store"
import moment from "moment";
import SideNav from "./side_nav.vue";
import BureauTabs from "../bureau_tabs.vue";

export default {
  components: {
    SideNav,
    BureauTabs,
  },
  data() {
    return {
      bureau: "tuc",
      store
    };
  },
  methods: {
    distanceOfTime(isoDateString) {
      if (!isoDateString || !isoDateString.match(/^\d{4}-\d{2}-\d{2}$/)) {
        return null;
      }

      const d = moment(isoDateString);
      return moment().diff(d, "years", true).toFixed(1) + " years";
    },
    formattedDate(dateString = null) {
      if (!dateString) {
        return moment().format("MMM D, YYYY");
      }

      return moment(dateString).format("MMM D, YYYY");
    },
  },
  computed: {
    canShowAgeOfCredit() {
      return store.creditReport && this.oldestOpenAccountAge;
    },
    openTradelines() {
      if (!store.creditReport) {
        return [];
      }

      return store.creditReport.tradelines.filter((x) => x[this.bureau].accountOpenClosed == "Open");
    },
    oldestOpenAccountAge() {
      // Since the dateOpened string is always in YYYY-MM-DD format, we can just
      // sort the array and return the first element.
      return this.openTradelines.map((x) => x[this.bureau].dateOpened).sort()[0];
    },
    ageOfCredit() {
      return this.distanceOfTime(this.oldestOpenAccountAge);
    },
  },
};
</script>
