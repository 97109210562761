<template>
  <div class="container mx-auto">
    <div class="flex gap-3xl">
      <div class="flex-grow">
        <div>
          <div class="w-full">
            <div class="grid grid-cols-3 tab-group">
              <div class="tab" :class="isActiveTab('profileShow') || isActiveTab('profileEdit') ? 'active' : ''"
                @click="selectTab('profileShow')">
                <h5 class="text-center">Profile</h5>
              </div>
              <div class="tab" :class="isActiveTab('password') ? 'active' : ''" @click="selectTab('password')">
                <h5 class="text-center">Password</h5>
              </div>
              <div class="tab" :class="isActiveTab('subscription') ? 'active' : ''" @click="selectTab('subscription')">
                <h5 class="text-center">Subscription</h5>
              </div>
            </div>
          </div>

          <div class="bg-white rounded-b-lg p-xl">
            <div>
              <div v-if="selectedTab == 'profileShow' || selectedTab == 'profileEdit'">
                <ProfileShow v-if="selectedTab == 'profileShow'" @edit-profile="selectedTab = 'profileEdit'" />
                <ProfileEdit v-if="selectedTab == 'profileEdit'" @show-profile="selectedTab = 'profileShow'" />
              </div>
              <div v-if="selectedTab == 'password'">
                <PasswordEdit />
              </div>
              <div v-if="selectedTab == 'subscription'">
                <SubscriptionDetails />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="width: 265px">
        <SideNav />
      </div>
    </div>
  </div>
</template>

<script>
import { store } from "./store";
import ProfileShow from "./settings/profile_show.vue";
import ProfileEdit from "./settings/profile_edit.vue";
import PasswordEdit from "./settings/password_edit.vue";
import SubscriptionDetails from "./settings/subscription_details.vue"
import SideNav from "./settings/side_nav.vue";

export default {
  components: {
    ProfileShow,
    ProfileEdit,
    PasswordEdit,
    SubscriptionDetails,
    SideNav
  },
  props: [],
  data: function () {
    return {
      selectedTab: "profileShow",
      store,
    };
  },
  methods: {
    isActiveTab(tab) {
      return this.selectedTab === tab;
    },
    selectTab(tab) {
      this.selectedTab = tab;
    },
  },
};
</script>
