<template>
  <div v-bind:data-bureau="bureau">
    <span data-field="address-street">{{ address.street }}</span>
    <span v-if="address.street">, </span>
    <span data-field="address-city">{{ address.city }}</span>
    <span>, </span>
    <span data-field="address-state">{{ address.state }}</span>
    <span>, </span>
    <span data-field="address-zip">{{ address.zip }}</span>
  </div>
</template>

<script>
export default {
  props: {
    address: {
      type: Object,
      required: true
    },
    bureau: {
      type: String,
      required: true
    }
  },
  computed: {

  }
}
</script>
