<template>
  <div class="flex gap-sm md:flex-row flex-col" v-if="store.creditReport">
    <div class="flex-grow-0">
      <DashboardSummary :bureau="bureau" />
    </div>
    <div class="flex-grow">
      <div>
        <div>
          <div class="comment-s text-muted">Full Name</div>
          <div class="comment text-pure-black">{{ store.creditReport.borrower.name[bureau] }}
          </div>
        </div>
        <div class="my-sm">
          <div class="comment-s text-muted">Current Address</div>
          <div class="comment text-pure-black w-32">
            <StreetAddress class="mb-3" :bureau="bureau" :address="store.creditReport.borrower.currentAddress[bureau]"
              data-field="borrower-current-address" />
          </div>
        </div>
      </div>
    </div>
    <div class="flex-grow">
      <div>
        <div>
          <div class="comment-s text-muted">Date of Birth</div>
          <div class="comment text-pure-black">
            {{ formatDate(store.creditReport.borrower.dateOfBirth[bureau], "MM/DD/YYYY") }}
          </div>
        </div>
        <div class="my-sm">
          <div class="comment-s text-muted">Employers</div>
          <div class="comment text-pure-black w-32">
            <div class="mb-3" v-bind:data-bureau="bureau"
              v-for="(employer, j) in store.creditReport.borrower.employers[bureau]" :key="j">
              {{ employer.name }}
            </div>
            <div v-if="store.creditReport.borrower.employers[bureau].length == 0">-</div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex-shrink">
      <div class="my-1">
        <a id="btn-print-report" href="#/credit-report/print" class="inline-block btn btn-outline w-full text-left"
          @click="triggerPrint()">
          <PrinterIcon class="inline-block w-4 h-4 mr-2" />
          Print Report
        </a>
      </div>
      <div class="my-1">
        <a id="btn-download-report" href="#/credit-report/print" class="inline-block btn btn-outline"
          @click="triggerReportDownload()">
          <ArrowDownTrayIcon class="inline-block w-4 h-4 mr-2" />
          Download Report
        </a>
      </div>
    </div>
    <PersonalInfoModal v-if="showPersonalInfoModal" :bureau="bureau" @ss-close-modal="showPersonalInfoModal = false">
    </PersonalInfoModal>
  </div>
</template>

<script>
import { store } from "../store";
import DashboardSummary from "../dashboard/summary.vue";
import PersonalInfoModal from "./personal_info_modal.vue";
import StreetAddress from "./street_address.vue";
import DateFormatter from "../../../lib/date_formatter";
import { PrinterIcon, ArrowDownTrayIcon } from "@heroicons/vue/24/solid";

export default {
  components: {
    PersonalInfoModal,
    DashboardSummary,
    PrinterIcon,
    ArrowDownTrayIcon,
    StreetAddress,
  },
  props: ["bureau"],
  data() {
    return {
      showPersonalInfoModal: false,
      store
    };
  },
  computed: {
    creditScoreStatus() {
      if (store.creditReport.creditScore[this.bureau] > 719) {
        return "good";
      } else if (store.creditReport.creditScore[this.bureau] > 629) {
        return "fair";
      } else {
        return "poor";
      }
    },
  },
  methods: {
    formatDate(s, f = null) {
      if (f) {
        return DateFormatter.format(s, f);
      } else {
        return DateFormatter.format(s);
      }
    },
    triggerPrint() {
      window.setTimeout(function () {
        window.print();
      }, 700);
    },
    downloadReport() {
      // Create a new HTML document
      var newHTML = document.implementation.createHTMLDocument("Credit Report | Standard Scores");

      // Add a link for a stylesheet with the url of the current host + "/portal/stylesheet"
      var link = newHTML.createElement("link");
      link.href = window.location.origin + "/portal/stylesheet";
      link.rel = "stylesheet";
      link.type = "text/css";
      newHTML.head.appendChild(link);

      newHTML.body.classList.add("p-3")

      // Add the contents of #credit-report-print-view to the body of the new document
      newHTML.body.innerHTML = document.getElementById("credit-report-print-view").innerHTML;

      // Create a new blob containing the contents of the new document
      var blob = new Blob([newHTML.documentElement.outerHTML], { type: "text/html" });
      var url = URL.createObjectURL(blob);

      var a = document.createElement("a");
      a.href = url;
      a.download = "credit-report-" + new Date().toISOString().slice(0, 10) + ".html";
      a.click();

      // Clean up
      URL.revokeObjectURL(url);
      a.remove();
    },
    triggerReportDownload() {
      window.setTimeout(() => {
        this.downloadReport();
        window.location.hash = "#/credit-report";
      }, 700);
    }
  },
};
</script>
