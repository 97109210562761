<template>
  <div
    class="rounded-md h-40 border border-zinc-500 p-sm bg-cover bg-no-repeat bg-center select-none hover:border-zinc-800"
    :style="`background-image: url(/images/card_bgs/${imageKey}.png)`">
    <div class="text-center my-lg font-semibold text-lg">**** **** **** {{ last4 }}</div>

    <div class="flex">
      <div v-if="cardType == 'visa'">
        <img src="/images/card_logos/visa.svg" width="50" />
      </div>
      <div v-if="cardType == 'master'">
        <img src="/images/card_logos/mastercard.svg" width="50" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    cardType: {
      type: String,
      required: true,
    },
    last4: {
      type: String,
      required: true,
    },
    imageKey: {
      type: String,
      required: true,
    },
  }
}
</script>
