<template>
  <div class="mt-24 text-center text-gray-700">
    <div v-if="store.creditReportError">
      Your credit report is being loaded & processed by our systems.
      <br />
      Please check back here later for your credit report analysis.
    </div>
    <div v-else>
      <div class="mb-8 text-center">
        <Spinner />
      </div>

      Your credit report analysis will display here once we finish fetching &
      processing your report.
    </div>
  </div>
</template>
<script>
import { store } from "./store.ts"
import Spinner from "../shared/spinner_svg.vue";
export default {
  data() {
    return {
      store
    }
  },
  components: {
    Spinner,
  },
}
</script>
