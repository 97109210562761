<template>
  <div>
    <div v-for="factor in store.scoreFactors" :key="factor.label" class="flex my-1 text-sm cursor-pointer" :class="cssForStatus(factor.status)" @click="window.location.hash = factor.path">
      <div class="p-2">{{factor.label}}</div>
      <div class="p-2 ml-auto">⬤</div>
    </div>
  </div>
</template>

<script>
import { store } from "../store"

export default {
  data() {
    return {
      store
    }
  },
  methods: {
    cssForStatus(status){
      if(status == "good"){
        return "text-lime-600 bg-lime-100 hover:bg-lime-200"
      }else if(status == "fair"){
        return "text-yellow-600 bg-yellow-100 hover:bg-yellow-200"
      }else if(status == "bad"){
        return "text-red-600 bg-red-100 hover:bg-red-200"
      }else{
        return "text-gray-600 bg-gray-100 hover:bg-gray-200"
      }
    }
  }
}
