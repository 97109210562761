<template>
  <div class="container mx-auto">
    <div class="flex gap-3xl">
      <div class="flex-grow">
        <div class="flex md:flex-row flex-col gap-xl">
          <div class="flex-shrink">
            <h3>Available Credit</h3>
            <p class="my-sm">
              Your available credit limit is the total limit subtracting your current total balance. Your available
              credit limit helps lenders understand why you are seeking new credit. Maxing out every line of credit
              sends a signal that you are borrowing money faster than you are repaying it. Which doesn't mean you can't
              apply or qualify for new lines of credit. Read below to see the calculation of your available credit
              limit.
            </p>
            <div v-if="store.creditReport">
              <div class="text-xl font-semibold">Your credit status</div>
              <p v-if="store.scoreFactors.availableCredit.status == 'good'">
                You have an excellent available credit limit.
              </p>
              <p v-if="store.scoreFactors.availableCredit.status == 'fair'">
                Your available credit is good, but it could be better. Paying down balances or increasing your credit
                limit will improve your Available Credit, and your overall Credit Score.
              </p>
              <p v-if="store.scoreFactors.availableCredit.status == 'bad'">
                Your available credit is low. Paying down balances or increasing your credit limit will improve your
                Available Credit, and your overall Credit Score.
              </p>
              <p v-if="!canShowAvailableCredit">
                We weren't able to assess the impact of your available credit because we didn't find any open lines of
                revolving credit.
              </p>
            </div>
          </div>
          <div style="min-width: 200px">
            <div v-if="canShowAvailableCredit">
              <FactorSummary
                :displayValue="usd(totalAvailableCredit)"
                :statusText="store.scoreFactors.availableCredit.status"
                :lastUpdated="store.creditReport.reportDate[bureau]"
              />
            </div>
          </div>
        </div>

        <div class="my-sm">
          <BureauTabs :bureau="bureau" @select-bureau="(newBureau) => (bureau = newBureau)" />

          <div v-if="store.creditReport" class="bg-white p-xl">
            <table class="table table-noborder w-full table-fixed">
              <thead>
                <tr>
                  <th class="text-left">Account</th>
                  <th class="text-right">Balance</th>
                  <th class="text-right">Limit</th>
                  <th class="text-right">Available</th>
                </tr>
              </thead>
              <tbody class="text-pure-black">
                <tr v-for="(creditCard, i) in openRevolvingAccounts" :key="i">
                  <td class="font-bold">{{ creditCard.creditorName }}</td>
                  <td class="text-right">
                    {{ usd(creditCard.balanceAmount) }}
                  </td>
                  <td class="text-right">
                    {{ usd(creditCard.creditLimitAmount) }}
                  </td>
                  <td class="text-right">{{ usd(creditCard.creditLimitAmount - creditCard.balanceAmount) }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div style="min-width: 265px">
        <SideNav />

        <div class="border-l-4 border-gray-800 p-sm my-sm">
          <p class="comment-s font-bold mb-sm">
            First, we add up the total limit on your credit cards to calculate your total credit limit. Next, we add up
            the balances on those same accounts. When we subtract the balances from the limits we end up with the total
            available credit limit.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { store } from "../store";
import SideNav from "./side_nav.vue";
import BureauTabs from "../bureau_tabs.vue";
import FactorSummary from "./factor_summary.vue";

export default {
  components: {
    SideNav,
    BureauTabs,
    FactorSummary,
  },
  data() {
    return {
      bureau: "tuc",
      store,
    };
  },
  computed: {
    openRevolvingAccounts() {
      if (!store.creditReport) {
        return [];
      }
      // A credit card account is considered closed if the status is not "Open" or if the limit is 0
      return store.creditReport.tradelines
        .filter(
          (tradeline) =>
            tradeline[this.bureau].creditType === "Revolving Account" &&
            tradeline[this.bureau].accountOpenClosed === "Open" &&
            tradeline[this.bureau].creditLimitAmount > 0
        )
        .map((tradeline) => tradeline[this.bureau]);
    },
    canShowAvailableCredit() {
      return store.creditReport && this.openRevolvingAccounts.length > 0;
    },
    totalCreditLimit() {
      return this.openRevolvingAccounts.reduce((total, creditCard) => {
        return total + creditCard.creditLimitAmount;
      }, 0);
    },
    totalUsedCredit() {
      return this.openRevolvingAccounts.reduce((total, creditCard) => {
        return total + creditCard.balanceAmount;
      }, 0);
    },
    totalAvailableCredit() {
      return this.totalCreditLimit - this.totalUsedCredit;
    },
  },
};
</script>
