<template>
  <div class="grid grid-cols-3 tab-group">
    <div class="tab" :class="bureauClasses('tuc')" @click="selectBureau('tuc')">
      <div
        style="background-image: url(/images/bureau_logos/transunion_bw.svg)"
        class="h-6 bg-center bg-no-repeat bg-contain"
      ></div>
    </div>
    <div v-if="!onlyTuc" class="tab" :class="bureauClasses('eqf')" @click="selectBureau('eqf')">
      <div
        style="background-image: url(/images/bureau_logos/equifax_bw.svg)"
        class="h-4 mt-2 bg-center bg-no-repeat bg-contain"
      ></div>
    </div>
    <div v-if="!onlyTuc" class="tab" :class="bureauClasses('exp')" @click="selectBureau('exp')">
      <div
        style="background-image: url(/images/bureau_logos/experian_bw.svg)"
        class="h-6 bg-center bg-no-repeat bg-contain"
      ></div>
    </div>
  </div>
</template>
<script>
import { store } from "./store";
export default {
  props: {
    bureau: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      selectedBureau: this.bureau,
      store,
    };
  },
  computed: {
    onlyTuc() {
      return this.store.creditReport?.singleBureauReport;
    },
  },
  methods: {
    selectBureau(bureau) {
      this.selectedBureau = bureau;
      this.$emit("select-bureau", bureau);
    },
    bureauClasses(bureau) {
      return this.selectedBureau == bureau ? "active" : "";
    },
  },
};
</script>
