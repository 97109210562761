import * as Sentry from "@sentry/browser";
import ensureArray from "./ensure_array";

export default {
  // Returns an array of hashes e.g [{date: "", value: ""}, {date: "", value: ""}]
  parse(rawReportData: Array<object>){
    console.log("trendingData", rawReportData); // TODO: Remove this

    // I apologize for these next 2 lines of code, but in my defense, the JSON
    // structure is a mess and this is the best way of parsing it
    const baseArray = ensureArray(rawReportData?.["ArrayOfProductTrendingAttribute"])

    // attributeArray will either contain a single object that has the data we
    // need, or multiple objects of which only one has the data we need.
    const attributeArray = ensureArray(baseArray[0]?.["ProductTrendingAttribute"])

    let dataArray: object[] = []
    if(attributeArray.length == 0){
      return []
    }else if(attributeArray.length == 1){
      dataArray = ensureArray(attributeArray[0]?.["ProductAttributeData"]?.["ProductTrendingData"])
    }else{
      // If there are multiple objects, we need to ignore ones that have null values for the AttributeValue
      dataArray = attributeArray.find((entry) => {
        return entry?.["ProductAttributeData"]?.["ProductTrendingData"]?.[0]?.["AttributeValue"] != null
      })
    }

    try{
      const result: object[] = []

      dataArray.forEach((productTrendingDataEntry) => {
        const dateString = productTrendingDataEntry?.["AttributeDate"]?.["$"]
        const status = productTrendingDataEntry?.["AttributeStatus"]?.["$"]
        const value = productTrendingDataEntry?.["AttributeValue"]?.["$"]

        if(status == "Success"){
          result.push({
            date: dateString.slice(0,10),
            value: parseInt(value)
          })
        }
      })
      return result
    }catch(e){
      Sentry.captureException(e)
      return []
    }
  }
}
