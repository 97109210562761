<script setup></script>
<script>
export default {
  components: {},
  data: function () {
    return {};
  },
  methods: {},
  mounted() {
    // stuff
  },
};
</script>
<template>
  <div class="fixed bottom-0 w-full bg-white footer-nav print:hidden">
    <div
      class="flex flex-wrap items-center justify-end py-1 -mx-0 border-t border-solid border-grey-400-opacity-60"
    >
      <a
        href="#"
        onclick="Clym.showWidget('', '', event);"
        class="block py-0 mx-2 text-sm text-gray-700 hover:text-black transition-fast-out"
        >Privacy Center</a
      >
      <a
        href="#"
        onclick="Clym.showWidget('/requests/new/do_not_sell_my_information', '', event);"
        class="block py-0 mx-2 text-sm text-gray-700 hover:text-black transition-fast-out"
        >Do not sell or share my personal information</a
      >
    </div>
  </div>
</template>

<style scoped></style>
