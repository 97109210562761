<template>
  <div class="w-full h-full p-2">
    <div class="w-full mx-auto mt-12 registration-step md:max-w-xl bg-white rounded-lg shadow-md p-md">
      <h4 class="text-3xl font-bold text-center">Purchase Credit Report</h4>

      <p v-if="creditReportPrice" class="my-md">
        If you wish to get an update on your credit without waiting for the
        monthly report, you can purchase a one-off credit report refresh.
        This will give you a snapshot of your credit at the time of purchase.
      </p>

      <div class="my-md">
        <div class="comment-s">
          Price
        </div>
        <h5>{{ usd(creditReportPrice) }}</h5>
      </div>

      <div class="my-md flex gap-sm">
        <input type="checkbox" class="m-1" v-model="termsAndConditionsAccepted" />

        <span class="text-xs text-gray-700">
          I understand that this is a one-off non-refundable payment and will be charged to my credit card on file.
        </span>
      </div>

      <div class="my-md" v-if="errors.length > 0">
        <div v-for="error in errors" :key="error" class="text-red-500">{{ error }}</div>
      </div>

      <div class="my-md" v-if="fetching">
        <Spinner />
      </div>
      <div class="my-md" v-else>
        <button class="btn btn-primary" @click="purchaseCreditReport()">
          Complete Purchase
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { store } from "../store";
import Spinner from "../../shared/spinner_svg.vue";

export default {
  name: "PurchaseCreditReport",
  components: {
    Spinner,
  },
  data() {
    return {
      store,
      creditReportPrice: null,
      termsAndConditionsAccepted: false,
      errors: [],
      fetching: false,
    };
  },
  computed: {
  },
  methods: {
    fetchPurchaseDetails() {
      fetch("/portal/credit-report-purchase-details")
        .then((response) => response.json())
        .then(data => {
          this.creditReportPrice = data.price;
        })
    },
    purchaseCreditReport() {
      if (!this.termsAndConditionsAccepted) {
        this.errors = ["Please accept the terms & conditions to proceed."]
        return
      }

      this.fetching = true

      fetch("/portal/purchase-credit-report", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": document.querySelector("meta[name=csrf-token]").content,
        }
      })
        .then((response) => response.json())
        .then(data => {
          this.fetching = false
          if (data.result == "error") {
            this.errors = data.errors
          } else {
            window.dispatchEvent(
              new CustomEvent("reload-credit-report")
            );
            window.location.hash = "#/credit-report"
          }
        })
    }
  },
  mounted() {
    this.fetchPurchaseDetails();
  },
}
</script>
