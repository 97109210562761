import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "portal" }
const _hoisted_2 = {
  key: 0,
  class: "container mx-auto mt-3"
}
const _hoisted_3 = { class: "alert alert-danger" }
const _hoisted_4 = {
  key: 0,
  class: "container mx-auto mt-3"
}
const _hoisted_5 = { class: "alert alert-info" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_TopNav = _resolveComponent("TopNav")
  const _component_FooterNav = _resolveComponent("FooterNav")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_TopNav),
    _createVNode(_Transition, {
      "enter-active-class": "duration-300 ease-out",
      "enter-from-class": "transform opacity-0",
      "enter-to-class": "opacity-100",
      "leave-active-class": "duration-500 ease-in",
      "leave-from-class": "opacity-100",
      "leave-to-class": "transform opacity-0"
    }, {
      default: _withCtx(() => [
        ($data.alertText.length > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, _toDisplayString($data.alertText), 1 /* TEXT */)
            ]))
          : _createCommentVNode("v-if", true)
      ]),
      _: 1 /* STABLE */
    }),
    _createVNode(_Transition, {
      "enter-active-class": "duration-300 ease-out",
      "enter-from-class": "transform opacity-0",
      "enter-to-class": "opacity-100",
      "leave-active-class": "duration-500 ease-in",
      "leave-from-class": "opacity-100",
      "leave-to-class": "transform opacity-0"
    }, {
      default: _withCtx(() => [
        ($data.noticeText.length > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, _toDisplayString($data.noticeText), 1 /* TEXT */)
            ]))
          : _createCommentVNode("v-if", true)
      ]),
      _: 1 /* STABLE */
    }),
    (_openBlock(), _createBlock(_resolveDynamicComponent($options.currentView))),
    _createVNode(_component_FooterNav)
  ]))
}