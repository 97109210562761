<template>
  <div id="personal-info-modal">
    <div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div class="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75"></div>

      <div class="fixed inset-0 z-10 w-screen overflow-y-auto" @click="$emit('ss-close-modal')">
        <div class="flex items-start justify-center min-h-full p-4 text-center md:items-center sm:p-0">
          <div
            @click.stop
            class="relative overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl md:my-8 sm:w-full sm:max-w-xl">
            <div class="p-4 text-white bg-sky-600">Personal Information</div>
            <div class="px-4 pt-5 pb-4 sm:p-6">
              <h3 class="mb-5 text-lg font-semibold">{{ store.creditReport.borrower.name[bureau] }}</h3>
              <div class="grid grid-cols-1 gap-3 md:grid-cols-3">
                <div>
                  <div class="font-semibold">Also Known As</div>

                  <div
                    v-for="(alias, j) in store.creditReport.borrower.aliases[bureau]"
                    :key="j"
                    class="mb-3"
                    v-bind:data-bureau="bureau">
                    {{ alias }}
                  </div>
                  <div v-if="store.creditReport.borrower.aliases[bureau].length == 0">-</div>
                </div>
                <div>
                  <div class="font-semibold">Current Address</div>

                  <StreetAddress class="mb-3" :bureau="bureau" :address="store.creditReport.borrower.currentAddress[bureau]" data-field="borrower-current-address" />
                </div>
                <div>
                  <div class="font-semibold">Previous Addresses</div>

                  <template v-for="(address, j) in store.creditReport.borrower.previousAddresses[bureau]" :key="j">
                    <StreetAddress class="mb-3" :bureau="bureau" :address="address" data-field="borrower-previous-address" />
                  </template>
                  <div v-if="store.creditReport.borrower.previousAddresses[bureau].length == 0">-</div>
                </div>
                <div>
                  <div class="font-semibold">Employers</div>

                  <div
                    class="mb-3"
                    v-bind:data-bureau="bureau"
                    v-for="(employer, j) in store.creditReport.borrower.employers[bureau]"
                    :key="j">
                    {{ employer.name }}
                  </div>
                  <div v-if="store.creditReport.borrower.employers[bureau].length == 0">-</div>
                </div>
                <div>
                  <div class="font-semibold">Date of Birth</div>

                  <div>{{ formatDate(store.creditReport.borrower.dateOfBirth[bureau], "MM/DD/YYYY") }}</div>
                </div>
              </div>

              <div class="p-3 my-3 text-sm bg-gray-100 border">
                Missing or incorrect personal information is not always a cause
                for concern, as your address and employment history may not
                always be up-to-date on your credit report. However, if you
                notice an unfamiliar address or employer, it could be a sign of
                fraud. Contact us for guidance if you suspect identity theft.
              </div>

              <div class="text-center">
                <button type="button" @click="$emit('ss-close-modal')" class="btn btn-sm btn-primary">Close</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { store } from "../store.ts";
import StreetAddress from "./street_address.vue";

export default {
  components: {
    StreetAddress,
  },
  data() {
    return { store };
  },
  props: ["bureau"],
};
</script>
