import { Application } from "@hotwired/stimulus"
import * as Turbo from "@hotwired/turbo"

const application = Application.start()

// Configure Stimulus development experience
application.debug = window.ssDebugEnabled;
window.Stimulus   = application

export { application }
