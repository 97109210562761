import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "comment-xs !font-bold" }
const _hoisted_2 = { class: "comment-xs" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_Transition, {
      name: "fade",
      mode: "out-in"
    }, {
      default: _withCtx(() => [
        (_openBlock(), _createElementBlock("div", {
          class: "py-sm",
          key: _ctx.bureau
        }, [
          _createElementVNode("h3", {
            class: _normalizeClass($options.h3Class)
          }, _toDisplayString($props.displayValue), 3 /* TEXT, CLASS */),
          _createElementVNode("div", _hoisted_1, _toDisplayString($options.prettyStatusText($props.statusText)), 1 /* TEXT */),
          _createElementVNode("div", _hoisted_2, "Updated: " + _toDisplayString(_ctx.formatDate($props.lastUpdated, "MMM D, YYYY")), 1 /* TEXT */)
        ]))
      ]),
      _: 1 /* STABLE */
    })
  ]))
}