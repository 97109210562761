const ssBuildConfiguration = process.env.NODE_ENV;
window['ssDebugEnabled'] = (ssBuildConfiguration == "development");
window['ssBuildConfiguration'] = ssBuildConfiguration;

import * as Sentry from "@sentry/browser";
import northstar from "./tracking/north-star";

if(ssBuildConfiguration == "development") {
  console.log("Running in development mode");
  // Mock Sentry in development
  window["Sentry"] = {
    captureException: function(e){ console.log(e) },
    captureMessage: function(m){ console.log(m) }
  }
}else{
  window["Sentry"] = Sentry
  Sentry.init({
    dsn: "https://7acf0425a6f32314dec3ff4266d216ed@o4505745181704192.ingest.sentry.io/4506179553067008"
  })
}

northstar.configure({
  platform: "web-app",
  isDevOrLocal: (ssBuildConfiguration == "development"),
});

northstar.shine();

// Entry point for the build script in your package.json
import "./controllers/application.js"
import LaunchPortalComponent from "./portal/launch_component"
import LaunchPortalComponentv2 from "./portal_v2/launch_component"
import axios from "axios"

import * as Turbo from "@hotwired/turbo"
window["Turbo"] = Turbo

document.addEventListener("turbo:load", () => {
  axios.defaults.headers.common['X-CSRF-Token'] = document.querySelector('meta[name="csrf-token"]')?.getAttribute('content')
})

window['launchPortalComponent'] = LaunchPortalComponent
window['launchPortalComponentv2'] = LaunchPortalComponentv2

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare let Spreedly: any;

window['initializeSpreedly'] = function(){
  Spreedly.init(window['spreedlyEnvironmentID'], {
    numberEl: "spreedly-number",
    cvvEl: "spreedly-cvv",
  });

  Spreedly.on("ready", () => {
    Spreedly.setFieldType("number", "text");
    Spreedly.setNumberFormat("prettyFormat");
    Spreedly.setStyle("number", "font-size: 1.125rem; line-height: 1.75rem; width: 100%");
    Spreedly.setStyle("cvv", "font-size: 1.125rem; line-height: 1.75rem;");
  });

  Spreedly.on("errors", (errors) => {
    const errorEl = document.getElementById("cc-errors")
    if(errorEl){
      errorEl.innerText = errors.map((e) => e.message).join(", ");
    }
    document.getElementById("cc-spinner")?.classList.add("hidden");
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  Spreedly.on("paymentMethod", (token, pmData) => {
    const tokenEl = document.getElementById("payment_method_token") as HTMLInputElement;
    tokenEl.value = token;

    const formEl = document.getElementById("add-credit-card-form") as HTMLFormElement;
    formEl.submit()
  });
}

window['getPaymentToken'] = function(){
  document.getElementById("cc-spinner")?.classList.remove("hidden");

  const firstNameEl = document.getElementById("first_name") as HTMLInputElement;
  const lastNameEl = document.getElementById("last_name") as HTMLInputElement;
  const ccExpiryEl = document.getElementById("cc_expiry") as HTMLInputElement;

  // Ensure expiry is MM/YY
  if(!ccExpiryEl.value.match(/^\d{2}\/\d{2}$/)){
    alert("Invalid expiry date");
    document.getElementById("cc-spinner")?.classList.add("hidden");
    return;
  }

  const ccExpiryPieces = ccExpiryEl.value.split("/");

  const data = {
    full_name: `${firstNameEl.value} ${lastNameEl.value}`,
    month: ccExpiryPieces[0],
    year: `20${ccExpiryPieces[1]}`,
  };

  Spreedly.tokenizeCreditCard(data);
}
