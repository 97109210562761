<template>
  <div>
    <Transition name="fade" mode="out-in">
      <div class="py-sm" :key="bureau">
        <h3 :class="h3Class">{{ displayValue }}</h3>
        <div class="comment-xs !font-bold">
          {{ prettyStatusText(statusText) }}
        </div>
        <div class="comment-xs">Updated: {{ formatDate(lastUpdated, "MMM D, YYYY") }}</div>
      </div>
    </Transition>
  </div>
</template>
<script>
export default {
  props: {
    displayValue: {
      type: String,
      required: true,
    },
    statusText: {
      type: String,
      required: true,
    },
    lastUpdated: {
      type: String,
      required: true,
    },
  },
  computed: {
    h3Class() {
      if (this.statusText == "good") {
        return "text-green mb-sm";
      } else if (this.statusText == "fair") {
        return "text-amber-500 mb-sm";
      } else {
        return "text-danger mb-sm";
      }
    },
  },
  methods: {
    prettyStatusText(statusText) {
      // Capitalize first character
      return statusText.charAt(0).toUpperCase() + statusText.slice(1);
    },
  },
};
</script>
