<template>
  <div>
    <div class="p-2 font-semibold">Factors priority breakdown</div>

    <div v-for="factor in store.scoreFactors" v-bind:key="factor.path">
      <a v-bind:href="factor.path" class="block p-2 text-sm font-semibold hover:bg-gray-100">
        <div
          v-bind:class="{
            'text-slate-800': isCurrentHash(factor.path),
            'text-gray-400': !isCurrentHash(factor.path),
          }">
          {{ factor.label }}
        </div>
        <div class="w-full h-1 my-1 bg-gray-200">
          <div
            v-bind:class="{
              'h-1': true,
              'bg-slate-800': isCurrentHash(factor.path),
              'bg-gray-300': !isCurrentHash(factor.path),
            }"
            v-bind:style="{ width: factor.importance }"></div>
        </div>
      </a>
    </div>
  </div>
</template>

<script>
import { store } from "../store"
export default {
  data(){
    return {
      store
    }
  },
  methods: {
    isCurrentHash: function (s) {
      return window.location.hash == s;
    },
  },
};
</script>
