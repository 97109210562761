<template>
  <div class="container mx-auto">
    <div class="flex gap-3xl">
      <div class="flex-grow">
        <div class="flex md:flex-row flex-col gap-xl">
          <div class="flex-shrink">
            <h3>Credit Utilization</h3>
            <p class="my-sm">
              Credit utilization is a crucial factor in your credit score and is a measure of how much of your available
              credit you are currently using. It's typically expressed as a percentage and is calculated by dividing
              your credit card balances by your credit card limits. Lenders do indeed pay close attention to your credit
              utilization because it can provide insights into your financial responsibility and risk as a borrower.
            </p>
          </div>
          <div style="min-width: 200px">
            <div v-if="canCalculateUtilization">
              <FactorSummary
                :displayValue="totalUtilization"
                :statusText="store.scoreFactors.utilization.status"
                :lastUpdated="store.creditReport.reportDate[bureau]"
              />
            </div>
          </div>
        </div>

        <div class="my-sm">
          <BureauTabs :bureau="bureau" @select-bureau="(newBureau) => (bureau = newBureau)" />

          <div v-if="store.creditReport" class="bg-white p-xl">
            <table class="table table-noborder w-full table-fixed">
              <thead>
                <tr>
                  <th class="text-left">Account</th>
                  <th class="text-right">Balance</th>
                  <th class="text-right">Limit</th>
                  <th class="text-right">Utilization</th>
                </tr>
              </thead>
              <tbody class="text-pure-black">
                <tr v-for="(creditCard, i) in openRevolvingAccounts" :key="i">
                  <td class="font-bold">{{ creditCard.creditorName }}</td>
                  <td class="text-right">
                    {{ usd(creditCard.balanceAmount) }}
                  </td>
                  <td class="text-right">
                    {{ usd(creditCard.creditLimitAmount) }}
                  </td>
                  <td class="text-right">{{ utilization(creditCard) }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <h3 class="my-md text-black">How to maintain a healthy<br />credit utilization rate:</h3>
        <div class="grid md:grid-cols-4 grid-cols-1 gap-sm">
          <div>
            <strong>Pay Credit Card Balances</strong>

            <p>
              Pay your credit card balances in full or keep them as low as possible. A utilization rate below 30% is
              generally recommended, but lower is better.
            </p>
          </div>
          <div>
            <strong>Monitor Credit Limits</strong>

            <p>
              Regularly review your credit limits to ensure they accurately reflect your credit needs. You can request
              credit limit increases if necessary, which can help lower your utilization rate.
            </p>
          </div>
          <div>
            <strong>Spread Out Expenses</strong>

            <p>
              Try to spread out your expenses across multiple credit cards rather than maxing out a single card. This
              can help keep individual card utilization low.
            </p>
          </div>
          <div>
            <strong>Regularly Check Your Credit</strong>

            <p>
              Monitor your credit reports to ensure that your utilization rate is accurately reported. Correct any
              errors if you find discrepancies.
            </p>
          </div>
        </div>
      </div>
      <div style="min-width: 265px">
        <SideNav />

        <div class="border-l-4 border-gray-800 p-sm my-sm">
          <p class="comment-s font-bold mb-sm">
            All of the credit card balances are added up, and divided by the sum of credit card limits, to yield a
            percentage of usage.
          </p>

          <div class="flex gap-sm">
            <div class="text-center flex-grow">
              <div>Card Balances</div>
              <hr style="border-color: black; margin: 2px 0" />
              <div>Card Limits</div>
            </div>
            <div class="py-sm">=</div>
            <div class="text-center flex-grow">
              <div>Credit</div>
              <div>Utilization</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { store } from "../store";
import SideNav from "./side_nav.vue";
import BureauTabs from "../bureau_tabs.vue";
import FactorSummary from "./factor_summary.vue";

export default {
  components: {
    SideNav,
    BureauTabs,
    FactorSummary,
  },
  data() {
    return {
      bureau: "tuc",
      store,
    };
  },
  methods: {
    utilization(creditCard) {
      if (creditCard.creditLimitAmount > 0) {
        return `${Math.round((creditCard.balanceAmount * 100) / creditCard.creditLimitAmount)}%`;
      } else {
        return "-";
      }
    },
  },
  computed: {
    canCalculateUtilization() {
      return this.totalLimit > 0;
    },
    totalBalance() {
      return this.openRevolvingAccounts
        .map((c) => c.balanceAmount)
        .filter(Number)
        .reduce((a, b) => a + b, 0);
    },
    totalLimit() {
      return this.openRevolvingAccounts
        .map((c) => c.creditLimitAmount)
        .filter(Number)
        .reduce((a, b) => a + b, 0);
    },
    totalUtilization() {
      if (this.totalLimit > 0) {
        return `${Math.round((this.totalBalance * 100) / this.totalLimit)}%`;
      } else {
        return "-";
      }
    },
    openRevolvingAccounts() {
      if (!store.creditReport) {
        return [];
      }

      return store.creditReport.tradelines
        .filter(
          (tradeline) =>
            tradeline[this.bureau].creditType === "Revolving Account" &&
            tradeline[this.bureau].accountOpenClosed === "Open" &&
            tradeline[this.bureau].creditLimitAmount > 0
        )
        .map((tradeline) => tradeline[this.bureau]);
    },
  },
};
</script>
