import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  id: "credit-report-display",
  class: "md:p-3"
}
const _hoisted_2 = {
  key: 0,
  class: "container mx-auto max-w-7xl"
}
const _hoisted_3 = { class: "p-3 mb-10 border border-t-0 rounded-b-md" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_BureauTabs = _resolveComponent("BureauTabs")
  const _component_ReportSummary = _resolveComponent("ReportSummary")
  const _component_Tradelines = _resolveComponent("Tradelines")
  const _component_Inquiries = _resolveComponent("Inquiries")
  const _component_MissingCreditReport = _resolveComponent("MissingCreditReport")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($options.reportLoaded)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_BureauTabs, {
            bureau: _ctx.bureau,
            onSelectBureau: _cache[0] || (_cache[0] = (newBureau) => (_ctx.bureau = newBureau))
          }, null, 8 /* PROPS */, ["bureau"]),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_ReportSummary, { bureau: _ctx.bureau }, null, 8 /* PROPS */, ["bureau"]),
            _createVNode(_component_Tradelines, { bureau: _ctx.bureau }, null, 8 /* PROPS */, ["bureau"]),
            _createVNode(_component_Inquiries, { bureau: _ctx.bureau }, null, 8 /* PROPS */, ["bureau"])
          ])
        ]))
      : (_openBlock(), _createBlock(_component_MissingCreditReport, {
          key: 1,
          creditReportError: _ctx.store.creditReportError
        }, null, 8 /* PROPS */, ["creditReportError"]))
  ]))
}