<template>
  <div class="grid grid-cols-3 bg-gray-50 rounded-t-md">
    <div
      class="p-3 cursor-pointer hover:grayscale-0 hover:opacity-100"
      :class="
        selectedBureau == 'tuc'
          ? 'grayscale-0 opacity-100 bg-white border border-b-0 rounded-t-md'
          : 'opacity-50 border-b bg-gray-100 border-gray-300 grayscale'
      "
      @click="selectBureau('tuc')">
      <div
        style="background-image: url(/images/bureau_logos/transunion.svg)"
        class="h-6 bg-center bg-no-repeat bg-contain"></div>
    </div>
    <div
      v-if="!onlyTuc"
      class="p-3 cursor-pointer hover:grayscale-0 hover:opacity-100"
      :class="
        selectedBureau == 'eqf'
          ? 'grayscale-0 opacity-100 bg-white border border-b-0 rounded-t-md'
          : 'opacity-50 border-b bg-gray-100 border-gray-300 grayscale'
      "
      @click="selectBureau('eqf')">
      <div
        style="background-image: url(/images/bureau_logos/equifax.svg)"
        class="h-4 mt-2 bg-center bg-no-repeat bg-contain"></div>
    </div>
    <div
      v-if="!onlyTuc"
      class="p-3 cursor-pointer hover:grayscale-0 hover:opacity-100"
      :class="
        selectedBureau == 'exp'
          ? 'grayscale-0 opacity-100 bg-white border border-b-0 rounded-t-md'
          : 'opacity-50 border-b bg-gray-100 border-gray-300 grayscale'
      "
      @click="selectBureau('exp')">
      <div
        style="background-image: url(/images/bureau_logos/experian.svg)"
        class="h-6 bg-center bg-no-repeat bg-contain"></div>
    </div>
  </div>
</template>
<script>
import {store} from "./store";
export default {
  props: {
    bureau: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      selectedBureau: this.bureau,
      store
    };
  },
  computed: {
    onlyTuc() {
      return this.store.creditReport.singleBureauReport
    }
  },
  methods: {
    selectBureau(bureau) {
      this.selectedBureau = bureau;
      this.$emit("select-bureau", bureau);
    },
  },
};
</script>
