<template>
  <div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true" @click="$emit('close-modal')" v-if="showModal">
    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

    <div class="fixed inset-0 z-10 w-screen overflow-y-auto" @click.stop="$emit('close-modal')">
      <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
        <div class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6" @click.stop="">
          <div class="flex gap-sm">
            <h6 class="comment">{{ cardTitle }}</h6>
            <div class="ml-auto"><XCircleIcon class="h-6 w-6 cursor-pointer" @click="$emit('close-modal')" /></div>
          </div>
          <div class="comment-s text-muted">{{ statusText }}</div>
          <div class="mt-4">
            <div v-if="positiveFactorCount + negativeFactorCount == 0" class="text-muted">
              There are no factors affecting your credit score in this category.
            </div>
            <div v-if="positiveFactors.length > 0">
              <div v-if="positiveFactorCount > 0" class="text-lg font-medium text-green">
                <span>{{ positiveFactorCount }}</span>
                <ArrowUpIcon class="inline-block h-4 w-4" />
              </div>
              <div class="space-y-3">
                <div v-for="factor in positiveFactors" :key="factor.id" class="comment">{{ factor.factor }}</div>
              </div>
            </div>
            <div v-if="negativeFactors.length > 0">
              <div v-if="negativeFactorCount > 0" class="text-lg font-medium text-primary">
                <span>{{ negativeFactorCount }}</span>
                <ArrowDownIcon class="inline-block h-4 w-4" />
              </div>
              <div class="space-y-3">
                <div v-for="factor in negativeFactors" :key="factor.id" class="comment">{{ factor.factor }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>


<script>
import { store } from "../store.ts";

import {
  ArrowDownIcon,
  ArrowUpIcon,
  XCircleIcon
} from "@heroicons/vue/24/outline";

export default {
  props: ["category", "bureau"],
  components: {
    ArrowDownIcon,
    ArrowUpIcon,
    XCircleIcon
  },
  computed: {
    showModal(){
      return store.creditReport && this.category
    },
    statusText(){
      const status = store.scoreFactors[this.category].status

      if (status === "good") {
        return "Good";
      } else if (status === "fair") {
        return "Fair";
      } else if (status === "bad") {
        return "Poor";
      } else {
        return "";
      }
    },
    cardTitle(){
      if(this.category == "paymentHistory"){
        return "Payment History"
      }
      else if(this.category == "availableCredit"){
        return "Available Credit"
      }
      else if(this.category == "ageOfCredit"){
        return "Age of Credit"
      }
      else if(this.category == "utilization"){
        return "Credit Utilization"
      }
      else if(this.category == "creditInquiries"){
        return "Credit Inquiries"
      }
      else if(this.category == "negativeMarks"){
        return "Negative Marks"
      }else{
        return ""
      }
    },
    positiveFactors(){
      if(!store.creditReport){
        return []
      }

      return store.creditReport.creditScoreFactors[this.bureau].filter(factor => factor.category == this.category && factor.scoreSentiment === "Positive")
    },
    negativeFactors(){
      if(!store.creditReport){
        return []
      }

      return store.creditReport.creditScoreFactors[this.bureau].filter(factor => factor.category == this.category && factor.scoreSentiment === "Negative")
    },
    positiveFactorCount(){
      return this.positiveFactors.length
    },
    negativeFactorCount(){
      return this.negativeFactors.length
    }
  }
}
</script>
