import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", null, [
    _withDirectives(_createElementVNode("input", {
      id: "ssn-input",
      type: "password",
      class: "mx-auto my-1 text-lg border-slate-200",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.ssnRaw) = $event)),
      onInput: _cache[1] || (_cache[1] = (ev) => _ctx.$emit('ssn-input', _ctx.ssnRaw)),
      maxlength: "9",
      placeholder: "Social Security Number",
      "data-lpignore": "true",
      autocomplete: "off"
    }, null, 544 /* HYDRATE_EVENTS, NEED_PATCH */), [
      [_vModelText, _ctx.ssnRaw]
    ])
  ]))
}