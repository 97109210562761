<template>
  <div class="mb-3">
    <label class="mb-1">Current Password</label>
    <div><input type="password" class="max-w-lg" v-model="currentPassword" /></div>
  </div>
  <div class="mb-3">
    <label class="mb-1">New Password</label>
    <div><input type="password" class="max-w-lg" v-model="newPassword" /></div>
  </div>
  <div class="mb-3">
    <label class="mb-1">Confirm New Password</label>
    <div><input type="password" class="max-w-lg" v-model="newPasswordConfirmation" /></div>
  </div>

  <button type="button" v-on:click="changePassword()" v-if="!showPasswordSpinner" class="mt-3 btn btn-lg btn-primary">
    Change Password
  </button>

  <div class="mb-3 alert alert-error" v-if="errors.length > 0">
    <div v-for="(error, i) in errors" :key="i">{{ error }}</div>
  </div>

  <Spinner v-if="showPasswordSpinner" />
</template>

<script>
import axios from "axios";
import Spinner from "../../shared/spinner_svg.vue";

export default {
  data() {
    return {
      errors: [],
      currentPassword: "",
      newPassword: "",
      newPasswordConfirmation: "",
      showPasswordSpinner: false,
    };
  },
  components: {
    Spinner,
  },
  methods: {
    changePassword: function () {
      this.showPasswordSpinner = true;
      this.errors = [];
      axios
        .post("/portal/profile/change-password", {
          user: {
            current_password: this.currentPassword,
            password: this.newPassword,
            password_confirmation: this.newPasswordConfirmation,
          },
        })
        .then(() => {
          window.dispatchEvent(new CustomEvent("ss-global-notice", { detail: "Password changed successfully." }));
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        })
        .finally(() => {
          this.showPasswordSpinner = false;
        });
    },
  },
};
</script>
