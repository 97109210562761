<template>
  <div>
    <div class="grid grid-cols-3 gap-3">
      <CardTile :cardType="creditCard.card_type" :last4="creditCard.last_4" v-if="creditCard" imageKey="primary" />

      <CardTile v-for="(card, i) in secondaryCards" :cardType="card.card_type" :last4="card.last_4"
        :key="`secondary-card-${i}`" :imageKey="(i % 4) + 1" />

      <div v-show="showAddCardForm" class="col-span-2 rounded-md border border-zinc-500 p-xl">
        <h4 class="mb-sm">New Card</h4>


        <div class="comment-s text-muted">Card Number</div>
        <div id="cc-number" style="height: 44px"
          class="w-full p-2 text-lg border bg-dull-white rounded-md border-slate-200"></div>

        <div class="grid grid-cols-1 gap-2 mt-3 md:grid-cols-2">
          <div>
            <div class="comment-s text-muted">Expiry</div>
            <input id="cc-expiry" type="text" inputmode="numeric" :value="ccExpiry"
              @input="maskCCExpiry($event.target.value)" class="w-full text-lg border-slate-200"
              style="font-family: Arial, sans-serif" placeholder="MM/YY" />
          </div>

          <div>
            <div class="comment-s text-muted">CVV</div>
            <div id="cc-cvv" style="height: 44px"
              class="w-full p-2 text-lg border bg-dull-white rounded-md border-slate-200">
            </div>
          </div>
        </div>

        <div class="my-sm">
          <button class="btn btn-primary" @click="getPaymentToken()">
            Save
          </button>

          <div v-if="errors.length > 0" class="text-red-500 mt-2">
            <div v-for="(error, i) in errors" :key="i">
              <div>{{ error }}</div>
            </div>
          </div>

          <Spinner v-if="showSpinner" />
        </div>
      </div>
      <div v-if="!showAddCardForm">
        <div class="rounded-md h-40 border border-zinc-500 bg-gray-100 p-sm flex flex-col cursor-pointer"
          v-on:click="showAddCardForm = true; initializeSpreedly()">
          <div class="my-auto text-center font-semibold">
            Add a Card
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* global Spreedly */
import { inject } from "vue";
import { store } from "../store";
import CardTile from "./card_tile.vue";
import Spinner from "../../shared/spinner_svg.vue";

export default {
  setup() {
    const spreedlyEnvironmentId = inject("spreedly.environmentId");
    return { spreedlyEnvironmentId };
  },
  components: {
    CardTile,
    Spinner
  },
  data: () => {
    return {
      subscriptions: [],
      creditCard: null,
      errors: [],
      ccExpiry: "",
      showSpinner: false,
      showAddCardForm: false,
      paymentToken: "",
      spreedlyInitialized: false
    };
  },
  mounted() {
    this.fetchBillingDetails();
  },
  computed: {
    ccExpiryMonth() {
      if (!this.ccExpiry.match(/\d\d\/\d\d/)) {
        return "";
      }

      return this.ccExpiry.substring(0, 2);
    },
    ccExpiryYear() {
      if (!this.ccExpiry.match(/\d\d\/\d\d/)) {
        return "";
      }

      // This code will need to be adjusted in another 1000 years
      return `20${this.ccExpiry.substring(3)}`;
    },
  },
  methods: {
    formattedCardType(string) {
      if (string == "visa") {
        return "Visa"
      } else if (string == "master") {
        return "Mastercard"
      } else if (string == "american_express") {
        return "American Express"
      } else if (string == "discover") {
        return "Discover"
      } else {
        // Titleize the string
        return string.replace(/_/g, " ").replace(/\w\S*/g, (txt) => {
          return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });
      }
    },
    maskCCExpiry(string) {
      const upToFourDigits = string.replace(/\D/g, "").substring(0, 4);

      if (upToFourDigits.length < 2) {
        this.ccExpiry = upToFourDigits;
      } else {
        // Add a slash after the 2nd character
        this.ccExpiry = upToFourDigits.substring(0, 2) + "/" + upToFourDigits.substring(2);
      }
    },
    validateCCExpiry() {
      if (!this.ccExpiry.match(/\d\d\/\d\d/) || parseInt(this.ccExpiryYear) < new Date().getFullYear()) {
        this.errors = ["Please enter a valid expiry date"];
        return false;
      }
      return true;
    },
    fetchBillingDetails() {
      fetch("/portal/billing")
        .then((response) => response.json())
        .then((data) => {
          this.subscriptions = data.subscriptions;
          this.creditCard = data.credit_card;
          this.secondaryCards = data.secondary_cards;
        });
    },
    getPaymentToken() {
      if (!this.validateCCExpiry()) {
        return;
      }

      this.showSpinner = true;
      let data = {
        full_name: `${store.profile.firstName} ${store.profile.lastName}`,
        month: this.ccExpiryMonth,
        year: this.ccExpiryYear,
      };

      Spreedly.tokenizeCreditCard(data);
    },
    submitPaymentToken(token) {
      this.showSpinner = true;

      fetch("/portal/billing/add-credit-card", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": document.querySelector("meta[name=csrf-token]").content,
        },
        body: JSON.stringify({ payment_method_token: token }),
      })
        .then((response) => response.json())
        .then((data) => {
          this.showSpinner = false;
          if (data.errors) {
            this.errors = data.errors;
          } else {
            this.showAddCardForm = false;
            this.fetchBillingDetails();
          }
        });
    },
    initializeSpreedly() {
      if (!this.showAddCardForm) {
        return;
      }
      if (this.spreedlyInitialized) {
        return;
      }

      this.spreedlyInitialized = true;
      this.showSpinner = true;

      Spreedly.init(this.spreedlyEnvironmentId, {
        numberEl: "cc-number",
        cvvEl: "cc-cvv",
      });

      Spreedly.on("ready", () => {
        this.showSpinner = false;

        Spreedly.setFieldType("number", "text");
        Spreedly.setNumberFormat("prettyFormat");
        Spreedly.setStyle("number", "font-size: 1.125rem; line-height: 1.75rem; width: 100%");
        Spreedly.setStyle("cvv", "font-size: 1.125rem; line-height: 1.75rem;");
      });

      Spreedly.on("errors", (errors) => {
        this.errors = errors.map((e) => e.message);
        this.showSpinner = false;
      });

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      Spreedly.on("paymentMethod", (token, pmData) => {
        this.submitPaymentToken(token);
      });
    },
  },

}
</script>
