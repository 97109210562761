<template>
  <div class="top-nav print:hidden" data-turbo="false">
    <header class="bg-white border-b">
      <nav class="flex items-center justify-between p-6 mx-auto max-w-7xl lg:px-8" aria-label="Global">
        <div class="flex lg:flex-1">
          <a href="#" class="-m-1.5 p-1.5">
            <span class="sr-only">Standard Scores</span>
            <img src="/images/logo.svg" class="w-40" />
          </a>
        </div>
        <div class="flex lg:hidden">
          <button type="button" class="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            v-on:click="mainMenuOpen = true">
            <span class="sr-only">Open main menu</span>
            <svg class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
              aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
            </svg>
          </button>
        </div>
        <div class="hidden lg:flex lg:gap-x-3">
          <a href="#/dashboard" class="nav-item" :class="navClassesForHash('#/dashboard')">
            <span>Dashboard</span>
          </a>
          <a href="#/credit-report" class="nav-item" :class="navClassesForHash('#/credit-report')">
            <span>Reports</span>
          </a>
          <a href="#/education/payment-history" class="nav-item" :class="navClassesForHash('#/education')">
            <span>Insights</span>
          </a>
          <a href="#/alerts" class="nav-item" :class="navClassesForHash('#/alerts')">
            <span class="inline-block text-center text-white bg-orange-700 rounded-full -ms-1 me-1"
              style="width: 13px; height: 13px; font-size: 10px; line-height: 12px"
              v-if="store.profile.unreadAlertCount > 0">
              {{ store.profile.unreadAlertCount }}
            </span>
            <span>Alerts</span>
          </a>
        </div>
        <div class="hidden lg:flex lg:flex-1 gap-3 lg:justify-end">
          <div>
            <a href="#" @click="logout()" class="nav-item">
              <span class="font-bold">Log Out</span>
              <ArrowRightOnRectangleIcon class="inline-block w-5 h-5 ml-3 text-gray-800" />
            </a>
          </div>
          <div>
            <div class="cursor-pointer" @click="userMenuOpen = !userMenuOpen">
              <UserIcon class="inline-block w-5 h-5 text-gray-800" />
              <ChevronDownIcon class="inline-block w-5 h-5 text-gray-800" />
            </div>
            <Transition name="fade">
              <div class="absolute z-10 w-72 -mt-10 bg-white border rounded-md shadow-md -ms-52" v-if="userMenuOpen">
                <div class="px-md py-sm space-y-2 border-b">
                  <div class="cursor-pointer text-right pr-2" @click="userMenuOpen = !userMenuOpen">
                    <UserIcon class="inline-block w-5 h-5 text-gray-800" />
                    <ChevronDownIcon class="inline-block w-5 h-5 text-gray-800" />
                  </div>
                  <div>
                    <div class="comment font-bold">{{ store.profile.planName }}</div>
                    <div class="comment-s text-green">Account Secured</div>
                  </div>
                </div>
                <div class="px-md py-sm space-y-2">
                  <div>
                    <div class="comment-s text-muted">First Name</div>
                    <div class="comment font-bold">{{ store.profile.firstName }}</div>
                  </div>
                  <div>
                    <div class="comment-s text-muted">Last Name</div>
                    <div class="comment font-bold">{{ store.profile.lastName }}</div>
                  </div>
                  <div>
                    <div class="comment-s text-muted">Phone number</div>
                    <div class="comment font-bold">{{ store.profile.phoneNumber }}</div>
                  </div>
                  <div>
                    <div class="comment-s text-muted">Email</div>
                    <div class="comment font-bold truncate">{{ store.profile.email }}</div>
                  </div>
                  <div class="py-sm">
                    <a href="#/profile" class="btn btn-lg btn-outline w-full text-center" @click="userMenuOpen = false">
                      <span>Settings</span>
                      <Cog8ToothIcon class="inline-block w-5 h-5 ml-2 align-text-bottom" />
                    </a>
                  </div>
                  <div>
                    <div class="comment">Customer Support</div>
                    <div class="text-lg font-bold">
                      <a :href="`tel:${window.customerSupportPhoneNumber}`">{{ window.customerSupportPhoneNumber }}</a>
                    </div>
                    <div class="mt-2 comment">Identity Theft Support</div>
                    <div class="text-lg font-bold">
                      <a :href="`tel:${window.identityTheftPhoneNumber}`">{{ window.identityTheftPhoneNumber }}</a>
                    </div>
                  </div>
                  <div class="pt-sm">
                    <a href="#" @click="logout()" class="btn btn-lg btn-dark w-full text-center">
                      <span>Logout</span>
                      <ArrowRightOnRectangleIcon class="inline-block w-5 h-5 ml-2 align-text-bottom" />
                    </a>
                  </div>
                </div>
              </div>
            </Transition>
          </div>
        </div>
      </nav>
      <!-- Mobile menu, show/hide based on menu open state. -->
      <div class="z-10 lg:hidden" role="dialog" aria-modal="true" @close="mobileMenuOpen = false" v-if="mainMenuOpen">
        <!-- Background backdrop, show/hide based on slide-over state. -->
        <div class="fixed inset-0 z-10"></div>
        <div
          class="fixed inset-y-0 right-0 z-10 w-full px-6 py-6 overflow-y-auto bg-white sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div class="flex items-center justify-between">
            <a href="#" class="-m-1.5 p-1.5">
              <span class="sr-only">Standard Scores</span>
              <img src="/images/logo.svg" class="w-40" />
            </a>
            <button type="button" class="-m-2.5 rounded-md p-2.5 text-gray-700" @click="mainMenuOpen = false">
              <span class="sr-only">Close menu</span>
              <svg class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
                aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
          <div class="flow-root mt-6">
            <div class="-my-6 divide-y divide-gray-500/10">
              <div class="py-6 space-y-2" @click="mainMenuOpen = false">
                <a href="#/dashboard"
                  class="block px-3 py-2 -mx-3 text-base font-medium leading-7 text-gray-800 rounded-lg hover:bg-gray-50"
                  :class="navClassesForHash('#/dashboard')">
                  Dashboard
                </a>
                <a href="#/credit-report"
                  class="block px-3 py-2 -mx-3 text-base font-medium leading-7 text-gray-800 rounded-lg hover:bg-gray-50"
                  :class="navClassesForHash('#/credit-report')">
                  Credit Reports
                </a>
                <a href="#/education/payment-history"
                  class="block px-3 py-2 -mx-3 text-base font-medium leading-7 text-gray-800 rounded-lg hover:bg-gray-50"
                  :class="navClassesForHash('#/education')">
                  Credit Insights
                </a>
                <a href="#/alerts"
                  class="block px-3 py-2 -mx-3 text-base font-medium leading-7 text-gray-800 rounded-lg hover:bg-gray-50"
                  :class="navClassesForHash('#/alerts')">
                  Alerts
                  <span class="inline-block text-center text-white bg-orange-600 rounded-full me-1"
                    style="width: 17px; height: 17px; font-size: 11px; line-height: 18px"
                    v-if="store.profile.unreadAlertCount > 0">
                    {{ store.profile.unreadAlertCount }}
                  </span>
                </a>
                <a href="#/profile"
                  class="block px-3 py-2 -mx-3 text-base font-medium leading-7 text-gray-800 rounded-lg hover:bg-gray-50"
                  :class="navClassesForHash('#/profile')">
                  Profile
                </a>
                <a href="#/billing"
                  class="block px-3 py-2 -mx-3 text-base font-medium leading-7 text-gray-800 rounded-lg hover:bg-gray-50"
                  :class="navClassesForHash('#/billing')">
                  Billing
                </a>
                <a href="#/contact-us"
                  class="block px-3 py-2 -mx-3 text-base font-medium leading-7 text-gray-800 rounded-lg hover:bg-gray-50"
                  :class="navClassesForHash('#/contact-us')">
                  Contact Us
                </a>
                <a href="#" @click="logout()"
                  class="block px-3 py-2 -mx-3 text-base font-medium leading-7 text-gray-800 rounded-lg hover:bg-gray-50">
                  Logout
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  </div>
</template>

<script>
import { store } from "./store";
import axios from "axios";
import {
  ChevronDownIcon,
  ArrowRightOnRectangleIcon,
} from "@heroicons/vue/20/solid";

import { UserIcon, Cog8ToothIcon } from "@heroicons/vue/24/outline";

export default {
  components: {
    UserIcon,
    ChevronDownIcon,
    Cog8ToothIcon,
    ArrowRightOnRectangleIcon,
  },
  props: {},
  data: function () {
    return {
      mainMenuOpen: false,
      userMenuOpen: false,
      currentHash: window.location.hash,
      store,
    };
  },
  methods: {
    navClassesForHash(hashString) {
      if (hashString == "#/dashboard") {
        // This can match either #/ or #/dashboard
        if (this.currentHash == "#/" || this.currentHash == "#/dashboard") {
          return "current";
        }
      } else {
        if (this.currentHash.includes(hashString)) {
          return "current";
        }
      }
    },
    logout() {
      axios.get("/portal/logout").then(() => {
        window.location = "/";
      });
    },
  },
  mounted() {
    // Update current hash when window hash changes - this is needed for the nav highlighting
    window.addEventListener(
      "hashchange",
      () => {
        this.currentHash = window.location.hash;
      },
      false
    );
  },
};
</script>
