<template>
  <div class="container mx-auto">
    <div class="grid grid-cols-1 md:gap-4 md:mt-12 md:grid-cols-3">
      <div class="col-span-2">
        <div>
          <div class="mb-3 text-4xl font-semibold">Credit Inquiries</div>
          <p>
            The number of hard credit inquiries, often referred to as "hard pulls," is a significant factor in your
            credit report and can have an impact on your credit score and your ability to obtain credit.
          </p>
        </div>

        <div class="my-6 text-sm font-bold text-gray-500">
          <span>Factors</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="inline-block w-3 h-3 mx-2">
            <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3" />
          </svg>
          <span>Credit Inquiries</span>
        </div>

        <div class="mb-3 text-xl font-semibold">How are credit inquiries counted?</div>
        <p>
          Usually inquiries clustered in a small time frame for a specific type of loan product will only count as a
          single inquiry in computing your credit score. In our website you'll see every inquiry is counted as an
          inquiry.
        </p>
        <p>To minimize the impact of hard inquiries on your credit:</p>
        <p>Be selective when applying for new credit, and only apply for credit that you truly need.</p>
        <p>
          Research and compare offers from different lenders before applying to minimize the number of inquiries.
        </p>
        <p>
          When shopping for credit (e.g., a mortgage or auto loan), try to complete your rate shopping within a short
          timeframe to ensure that multiple inquiries are treated as one.
        </p>
        <p>
          While hard inquiries can have a temporary impact on your credit, their significance tends to diminish over
          time. To maintain a strong credit profile, it's essential to manage your credit responsibly and keep hard
          inquiries to a reasonable minimum.
        </p>
        <Inquiries v-bind:inquiries="store.creditReport.inquiries" />
      </div>
      <div class="order-first md:order-last">
        <div v-if="store.creditReport">
          <div class="rounded-md">
            <BureauTabs :bureau="bureau" @select-bureau="(newBureau) => (bureau = newBureau)" />
            <Transition name="fade" mode="out-in">
              <div
                v-bind:class="`text-center py-5 rounded-b-md border border-t-0 bg-gradient-${store.scoreFactors.creditInquiries.status}`"
                :key="bureau">
                <div class="my-3 text-4xl font-semibold">{{ inquiriesLast6Months.length }}</div>
                <div class="text-sm">Updated: {{ formatDate(store.creditReport.reportDate[bureau], "MMM D, YYYY") }}</div>
                <div
                  v-bind:class="`my-3 credit-score-factor-status credit-score-factor-status-${store.scoreFactors.creditInquiries.status}`">
                  {{ store.scoreFactors.creditInquiries.status }}
                </div>
              </div>
            </Transition>
          </div>
        </div>
        <SideNav />
      </div>
    </div>
  </div>
</template>

<script>
import { store } from "../store"
import SideNav from "./side_nav.vue";
import Inquiries from "../credit_report/section_inquiries.vue";
import BureauTabs from "../bureau_tabs.vue";
import moment from "moment";

const sixMonthsAgo = moment().subtract(6, "months");

export default {
  components: {
    SideNav,
    Inquiries,
    BureauTabs,
  },
  data() {
    return {
      bureau: "tuc",
      store
    };
  },
  computed: {
    inquiriesLast6Months() {
      return store.creditReport.inquiries[this.bureau].filter((inquiry) => {
        return moment(inquiry.date).isAfter(sixMonthsAgo);
      });
    },
  },
};
</script>
