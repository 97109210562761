import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["selected"]
const _hoisted_2 = { label: "States" }
const _hoisted_3 = ["selected"]
const _hoisted_4 = ["selected"]
const _hoisted_5 = ["selected"]
const _hoisted_6 = ["selected"]
const _hoisted_7 = ["selected"]
const _hoisted_8 = ["selected"]
const _hoisted_9 = ["selected"]
const _hoisted_10 = ["selected"]
const _hoisted_11 = ["selected"]
const _hoisted_12 = ["selected"]
const _hoisted_13 = ["selected"]
const _hoisted_14 = ["selected"]
const _hoisted_15 = ["selected"]
const _hoisted_16 = ["selected"]
const _hoisted_17 = ["selected"]
const _hoisted_18 = ["selected"]
const _hoisted_19 = ["selected"]
const _hoisted_20 = ["selected"]
const _hoisted_21 = ["selected"]
const _hoisted_22 = ["selected"]
const _hoisted_23 = ["selected"]
const _hoisted_24 = ["selected"]
const _hoisted_25 = ["selected"]
const _hoisted_26 = ["selected"]
const _hoisted_27 = ["selected"]
const _hoisted_28 = ["selected"]
const _hoisted_29 = ["selected"]
const _hoisted_30 = ["selected"]
const _hoisted_31 = ["selected"]
const _hoisted_32 = ["selected"]
const _hoisted_33 = ["selected"]
const _hoisted_34 = ["selected"]
const _hoisted_35 = ["selected"]
const _hoisted_36 = ["selected"]
const _hoisted_37 = ["selected"]
const _hoisted_38 = ["selected"]
const _hoisted_39 = ["selected"]
const _hoisted_40 = ["selected"]
const _hoisted_41 = ["selected"]
const _hoisted_42 = ["selected"]
const _hoisted_43 = ["selected"]
const _hoisted_44 = ["selected"]
const _hoisted_45 = ["selected"]
const _hoisted_46 = ["selected"]
const _hoisted_47 = ["selected"]
const _hoisted_48 = ["selected"]
const _hoisted_49 = ["selected"]
const _hoisted_50 = ["selected"]
const _hoisted_51 = ["selected"]
const _hoisted_52 = ["selected"]
const _hoisted_53 = ["selected"]
const _hoisted_54 = { label: "US Territories" }
const _hoisted_55 = ["selected"]
const _hoisted_56 = ["selected"]
const _hoisted_57 = ["selected"]
const _hoisted_58 = ["selected"]
const _hoisted_59 = ["selected"]
const _hoisted_60 = ["selected"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("select", {
      class: _normalizeClass($props.class),
      onChange: _cache[0] || (_cache[0] = (ev) => _ctx.$emit('update:modelValue', ev.target.value)),
      placeholder: "State"
    }, [
      _createElementVNode("option", {
        value: "",
        selected: $props.modelValue == '',
        disabled: ""
      }, "State/Territory", 8 /* PROPS */, _hoisted_1),
      _createElementVNode("optgroup", _hoisted_2, [
        _createElementVNode("option", {
          selected: $props.modelValue == 'AL',
          value: "AL"
        }, "Alabama", 8 /* PROPS */, _hoisted_3),
        _createElementVNode("option", {
          selected: $props.modelValue == 'AK',
          value: "AK"
        }, "Alaska", 8 /* PROPS */, _hoisted_4),
        _createElementVNode("option", {
          selected: $props.modelValue == 'AZ',
          value: "AZ"
        }, "Arizona", 8 /* PROPS */, _hoisted_5),
        _createElementVNode("option", {
          selected: $props.modelValue == 'AR',
          value: "AR"
        }, "Arkansas", 8 /* PROPS */, _hoisted_6),
        _createElementVNode("option", {
          selected: $props.modelValue == 'CA',
          value: "CA"
        }, "California", 8 /* PROPS */, _hoisted_7),
        _createElementVNode("option", {
          selected: $props.modelValue == 'CO',
          value: "CO"
        }, "Colorado", 8 /* PROPS */, _hoisted_8),
        _createElementVNode("option", {
          selected: $props.modelValue == 'CT',
          value: "CT"
        }, "Connecticut", 8 /* PROPS */, _hoisted_9),
        _createElementVNode("option", {
          selected: $props.modelValue == 'DE',
          value: "DE"
        }, "Delaware", 8 /* PROPS */, _hoisted_10),
        _createElementVNode("option", {
          selected: $props.modelValue == 'DC',
          value: "DC"
        }, "District Of Columbia", 8 /* PROPS */, _hoisted_11),
        _createElementVNode("option", {
          selected: $props.modelValue == 'FL',
          value: "FL"
        }, "Florida", 8 /* PROPS */, _hoisted_12),
        _createElementVNode("option", {
          selected: $props.modelValue == 'GA',
          value: "GA"
        }, "Georgia", 8 /* PROPS */, _hoisted_13),
        _createElementVNode("option", {
          selected: $props.modelValue == 'HI',
          value: "HI"
        }, "Hawaii", 8 /* PROPS */, _hoisted_14),
        _createElementVNode("option", {
          selected: $props.modelValue == 'ID',
          value: "ID"
        }, "Idaho", 8 /* PROPS */, _hoisted_15),
        _createElementVNode("option", {
          selected: $props.modelValue == 'IL',
          value: "IL"
        }, "Illinois", 8 /* PROPS */, _hoisted_16),
        _createElementVNode("option", {
          selected: $props.modelValue == 'IN',
          value: "IN"
        }, "Indiana", 8 /* PROPS */, _hoisted_17),
        _createElementVNode("option", {
          selected: $props.modelValue == 'IA',
          value: "IA"
        }, "Iowa", 8 /* PROPS */, _hoisted_18),
        _createElementVNode("option", {
          selected: $props.modelValue == 'KS',
          value: "KS"
        }, "Kansas", 8 /* PROPS */, _hoisted_19),
        _createElementVNode("option", {
          selected: $props.modelValue == 'KY',
          value: "KY"
        }, "Kentucky", 8 /* PROPS */, _hoisted_20),
        _createElementVNode("option", {
          selected: $props.modelValue == 'LA',
          value: "LA"
        }, "Louisiana", 8 /* PROPS */, _hoisted_21),
        _createElementVNode("option", {
          selected: $props.modelValue == 'ME',
          value: "ME"
        }, "Maine", 8 /* PROPS */, _hoisted_22),
        _createElementVNode("option", {
          selected: $props.modelValue == 'MD',
          value: "MD"
        }, "Maryland", 8 /* PROPS */, _hoisted_23),
        _createElementVNode("option", {
          selected: $props.modelValue == 'MA',
          value: "MA"
        }, "Massachusetts", 8 /* PROPS */, _hoisted_24),
        _createElementVNode("option", {
          selected: $props.modelValue == 'MI',
          value: "MI"
        }, "Michigan", 8 /* PROPS */, _hoisted_25),
        _createElementVNode("option", {
          selected: $props.modelValue == 'MN',
          value: "MN"
        }, "Minnesota", 8 /* PROPS */, _hoisted_26),
        _createElementVNode("option", {
          selected: $props.modelValue == 'MS',
          value: "MS"
        }, "Mississippi", 8 /* PROPS */, _hoisted_27),
        _createElementVNode("option", {
          selected: $props.modelValue == 'MO',
          value: "MO"
        }, "Missouri", 8 /* PROPS */, _hoisted_28),
        _createElementVNode("option", {
          selected: $props.modelValue == 'MT',
          value: "MT"
        }, "Montana", 8 /* PROPS */, _hoisted_29),
        _createElementVNode("option", {
          selected: $props.modelValue == 'NE',
          value: "NE"
        }, "Nebraska", 8 /* PROPS */, _hoisted_30),
        _createElementVNode("option", {
          selected: $props.modelValue == 'NV',
          value: "NV"
        }, "Nevada", 8 /* PROPS */, _hoisted_31),
        _createElementVNode("option", {
          selected: $props.modelValue == 'NH',
          value: "NH"
        }, "New Hampshire", 8 /* PROPS */, _hoisted_32),
        _createElementVNode("option", {
          selected: $props.modelValue == 'NJ',
          value: "NJ"
        }, "New Jersey", 8 /* PROPS */, _hoisted_33),
        _createElementVNode("option", {
          selected: $props.modelValue == 'NM',
          value: "NM"
        }, "New Mexico", 8 /* PROPS */, _hoisted_34),
        _createElementVNode("option", {
          selected: $props.modelValue == 'NY',
          value: "NY"
        }, "New York", 8 /* PROPS */, _hoisted_35),
        _createElementVNode("option", {
          selected: $props.modelValue == 'NC',
          value: "NC"
        }, "North Carolina", 8 /* PROPS */, _hoisted_36),
        _createElementVNode("option", {
          selected: $props.modelValue == 'ND',
          value: "ND"
        }, "North Dakota", 8 /* PROPS */, _hoisted_37),
        _createElementVNode("option", {
          selected: $props.modelValue == 'OH',
          value: "OH"
        }, "Ohio", 8 /* PROPS */, _hoisted_38),
        _createElementVNode("option", {
          selected: $props.modelValue == 'OK',
          value: "OK"
        }, "Oklahoma", 8 /* PROPS */, _hoisted_39),
        _createElementVNode("option", {
          selected: $props.modelValue == 'OR',
          value: "OR"
        }, "Oregon", 8 /* PROPS */, _hoisted_40),
        _createElementVNode("option", {
          selected: $props.modelValue == 'PA',
          value: "PA"
        }, "Pennsylvania", 8 /* PROPS */, _hoisted_41),
        _createElementVNode("option", {
          selected: $props.modelValue == 'RI',
          value: "RI"
        }, "Rhode Island", 8 /* PROPS */, _hoisted_42),
        _createElementVNode("option", {
          selected: $props.modelValue == 'SC',
          value: "SC"
        }, "South Carolina", 8 /* PROPS */, _hoisted_43),
        _createElementVNode("option", {
          selected: $props.modelValue == 'SD',
          value: "SD"
        }, "South Dakota", 8 /* PROPS */, _hoisted_44),
        _createElementVNode("option", {
          selected: $props.modelValue == 'TN',
          value: "TN"
        }, "Tennessee", 8 /* PROPS */, _hoisted_45),
        _createElementVNode("option", {
          selected: $props.modelValue == 'TX',
          value: "TX"
        }, "Texas", 8 /* PROPS */, _hoisted_46),
        _createElementVNode("option", {
          selected: $props.modelValue == 'UT',
          value: "UT"
        }, "Utah", 8 /* PROPS */, _hoisted_47),
        _createElementVNode("option", {
          selected: $props.modelValue == 'VT',
          value: "VT"
        }, "Vermont", 8 /* PROPS */, _hoisted_48),
        _createElementVNode("option", {
          selected: $props.modelValue == 'VA',
          value: "VA"
        }, "Virginia", 8 /* PROPS */, _hoisted_49),
        _createElementVNode("option", {
          selected: $props.modelValue == 'WA',
          value: "WA"
        }, "Washington", 8 /* PROPS */, _hoisted_50),
        _createElementVNode("option", {
          selected: $props.modelValue == 'WV',
          value: "WV"
        }, "West Virginia", 8 /* PROPS */, _hoisted_51),
        _createElementVNode("option", {
          selected: $props.modelValue == 'WI',
          value: "WI"
        }, "Wisconsin", 8 /* PROPS */, _hoisted_52),
        _createElementVNode("option", {
          selected: $props.modelValue == 'WY',
          value: "WY"
        }, "Wyoming", 8 /* PROPS */, _hoisted_53)
      ]),
      _createElementVNode("optgroup", _hoisted_54, [
        _createElementVNode("option", {
          selected: $props.modelValue == 'AS',
          value: "AS"
        }, "American Samoa", 8 /* PROPS */, _hoisted_55),
        _createElementVNode("option", {
          selected: $props.modelValue == 'GU',
          value: "GU"
        }, "Guam", 8 /* PROPS */, _hoisted_56),
        _createElementVNode("option", {
          selected: $props.modelValue == 'MP',
          value: "MP"
        }, "Northern Mariana Islands", 8 /* PROPS */, _hoisted_57),
        _createElementVNode("option", {
          selected: $props.modelValue == 'PR',
          value: "PR"
        }, "Puerto Rico", 8 /* PROPS */, _hoisted_58),
        _createElementVNode("option", {
          selected: $props.modelValue == 'UM',
          value: "UM"
        }, "United States Minor Outlying Islands", 8 /* PROPS */, _hoisted_59),
        _createElementVNode("option", {
          selected: $props.modelValue == 'VI',
          value: "VI"
        }, "Virgin Islands", 8 /* PROPS */, _hoisted_60)
      ])
    ], 34 /* CLASS, HYDRATE_EVENTS */)
  ]))
}