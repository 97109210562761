<template>
  <div>
    <div class="grid md:grid-cols-2 grid-cols-1">
      <div class="space-y-5">
        <div>
          <label class="mb-1">First Name</label>
          <div><input type="text" class="w-64" v-model="store.profile.firstName" /></div>
        </div>
        <div>
          <label class="mb-1">Last Name</label>
          <div><input type="text" class="w-64" v-model="store.profile.lastName" /></div>
        </div>
        <div>
          <label class="mb-1">Email Address</label>
          <div><input type="text" class="w-64" v-model="store.profile.email" /></div>
        </div>
      </div>
      <div>
        <!-- Create form fields for address (line1, line2, city, state, zip) -->
        <div class="space-y-5">
          <div>
            <label class="mb-1">Address</label>
            <div>
              <input type="text" class="w-64" v-model="store.profile.address.line_1" placeholder="Address Line 1" />
            </div>
            <div>
              <input type="text" class="w-64 mt-1" v-model="store.profile.address.line_2" placeholder="Address Line 2" />
            </div>
          </div>
          <div>
            <label class="mb-1">City</label>
            <div><input type="text" class="w-64" v-model="store.profile.address.city" placeholder="City" /></div>
          </div>
          <div>
            <label class="mb-1">State</label>
            <div><StateSelect id="user-state" v-model="store.profile.address.state" class="w-64 py-2.5" /></div>
          </div>
          <div>
            <label class="mb-1">Zip</label>
            <div><input type="text" class="w-64" v-model="store.profile.address.zip" placeholder="Zip" /></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <button type="button" v-on:click="updateProfile()" v-if="!showProfileSpinner" class="btn btn-lg btn-primary mt-5">
    Submit
  </button>
  <Spinner v-if="showProfileSpinner" />
</template>
<script>
import axios from "axios";
import { store } from "../store";
import Spinner from "../../shared/spinner_svg.vue";
import StateSelect from "../../registration/state_select.vue";

export default {
  components: {
    Spinner,
    StateSelect,
  },
  methods: {
    updateProfile() {
      this.showProfileSpinner = true;
      this.errors = [];
      axios
        .post("/portal/profile/update", {
          user: {
            first_name: store.profile.firstName,
            last_name: store.profile.lastName,
            email: store.profile.email,
            address: store.profile.address,
          },
        })
        .then(() => {
          window.dispatchEvent(new CustomEvent("ss-global-notice", { detail: "Profile updated successfully." }));
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        })
        .finally(() => {
          this.showProfileSpinner = false;
          this.$emit("show-profile");
        });
    },
  },
  data() {
    return {
      store,
      showProfileSpinner: false,
    };
  },
}
</script>
