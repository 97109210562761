<template>
  <div class="container mx-auto">
    <div class="grid grid-cols-1 md:gap-4 md:mt-12 md:grid-cols-3">
      <div class="col-span-2">
        <div>
          <div class="mb-3 text-4xl font-semibold">Negative Marks</div>
          <p>
            Accounts that appear as delinquent or derogatory on your credit report can significantly impact your credit
            score and your ability to secure new credit.
          </p>
        </div>

        <div class="my-6 text-sm font-bold text-gray-500">
          <span>Factors</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="inline-block w-3 h-3 mx-2">
            <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3" />
          </svg>
          <span>Negative Marks</span>
        </div>

        <div class="text-xl font-semibold">How is your negative credit marks calculated?</div>
        <p>We count all the accounts that have had a late payment or negative mark in the last 2 years.</p>
        <div class="my-5 text-center">
          <div class="flex justify-center gap-2">
            <div>
              <div class="text-2xl text-gray-500" v-if="store.creditReport">
                {{ delinquentAccounts }}
              </div>
              <div class="font-semibold">Delinquent</div>
            </div>
            <div class="flex flex-col"><div class="mt-auto">+</div></div>
            <div>
              <div class="text-2xl text-gray-500" v-if="store.creditReport">
                {{ derogatoryAccounts }}
              </div>
              <div class="font-semibold">Derogatory</div>
            </div>
            <div class="flex flex-col"><div class="mt-auto">=</div></div>
            <div>
              <div class="text-2xl text-gray-500" v-if="store.creditReport">
                {{ negativeMarks }}
              </div>
              <div class="font-semibold">Negative Marks</div>
            </div>
          </div>
        </div>
        <p>
          <strong>Delinquent Accounts:</strong>
        </p>
        <ul class="ml-5 list-disc list-outside">
          <li>
            Delinquent accounts are those that are past due on payments. When you fail to make payments on time, the
            account becomes delinquent.
          </li>
          <li>
            Delinquencies are typically categorized by the number of days past due, such as 30, 60, 90, or 120+ days.
          </li>
          <li>
            Even a single late payment can have a negative impact on your credit report, although the severity of the
            impact may increase with the length of the delinquency.
          </li>
        </ul>
        <p class="mt-3">
          <strong>Derogatory Accounts:</strong>
        </p>
        <ul class="ml-5 list-disc list-outside">
          <li>
            Derogatory accounts are more severe than delinquent accounts. They often have more serious negative marks
            against them, such as charge-offs, collections, bankruptcies, foreclosures, or tax liens.
          </li>
          <li>
            These accounts can significantly damage your credit score and stay on your credit report for several years,
            making it difficult to obtain new credit.
          </li>
        </ul>
      </div>
      <div class="order-first md:order-last">
        <div v-if="store.creditReport">
          <div class="rounded-md">
            <BureauTabs :bureau="bureau" @select-bureau="(newBureau) => (bureau = newBureau)" />
            <Transition name="fade" mode="out-in">
              <div
                v-bind:class="`text-center py-5 rounded-b-md border border-t-0 bg-gradient-${store.scoreFactors.negativeMarks.status}`"
                :key="bureau">
                <div class="my-3 text-4xl font-semibold">{{ negativeMarks }}</div>
                <div class="text-sm">Updated: {{ formatDate(store.creditReport.reportDate[bureau], "MMM D, YYYY") }}</div>
                <div
                  v-bind:class="`my-3 credit-score-factor-status credit-score-factor-status-${store.scoreFactors.negativeMarks.status}`">
                  {{ store.scoreFactors.negativeMarks.status }}
                </div>
              </div>
            </Transition>
          </div>
        </div>
        <SideNav v-bind:scoreFactors="store.scoreFactors" />
      </div>
    </div>
  </div>
</template>

<script>
import { store } from "../store"
import SideNav from "./side_nav.vue";
import BureauTabs from "../bureau_tabs.vue";

export default {
  components: {
    SideNav,
    BureauTabs,
  },
  data() {
    return {
      bureau: "tuc",
      store
    };
  },
  computed: {
    delinquentAccounts: function () {
      return parseInt(store.creditReport.summary.tradelineSummary[this.bureau].delinquentAccounts);
    },
    derogatoryAccounts: function () {
      return parseInt(store.creditReport.summary.tradelineSummary[this.bureau].derogatoryAccounts);
    },
    negativeMarks: function () {
      return this.delinquentAccounts + this.derogatoryAccounts;
    },
  },
};
</script>
