<template>
  <div>
    <div>
      <a href="#/profile" class="block my-md comment text-black">
        <div v-bind:class="{ 'font-bold': isCurrentHash('#/profile') }">
          Your Info
          <UserIcon class="inline-block w-6 h-6 ml-3 text-gray-800" />
        </div>
      </a>
      <a href="#/billing" class="block my-md comment text-black">
        <div v-bind:class="{ 'font-bold': isCurrentHash('#/billing') }">
          Billing
          <CreditCardIcon class="inline-block w-6 h-6 ml-3 text-gray-800" />
        </div>
      </a>
      <a href="#/contact-us" class="block my-md comment text-black">
        <div v-bind:class="{ 'font-bold': isCurrentHash('#/contact-us') }">
          Customer Support
          <ChatBubbleLeftIcon class="inline-block w-6 h-6 ml-3 text-gray-800" />
        </div>
      </a>
    </div>
  </div>
</template>

<script>
import { store } from "../store";
import { UserIcon, CreditCardIcon, ChatBubbleLeftIcon } from "@heroicons/vue/24/outline";
export default {
  components: {
    UserIcon,
    CreditCardIcon,
    ChatBubbleLeftIcon,
  },
  data() {
    return {
      store,
    };
  },
  methods: {
    isCurrentHash: function (s) {
      return window.location.hash == s;
    },
  },
};
</script>
