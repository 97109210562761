<template>
  <div>
    <div class="text-lg font-semibold">Credit score history</div>
    <apexchart type="line" :options="chartOptions" :series="chartData" v-if="showChart"></apexchart>
    <Spinner v-else-if="!store.scoreFetched" />
  </div>
</template>

<script>
import {store} from "../store"
import VueApexCharts from "vue3-apexcharts";
import Spinner from "../../shared/spinner_svg.vue";

export default {
  components: { apexchart: VueApexCharts, Spinner },
  data() {
    return {
      store,
      chartOptions: {
        xaxis: { type: "datetime" },
        chart: {
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
      },
    };
  },
  computed: {
    showChart() {
      return store.creditScoreHistory && store.creditScoreHistory.length > 0;
    },
    chartData() {
      if (!store.creditScoreHistory) {
        return [{ name: "Credit Score", data: [] }];
      }

      return [
        {
          name: "Credit Score",
          data: store.creditScoreHistory.map((entry) => {
            return { x: entry.date, y: entry.value };
          }),
        },
      ];
    },
  },
};
</script>
