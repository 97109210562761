import moment from "moment";

export default {
  format(dateString: string, formatString = "MM/DD/YYYY"): string | null {
    if (!dateString) {
      return null;
    }

    const date = moment(dateString);
    if (!date.isValid()) {
      return null;
    }

    return date.format(formatString);
  },
};
