import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  href: "#/profile",
  class: "block my-md comment text-black"
}
const _hoisted_2 = {
  href: "#/billing",
  class: "block my-md comment text-black"
}
const _hoisted_3 = {
  href: "#/contact-us",
  class: "block my-md comment text-black"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_UserIcon = _resolveComponent("UserIcon")
  const _component_CreditCardIcon = _resolveComponent("CreditCardIcon")
  const _component_ChatBubbleLeftIcon = _resolveComponent("ChatBubbleLeftIcon")

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", null, [
      _createElementVNode("a", _hoisted_1, [
        _createElementVNode("div", {
          class: _normalizeClass({ 'font-bold': $options.isCurrentHash('#/profile') })
        }, [
          _createTextVNode(" Your Info "),
          _createVNode(_component_UserIcon, { class: "inline-block w-6 h-6 ml-3 text-gray-800" })
        ], 2 /* CLASS */)
      ]),
      _createElementVNode("a", _hoisted_2, [
        _createElementVNode("div", {
          class: _normalizeClass({ 'font-bold': $options.isCurrentHash('#/billing') })
        }, [
          _createTextVNode(" Billing "),
          _createVNode(_component_CreditCardIcon, { class: "inline-block w-6 h-6 ml-3 text-gray-800" })
        ], 2 /* CLASS */)
      ]),
      _createElementVNode("a", _hoisted_3, [
        _createElementVNode("div", {
          class: _normalizeClass({ 'font-bold': $options.isCurrentHash('#/contact-us') })
        }, [
          _createTextVNode(" Customer Support "),
          _createVNode(_component_ChatBubbleLeftIcon, { class: "inline-block w-6 h-6 ml-3 text-gray-800" })
        ], 2 /* CLASS */)
      ])
    ])
  ]))
}