import moment from 'moment'

const requiredFields = {
  "address": [
    // We need to check the address object manually because there's no point in
    // coding a way to define fields inside objects here.
  ],
  "name": [
    "first_name",
    "last_name"
  ],
  "date-of-birth": [
    "dob_month",
    "dob_day",
    "dob_year"
  ],
  "account-info": [
    "email",
    "phone",
    "password",
    "password_confirmation"
  ],
  "ssn": ["ssn"],
  "payment": []
}

const prettyNames = {
  email: "Email",
  phone: "Phone",
  first_name: "First name",
  last_name: "Last name",
  address_line_1: "Address Line 1",
  address_line_2: "Address Line 2",
  password: "Password",
  password_confirmation: "Password confirmation",
  city: "City",
  state: "State",
  zip: "Zip",
  dob_month: "Month",
  dob_day: "Day",
  dob_year: "Year",
  ssn: "SSN"
}

const result : {status: string, errors: string[]} = {
  status: "valid",
  errors: []
}

function checkRequiredFields(step, data){
  for(const fieldName of requiredFields[step]){
    // Check for null/empty/falsey value
    if(!data[fieldName]){
      result.status = "invalid"
      result.errors.push(`${prettyNames[fieldName]} cannot be blank`)
    }
  }
}

function validateAddress(address, errorPrefix = ""){
  const zipFormat1 = /^\d\d\d\d\d$/g
  const zipFormat2 = /^\d\d\d\d\d-\d\d\d\d$/g

  const streetAddressFormat = /^[\w\s#-]+$/g
  const line1DigitCheck = /^\d+$/g
  const cityFormat = /^[\w\s-']+$/g

  if(!streetAddressFormat.test(address.address_line_1)){
    result.status = "invalid"
    result.errors.push(`${errorPrefix}${prettyNames.address_line_1} cannot contain special characters other than '-' and '#'`)
  }

  if(line1DigitCheck.test(address.address_line_1)){
    result.status = "invalid"
    result.errors.push(`${errorPrefix}${prettyNames.address_line_1} cannot be just contain digits`)
  }

  if(address.address_line_2.length > 0 && !streetAddressFormat.test(address.address_line_2)){
    result.status = "invalid"
    result.errors.push(`${errorPrefix}${prettyNames.address_line_2} cannot contain special characters other than '-' and '#'`)
  }

  if(!cityFormat.test(address.city)){
    result.status = "invalid"
    result.errors.push(`${errorPrefix}${prettyNames.city} cannot contain special characters other than "-" and "'"`)
  }

  if(!zipFormat1.test(address.zip) && !zipFormat2.test(address.zip)){
    result.status = "invalid"
    result.errors.push(`${errorPrefix}${prettyNames.zip} must be in the format XXXXX or XXXXX-XXXX`)
  }

}

function checkFieldValues(step, data){
  if(step == "address"){
    validateAddress(data.address)

    if(data.use_previous_address){
      validateAddress(data.previous_address, "Previous ")
    }
  }else if(step == "date-of-birth"){
    const dobString = `${data.dob_year}-${data.dob_month.padStart(2, "0")}-${data.dob_day.padStart(2, "0")}`

    const dob_18y = moment(dobString)

    if(!dob_18y.isValid()){
      result.status = "invalid"
      result.errors.push(`Please provide a valid date of birth`)
    }

    dob_18y.add(18, 'y')

    if(dob_18y.isAfter(moment())){
      result.status = "invalid"
      result.errors.push(`You must be at least 18 years old to sign up for any Standard Scores services.`)
    }
  }else if (step == "account-info"){
    const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/g

    if(data.email.length >= 50){
      result.status = "invalid"
      result.errors.push(`Email address cannot exceed 50 characters in length`)
    }

    if(!emailRegex.test(data.email)){
      result.status = "invalid"
      result.errors.push(`The provided email address is invalid`)
    }

    if(data.password.length < 8){
      result.status = "invalid"
      result.errors.push(`Password must be at least 8 characters long`)
    }

    if(!/[A-Z]/.test(data.password) || !/[a-z]/.test(data.password) || !/\d/.test(data.password) || !/\W/.test(data.password)){
      result.status = "invalid"
      result.errors.push(`Password must have upper & lower case characters, and must include at least 1 number and special charater`)
    }

    if(data.password != data.password_confirmation){
      result.status = "invalid"
      result.errors.push(`Password must match confirmation`)
    }
  }else if(step == "ssn"){
    const ssnFormat = /^\d\d\d\d\d\d\d\d\d$/g

    if(!ssnFormat.test(data.ssn)){
      result.status = "invalid"
      result.errors.push(`Please provide a valid SSN.`)
    }
  }
}

export default {
  validate: function(step, data){
    result.status = "valid"
    result.errors = []

    checkRequiredFields(step, data)
    checkFieldValues(step, data)

    return result
  }
}
