<template>
  <div class="w-full h-full p-2">
    <form id="ccs-auth-form" action="/ccs-auth">
      <div class="w-full mx-auto my-12 registration-step md:max-w-xl">
        <h4 class="text-3xl font-bold text-center">Authentication</h4>

        <div v-if="authFailed" id="auth-failed">
          Your answers were incorrect. Please contact us for information on how to proceed.
        </div>
        <div v-else>
          <div class="my-3">Please answer the following questions to verify your identity</div>

          <div v-for="(question, index) in questions" :key="question.id">
            <MCQ
              :question="question"
              :index="index"
              @select-answer="
                (answerID) => {
                  question.answer_id = answerID;
                  this.answerProvided = true;
                }
              "
              @user-input="
                (answerData) => {
                  question.answer_id = answerData.answer_id;
                  question.user_input = answerData.user_input;
                  this.answerProvided = answerData.user_input.length > 0;
                }
              " />
          </div>

          <div class="w-full mx-auto text-center md:max-w-xl">
            <div v-if="errors" id="error-messages">
              <div v-for="error in errors" :key="error" class="text-red-700">{{ error }}</div>
            </div>
          </div>
        </div>

        <div v-if="showSpinner" class="text-center">
          <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" class="inline-block">
            <path d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,19a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z" opacity=".25" />
            <path
              d="M10.14,1.16a11,11,0,0,0-9,8.92A1.59,1.59,0,0,0,2.46,12,1.52,1.52,0,0,0,4.11,10.7a8,8,0,0,1,6.66-6.61A1.42,1.42,0,0,0,12,2.69h0A1.57,1.57,0,0,0,10.14,1.16Z">
              <animateTransform
                attributeName="transform"
                type="rotate"
                dur="0.75s"
                values="0 12 12;360 12 12"
                repeatCount="indefinite" />
            </path>
          </svg>
        </div>

        <div v-show="confirmSSN">
          Please confirm if this is your complete SSN:
          <span id="complete-ssn">{{ ssn }}</span>
        </div>

        <button
          id="btn-continue"
          type="button"
          class="w-full p-3 mt-3 text-lg font-semibold bg-brand hover:bg-amber-400"
          v-on:click="submit()"
          v-if="!showSpinner && !authFailed"
          v-bind:disabled="!answerProvided">
          Continue
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="inline-block w-5 h-5 align-sub">
            <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75" />
          </svg>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import axios from "axios";
import MCQ from "./multiple_choice_question.vue";

export default {
  components: {
    MCQ,
  },
  data() {
    return {
      showSpinner: false,
      questions: [],
      errors: [],
      authFailed: false,
      answerProvided: false,
      ssn: "",
      confirmSSN: false
    };
  },
  methods: {
    submit() {
      this.showSpinner = true;
      this.errors = [];
      axios
        .post("/ccs-auth/verification", {
          questions: this.questions,
        })
        .then((response) => {
          if (response.data.result == "success") {
            if (response.data.questions && response.data.questions.length > 0) {
              this.showSpinner = false;
              this.questions = response.data.questions;
              this.ssn = response.data.ssn
              this.answerProvided = false;
            } else {
              // All steps completed - move on.
              window.location = "/portal";
            }
          } else {
            this.showSpinner = false;
            this.errors = response.data.errors;

            if (response.data.auth_failed) {
              this.authFailed = true;
            }
          }
        });
    },
  },
  mounted() {
    axios.get("/ccs-auth-questions").then((response) => {
      this.questions = response.data.questions;
      this.ssn = response.data.ssn
    });
  },
};
</script>
