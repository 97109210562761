<template>
  <div class="container mx-auto">
    <div class="flex gap-3xl">
      <div class="flex-grow">
        <div>
          <div class="w-full">
            <div class="grid grid-cols-3 tab-group">
              <div class="tab" :class="isActiveTab('cards') ? 'active' : ''" @click="selectTab('cards')">
                <h5 class="text-center">Cards</h5>
              </div>
              <div class="tab" :class="isActiveTab('payment-history') ? 'active' : ''"
                @click="selectTab('payment-history')">
                <h5 class="text-center">Payment History</h5>
              </div>
            </div>
          </div>

          <div class="bg-white rounded-b-lg p-xl">
            <div>
              <div v-if="selectedTab == 'cards'">
                <CreditCards />
              </div>
              <div v-if="selectedTab == 'payment-history'">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="width: 265px">
        <SideNav />
      </div>
    </div>
  </div>
</template>
<script>
import CreditCards from "./billing/credit_cards.vue";
import SideNav from "./settings/side_nav.vue";

export default {
  components: {
    CreditCards,
    SideNav
  },
  data: () => {
    return {
      selectedTab: "cards",
    };
  },
  methods: {
    isActiveTab(tab) {
      return this.selectedTab === tab;
    },
    selectTab(tab) {
      this.selectedTab = tab;
    },
  },
};
</script>
