<template>
  <div class="container mx-auto">
    <div class="grid grid-cols-1 md:gap-4 md:mt-12 md:grid-cols-3">
      <div class="col-span-2">
        <div>
          <div class="mb-3 text-4xl font-semibold">Available Credit</div>
          <p>
            Your available credit limit is the total limit subtracting your current total balance. Your available credit
            limit helps lenders understand why you are seeking new credit. Maxing out every line of credit sends a
            signal that you are borrowing money faster than you are repaying it. Which doesn't mean you can't apply or
            qualify for new lines of credit. Read below to see the calculation of your available credit limit.
          </p>
        </div>

        <div class="my-6 text-sm font-bold text-gray-500">
          <span>Factors</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="inline-block w-3 h-3 mx-2">
            <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3" />
          </svg>
          <span>Available Credit</span>
        </div>

        <div class="text-xl font-semibold">How is your available credit calculated?</div>
        <p>
          First, we add up the total limit on your credit cards to calculate your total credit limit. Next, we add up
          the balances on those same accounts. When we subtract the balances from the limits we end up with the total
          available credit limit.
        </p>

        <div class="my-3 text-center">
          <div class="flex justify-center gap-2">
            <div>
              <div class="text-2xl text-gray-500" v-if="canShowAvailableCredit">
                {{ usd(totalCreditLimit) }}
              </div>
              <div class="font-semibold">Total Credit</div>
            </div>
            <div class="flex flex-col"><div class="mt-auto">-</div></div>
            <div>
              <div class="text-2xl text-gray-500" v-if="canShowAvailableCredit">
                {{ usd(totalUsedCredit) }}
              </div>
              <div class="font-semibold">Used Credit</div>
            </div>
            <div class="flex flex-col"><div class="mt-auto">=</div></div>
            <div>
              <div class="text-2xl text-gray-500" v-if="canShowAvailableCredit">
                {{ usd(totalAvailableCredit) }}
              </div>
              <div class="font-semibold">Available Credit</div>
            </div>
          </div>
        </div>

        <div v-if="store.creditReport">
          <div class="text-xl font-semibold">Your credit status</div>
          <p v-if="store.scoreFactors.availableCredit.status == 'good'">You have an excellent available credit limit.</p>
          <p v-if="store.scoreFactors.availableCredit.status == 'fair'">
            Your available credit is good, but it could be better. Paying down balances or increasing your
            credit limit will improve your Available Credit, and your overall Credit Score.
          </p>
          <p v-if="store.scoreFactors.availableCredit.status == 'poor'">
            Your available credit is low. Paying down balances or increasing your credit limit will improve
            your Available Credit, and your overall Credit Score.
          </p>
          <p v-if="!canShowAvailableCredit">
            We weren't able to assess the impact of your available credit because we didn't find any open lines of revolving credit.
          </p>
        </div>

        <div v-if="store.creditReport">
          <div class="my-5 text-xl font-semibold">Available credit by account</div>

          <table class="table w-full table-fixed">
            <thead>
              <tr>
                <th class="py-2 text-left">Account</th>
                <th class="py-2 text-left">Limit</th>
                <th class="py-2 text-left">Balance</th>
                <th class="py-2 text-left">Available</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(creditCard, i) in openRevolvingAccounts" :key="i">
                <td class="font-semibold">{{ creditCard.creditorName }}</td>
                <td>{{ usd(creditCard.creditLimitAmount) }}</td>
                <td>{{ usd(creditCard.balanceAmount) }}</td>
                <td>{{ usd(creditCard.creditLimitAmount - creditCard.balanceAmount) }}</td>
              </tr>
            </tbody>
            <tfoot v-if="openRevolvingAccounts.length == 0">
              <td colspan="4" class="py-3 text-center">You have no open credit cards</td>
            </tfoot>
          </table>
        </div>

        <div class="mt-3 text-xl font-semibold">How do you compare by total credit limit?</div>
        <div class="mt-4 -mb-3">
          <div class="relative overflow-hidden not-prose bg-slate-50 rounded-xl dark:bg-slate-800/25">
            <div
              style="background-position: 10px 10px"
              class="absolute inset-0 bg-grid-slate-100 [mask-image:linear-gradient(0deg,#fff,rgba(255,255,255,0.6))] dark:bg-grid-slate-700/25 dark:[mask-image:linear-gradient(0deg,rgba(255,255,255,0.1),rgba(255,255,255,0.5))]"></div>
            <div class="relative overflow-auto rounded-xl">
              <div class="my-8 overflow-hidden shadow-sm">
                <table class="data-table rounded-xl">
                  <thead>
                    <tr>
                      <th colspan="3" class="table-title">Average Total Credit Limits by Generation</th>
                    </tr>
                    <tr>
                      <th class="column-header">Generation</th>
                      <th class="column-header">Average FICO&reg; Score</th>
                      <th class="column-header">Average Total Credit Limit Per Consumer</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Generation Z (18-22)</td>
                      <td>667</td>
                      <td>$8,062</td>
                    </tr>
                    <tr>
                      <td>Millenials (23-38)</td>
                      <td>668</td>
                      <td>$20,647</td>
                    </tr>
                    <tr>
                      <td>Generation X (39-54)</td>
                      <td>688</td>
                      <td>$33,357</td>
                    </tr>
                    <tr>
                      <td>Baby Boomers (55-73)</td>
                      <td>731</td>
                      <td>$39,919</td>
                    </tr>
                    <tr>
                      <td>Silent Generation (74+)</td>
                      <td>756</td>
                      <td>$32,338</td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colspan="3">*Source: Experian data from Q2 2019</td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
            <div
              class="absolute inset-0 border pointer-events-none border-black/5 rounded-xl dark:border-white/5"></div>
          </div>
        </div>
      </div>
      <div class="order-first md:order-last">
        <div v-if="canShowAvailableCredit">
          <div class="rounded-md">
            <BureauTabs :bureau="bureau" @select-bureau="(newBureau) => (bureau = newBureau)" />
            <Transition name="fade" mode="out-in">
              <div
                v-bind:class="`text-center py-5 rounded-b-md border border-t-0 bg-gradient-${store.scoreFactors.availableCredit.status}`" :key="bureau">
                <div class="my-3 text-4xl font-semibold">{{ usd(totalAvailableCredit) }}</div>
                <div class="text-sm">Updated: {{ formatDate(store.creditReport.reportDate[bureau], "MMM D, YYYY") }}</div>
                <div
                  v-bind:class="`my-3 credit-score-factor-status credit-score-factor-status-${store.scoreFactors.availableCredit.status}`">
                  {{ store.scoreFactors.availableCredit.status }}
                </div>
              </div>
            </Transition>
          </div>
        </div>
        <div v-else class="px-5 py-12 text-center text-gray-500 border rounded-md">
          We weren't able to calculate the available credit for accounts on your credit report.
        </div>
        <SideNav />
      </div>
    </div>
  </div>
</template>

<script>
import { store } from "../store"
import SideNav from "./side_nav.vue";
import BureauTabs from "../bureau_tabs.vue";

export default {
  components: {
    SideNav,
    BureauTabs,
  },
  data() {
    return {
      bureau: "tuc",
      store
    };
  },
  computed: {
    openRevolvingAccounts() {
      if (!store.creditReport) {
        return [];
      }
      // A credit card account is considered closed if the status is not "Open" or if the limit is 0
      return store.creditReport.tradelines
        .filter(
          (tradeline) =>
            tradeline[this.bureau].creditType === "Revolving Account" &&
            tradeline[this.bureau].accountOpenClosed === "Open" &&
            tradeline[this.bureau].creditLimitAmount > 0
        )
        .map((tradeline) => tradeline[this.bureau]);
    },
    canShowAvailableCredit() {
      return store.creditReport && this.openRevolvingAccounts.length > 0;
    },
    totalCreditLimit() {
      return this.openRevolvingAccounts.reduce((total, creditCard) => {
        return total + creditCard.creditLimitAmount;
      }, 0);
    },
    totalUsedCredit() {
      return this.openRevolvingAccounts.reduce((total, creditCard) => {
        return total + creditCard.balanceAmount;
      }, 0);
    },
    totalAvailableCredit() {
      return this.totalCreditLimit - this.totalUsedCredit;
    },
  },
};
</script>
