<template>
  <div>
    <h4>Account Details</h4>
    <div class="pill-group my-sm w-full">
      <div class="pill flex-grow" :class="accountOpenClosed == 'Open' ? 'active' : ''"
        @click="accountOpenClosed = 'Open'">
        <span>Open</span>
      </div>
      <div class="pill flex-grow" :class="accountOpenClosed == 'Closed' ? 'active' : ''"
        @click="accountOpenClosed = 'Closed'">
        <span>Closed</span>
      </div>
      <div class="pill flex-grow" :class="accountOpenClosed == 'All' ? 'active' : ''"
        @click="accountOpenClosed = 'All'">
        <span>All</span>
      </div>
    </div>
    <div>
      <div>
        <!-- Header Row -->
        <div>
          <div class="flex flex-row border-b px-sm">
            <div class="flex-grow px-3 py-1 md:py-3">
              <div class="comment-s text-muted-2">Account</div>
            </div>
            <div class="w-48 px-3 py-1 md:py-3">
              <div class="comment-s text-muted-2">Payment Status</div>
            </div>
            <div class="w-48 px-3 py-1 text-left md:py-3 md:text-right">
              <div class="comment-s text-muted-2">Date Opened</div>
            </div>
            <div class="w-48 px-3 py-1 text-left md:py-3 md:text-right">
              <div class="comment-s text-muted-2">High Balance</div>
            </div>
            <div class="w-48 px-3 py-1 text-left md:py-3 md:text-right">
              <div class="comment-s text-muted-2">Balance</div>
            </div>
          </div>
        </div>
        <!-- End Header Row -->
        <div v-if="filteredTradelines.length == 0" class="py-3 text-center border-b text-zinc-600">
          <em>No accounts available to display</em>
        </div>
        <div v-for="(tradeline, i) in filteredTradelines" class="bg-white rounded-xl p-sm my-sm" :key="i">
          <div class="flex flex-col cursor-pointer md:flex-row" @click="tradeline.showDetails = !tradeline.showDetails">
            <div class="flex-grow text-pure-black px-3">
              <div class="comment font-semibold">
                {{ tradeline.creditorName }}
                <CaretDown class="hidden w-5 h-4 md:inline-block" />
              </div>
              <div class="text-sm">Account: {{ tradeline.accountNumber }}</div>
              <div class="text-sm" v-if="tradeline.originalCreditorName">
                Original Creditor: {{ tradeline.originalCreditorName }}
              </div>
            </div>
            <div class="w-48 px-3 py-1">
              <div class="comment">
                {{ tradeline.paymentStatus }}
              </div>
            </div>
            <div class="w-48 px-3 py-1 text-left md:text-right">
              <div class="comment">
                {{ formatDate(tradeline.dateOpened, "MM/DD/YYYY") || "-" }}
              </div>
            </div>
            <div class="w-48 px-3 py-1 text-left md:text-right">
              <div class="comment">{{ usd(tradeline.highBalanceAmount) }}</div>
            </div>
            <div class="w-48 px-3 py-1 text-left md:text-right">
              <div class="comment">{{ usd(tradeline.balanceAmount) }}</div>
            </div>
          </div>
          <div class="block py-2 text-center md:hidden" @click="tradeline.showDetails = !tradeline.showDetails">
            <CaretDown class="inline-block w-5 h-4 text-black" :class="tradeline.showDetails ? 'rotate-180' : ''" />
            <span class="text-xs">{{ tradeline.showDetails ? "Hide" : "Show" }} Details</span>
            <CaretDown class="inline-block w-5 h-4 text-black" :class="tradeline.showDetails ? 'rotate-180' : ''" />
          </div>
          <Transition name="slide">
            <div v-if="tradeline.showDetails" class="flex flex-col md:flex-row p-sm gap-sm">
              <div class="whitespace-nowrap flex-1">
                <div class="my-sm">
                  <div class="comment-xs">Credit Type</div>
                  <div class="comment text-pure-black">{{ tradeline.creditType }}</div>
                </div>
                <div class="my-sm">
                  <div class="comment-xs">Date Reported</div>
                  <div class="comment text-pure-black">
                    {{ formatDate(tradeline.dateReported, "MM/DD/YYYY") || "-" }}
                  </div>
                </div>
                <div class="my-sm" v-if="tradeline.creditLimitAmount">
                  <div class="comment-xs">Credit Limit</div>
                  <div class="comment text-pure-black">
                    {{ usd(tradeline.creditLimitAmount) }}
                  </div>
                </div>
                <div class="my-sm" v-if="tradeline.pastDueAmount">
                  <div class="comment-xs">Amount Past Due</div>
                  <div class="comment text-pure-black">{{ usd(tradeline.pastDueAmount) }}</div>
                </div>
                <div class="my-sm" v-if="tradeline.monthlyPaymentAmount">
                  <div class="comment-xs">Monthly Payment</div>
                  <div class="comment text-pure-black">
                    {{ usd(tradeline.monthlyPaymentAmount) }}
                  </div>
                </div>
                <div class="my-sm" v-if="tradeline.remarks.length > 0">
                  <div class="mb-1" v-for="(remark, i) in tradeline.remarks" :key="i">{{ remark }}</div>
                </div>
              </div>
              <div class="flex flex-wrap gap-2" v-if="tradeline.paymentHistory.length > 0">
                <div class="p-sm border text-center rounded-lg w-24 h-22"
                  v-for="(paymentData, i) in tradeline.paymentHistory" :key="i">
                  <h5 :class="paymentStatusClass(paymentData.status)"
                    :title="paymentStatusDescription(paymentData.status)">
                    {{ paymentStatusText(paymentData.status) }}
                  </h5>
                  <div class="comment-s whitespace-nowrap">
                    {{ formatDate(paymentData.date, "MMM 'YY") }}
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { store } from "../store";
import moment from "moment";
import CaretDown from "../../shared/caret_down.vue";
import PaymentStatusDetails from "../../../lib/payment_status_details";

export default {
  props: ["bureau"],
  components: {
    CaretDown,
  },
  data: function () {
    return {
      accountOpenClosed: "Open",
      store,
    };
  },
  computed: {
    tradelines() {
      return store.creditReport.tradelines;
    },
    filteredTradelines() {
      // Return tradelines that have data for the current bureau and match the filters
      return this.tradelines
        .filter((tradeline) => {
          return (
            tradeline[this.bureau].creditorName &&
            this.matchTradelineStatus(tradeline[this.bureau], this.accountOpenClosed)
          );
        })
        .map((tradeline) => tradeline[this.bureau]);
    },
  },
  methods: {
    matchTradelineStatus(tradelineData, status) {
      if (status == "All") {
        return true;
      }

      // Collection accounts should be considered closed.
      const isCollectionAccount = tradelineData.creditType == "Collection Account";

      if (status == "Open") {
        return tradelineData.accountOpenClosed == "Open" && !isCollectionAccount;
      } else {
        return tradelineData.accountOpenClosed == "Closed" || isCollectionAccount;
      }
    },
    paymentStatusClass(status) {
      return PaymentStatusDetails[status]?.class_v2 || "text-gray-400";
    },
    paymentStatusText(status) {
      return PaymentStatusDetails[status]?.text || "";
    },
    paymentStatusDescription(status) {
      return PaymentStatusDetails[status]?.details || "Unknown";
    },
    timestampToDateString(i) {
      return moment(i).format("MMM D, YYYY");
    },
    toggleDetails(ev, i) {
      document.getElementById(`tradeline-${i}`).classList.toggle("hidden");

      if (ev.target.innerText === "Show Details") {
        ev.target.innerText = "Hide Details";
      } else {
        ev.target.innerText = "Show Details";
      }
    },
  },
};
</script>
