<template>
  <div>
    <div v-for="factor in store.scoreFactors" v-bind:key="factor.path">
      <a v-bind:href="factor.path" class="block my-md comment text-black">
        <div
          v-bind:class="{
            'font-bold': isCurrentHash(factor.path),
          }"
        >
          {{ factor.label }}
        </div>
      </a>
    </div>
  </div>
</template>

<script>
import { store } from "../store";
export default {
  data() {
    return {
      store,
    };
  },
  methods: {
    isCurrentHash: function (s) {
      return window.location.hash == s;
    },
  },
};
</script>
