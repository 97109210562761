<template>
  <div class="container mx-auto">
    <div class="flex gap-3xl">
      <div class="flex-grow">
        <div class="flex md:flex-row flex-col gap-xl">
          <div class="flex-shrink">
            <h3>Age of Credit</h3>
            <p class="my-sm">
              The age of the oldest active account on your credit report is an important factor in your credit score and
              is commonly referred to as your "credit age" or "length of credit history." Lenders do indeed like to see
              a long and healthy credit history because it demonstrates your ability to manage credit responsibly over
              time.
            </p>
          </div>
          <div style="min-width: 200px">
            <div v-if="canShowAgeOfCredit">
              <FactorSummary
                :displayValue="ageOfCredit"
                :statusText="store.scoreFactors.ageOfCredit.status"
                :lastUpdated="store.creditReport.reportDate[bureau]"
              />
            </div>
          </div>
        </div>

        <div class="my-sm">
          <BureauTabs :bureau="bureau" @select-bureau="(newBureau) => (bureau = newBureau)" />

          <div v-if="store.creditReport" class="bg-white p-xl">
            <table class="table table-noborder w-full table-fixed">
              <thead>
                <tr>
                  <th class="text-left">Account</th>
                  <th class="text-left">Opened</th>
                  <th class="text-left">Utilization</th>
                </tr>
              </thead>
              <tbody class="text-pure-black">
                <tr v-for="(tradeline, i) in openTradelines" :key="i">
                  <td class="font-bold">{{ tradeline[bureau].creditorName }}</td>
                  <td>
                    {{ formatDate(tradeline[bureau].dateOpened, "MMM D, YYYY") }}
                  </td>
                  <td>{{ distanceOfTime(tradeline[bureau].dateOpened) }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div style="min-width: 265px">
        <SideNav />

        <div class="border-l-4 border-gray-800 p-sm my-sm">
          <p class="comment-s font-bold mb-sm">
            Your age of credit is calculated by using the age of your oldest open account.
          </p>

          <div class="comment-s">Current Date - Account Opened = Age of credit</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { store } from "../store";
import moment from "moment";
import SideNav from "./side_nav.vue";
import BureauTabs from "../bureau_tabs.vue";
import FactorSummary from "./factor_summary.vue";

export default {
  components: {
    SideNav,
    BureauTabs,
    FactorSummary,
  },
  data() {
    return {
      bureau: "tuc",
      store,
    };
  },
  computed: {
    canShowAgeOfCredit() {
      return store.creditReport && this.oldestOpenAccountAge;
    },
    openTradelines() {
      if (!store.creditReport) {
        return [];
      }

      return store.creditReport.tradelines.filter((x) => x[this.bureau].accountOpenClosed == "Open");
    },
    oldestOpenAccountAge() {
      // Since the dateOpened string is always in YYYY-MM-DD format, we can just
      // sort the array and return the first element.
      return this.openTradelines.map((x) => x[this.bureau].dateOpened).sort()[0];
    },
    ageOfCredit() {
      return this.distanceOfTime(this.oldestOpenAccountAge);
    },
  },
  methods: {
    distanceOfTime(isoDateString) {
      if (!isoDateString || !isoDateString.match(/^\d{4}-\d{2}-\d{2}$/)) {
        return null;
      }

      const d = moment(isoDateString);
      return moment().diff(d, "years", true).toFixed(1) + " years";
    },
    formattedDate(dateString = null) {
      if (!dateString) {
        return moment().format("MMM D, YYYY");
      }

      return moment(dateString).format("MMM D, YYYY");
    },
  },
};
</script>
