<template>
  <div class="mt-5">
    <h3 class="mt-5 mb-1 text-base font-semibold md:text-lg">Account Details</h3>
    <div class="grid grid-cols-3 tab-container">
      <div class="tab" :class="accountOpenClosed == 'Open' ? 'active' : ''" @click="accountOpenClosed = 'Open'">
        Open
      </div>
      <div class="tab" :class="accountOpenClosed == 'Closed' ? 'active' : ''" @click="accountOpenClosed = 'Closed'">
        Closed
      </div>
      <div class="tab" :class="accountOpenClosed == 'All' ? 'active' : ''" @click="accountOpenClosed = 'All'">
        All
      </div>
    </div>
    <div class="border border-t-0 border-b-0">
      <div>
        <div v-if="filteredTradelines.length == 0" class="py-3 text-center border-b text-zinc-600">
          <em>No accounts available to display</em>
        </div>
        <div v-for="(tradeline, i) in filteredTradelines" class="border-b" :key="i">
          <div
            class="flex flex-col cursor-pointer md:flex-row hover:bg-gray-100"
            @click="tradeline.showDetails = !tradeline.showDetails">
            <div class="flex-grow px-3 py-1 md:py-5">
              <div class="text-lg font-semibold md:text-xl text-sky-500">
                {{ tradeline.creditorName }}
                <CaretDown class="hidden w-5 h-4 text-black md:inline-block" />
              </div>
              <div class="text-sm">Account: {{ tradeline.accountNumber }}</div>
              <div class="text-sm" v-if="tradeline.originalCreditorName">Original Creditor: {{ tradeline.originalCreditorName }}</div>
            </div>
            <div class="w-48 px-3 py-1 md:py-5">
              <div
                class="text-lg font-medium"
                :class="tradeline.paymentStatus == 'Current' ? 'text-lime-600' : 'text-red-600'">
                {{ tradeline.paymentStatus }}
              </div>
              <div class="font-sans text-xs uppercase">Payment Status</div>
            </div>
            <div class="w-48 px-3 py-1 text-left md:py-5 md:text-right">
              <div class="text-lg font-semibold md:text-xl text-zinc-500">{{ formatDate(tradeline.dateOpened, "MM/DD/YYYY") || "-" }}</div>
              <div class="font-sans text-xs uppercase">Date Opened</div>
            </div>
            <div class="w-48 px-3 py-1 text-left md:py-5 md:text-right">
              <div class="text-lg font-semibold md:text-xl text-zinc-500">{{ usd(tradeline.highBalanceAmount) }}</div>
              <div class="font-sans text-xs uppercase">High Balance</div>
            </div>
            <div class="w-48 px-3 py-1 text-left md:py-5 md:text-right">
              <div class="text-lg font-semibold md:text-xl text-zinc-500">{{ usd(tradeline.balanceAmount) }}</div>
              <div class="font-sans text-xs uppercase">Balance</div>
            </div>
          </div>
          <div class="block py-2 text-center md:hidden" @click="tradeline.showDetails = !tradeline.showDetails">
            <CaretDown class="inline-block w-5 h-4 text-black" :class="tradeline.showDetails ? 'rotate-180' : ''" />
            <span class="text-xs">{{ tradeline.showDetails ? "Hide" : "Show" }} Details</span>
            <CaretDown class="inline-block w-5 h-4 text-black" :class="tradeline.showDetails ? 'rotate-180' : ''"  />
          </div>
          <Transition name="slide">
            <div v-if="tradeline.showDetails">
              <div class="flex flex-col md:flex-row">
                <div class="flex-grow px-3 py-2 md:py-3">
                  <div class="text-lg font-semibold md:text-xl text-zinc-500">{{ tradeline.creditType }}</div>
                  <div class="font-sans text-xs uppercase">Credit Type</div>
                </div>
                <div class="w-48 px-3 py-2 md:py-3">
                  <div class="text-lg font-semibold md:text-xl text-zinc-500">{{ formatDate(tradeline.dateReported, "MM/DD/YYYY") || "-" }}</div>
                  <div class="font-sans text-xs uppercase">Date Reported</div>
                </div>
                <div class="w-48 px-3 py-2 text-left md:py-3 md:text-right" :class="tradeline.creditLimitAmount ? '' : 'opacity-0'">
                  <div class="text-lg font-semibold md:text-xl text-zinc-500">{{ usd(tradeline.creditLimitAmount) }}</div>
                  <div class="font-sans text-xs uppercase">Credit Limit</div>
                </div>
                <div class="w-48 px-3 py-2 text-left md:py-3 md:text-right" :class="tradeline.pastDueAmount ? '' : 'opacity-0'">
                  <div class="text-lg font-semibold md:text-xl text-zinc-500">{{ usd(tradeline.pastDueAmount) }}</div>
                  <div class="font-sans text-xs uppercase">Amount Past Due</div>
                </div>
                <div class="w-48 px-3 py-2 text-left md:py-3 md:text-right" :class="tradeline.monthlyPaymentAmount ? '' : 'opacity-0'">
                  <div class="text-lg font-semibold md:text-xl text-zinc-500">{{ usd(tradeline.monthlyPaymentAmount) }}</div>
                  <div class="font-sans text-xs uppercase">Monthly Payment</div>
                </div>
              </div>
              <div class="p-3" v-if="tradeline.paymentHistory.length > 0">
                <div class="font-bold">Payment History</div>

                <div class="overflow-x-auto">
                  <table class="table w-auto mt-3 text-sm">
                    <thead>
                      <tr>
                        <template v-for="(paymentData, i) in tradeline.paymentHistory" :key="i">
                          <th class="px-2 text-xs text-center border-r !border-gray-300 whitespace-nowrap">
                            {{ formatDate(paymentData.date, "MMM 'YY") }}
                          </th>
                        </template>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <template v-for="(paymentData, i) in tradeline.paymentHistory" :key="i">
                          <td class="px-2 text-center border-r" :class="paymentStatusClass(paymentData.status)" :title="paymentStatusDescription(paymentData.status)">
                            {{ paymentStatusText(paymentData.status) }}
                          </td>
                        </template>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { store } from "../store";
import moment from "moment";
import CaretDown from "../../shared/caret_down.vue";
import PaymentStatusDetails from "../../../lib/payment_status_details"

export default {
  props: ["bureau"],
  components: {
    CaretDown,
  },
  data: function () {
    return {
      accountOpenClosed: "Open",
      store
    };
  },
  computed: {
    tradelines(){
      return store.creditReport.tradelines
    },
    filteredTradelines() {
      // Return tradelines that have data for the current bureau and match the filters
      return this.tradelines
        .filter((tradeline) => {
          return (
            tradeline[this.bureau].creditorName &&
            this.matchTradelineStatus(tradeline[this.bureau], this.accountOpenClosed)
          );
        })
        .map((tradeline) => tradeline[this.bureau]);
    },
  },
  methods: {
    matchTradelineStatus(tradelineData, status) {
      if (status == "All") {
        return true;
      }

      // Collection accounts should be considered closed.
      const isCollectionAccount = tradelineData.creditType == "Collection Account";

      if(status == "Open"){
        return tradelineData.accountOpenClosed == "Open" && !isCollectionAccount;
      }else{
        return tradelineData.accountOpenClosed == "Closed" || isCollectionAccount;
      }
    },
    paymentStatusClass(status) {
      return PaymentStatusDetails[status]?.class || "text-gray-400";
    },
    paymentStatusText(status) {
      return PaymentStatusDetails[status]?.text || "";
    },
    paymentStatusDescription(status) {
      return PaymentStatusDetails[status]?.details || "Unknown";
    },
    timestampToDateString(i) {
      return moment(i).format("MMM D, YYYY");
    },
    toggleDetails(ev, i) {
      document.getElementById(`tradeline-${i}`).classList.toggle("hidden");

      if (ev.target.innerText === "Show Details") {
        ev.target.innerText = "Hide Details";
      } else {
        ev.target.innerText = "Show Details";
      }
    },
  },
};
</script>
