export default function(amount: number | null){
  if(amount == null){ return null }
  if(isNaN(Number(amount))){ return null }

  const usd = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  })

  return usd.format(amount)
}
