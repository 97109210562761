// This file maps credit score factor codes to their corresponding category

export default {
"4": "utilization",
"5": "paymentHistory",
"6": "negativeMarks",
"7": "negativeMarks",
"8": "negativeMarks",
"9": "negativeMarks",
"10": "negativeMarks",
"11": "paymentHistory",
"12": "ageOfCredit",
"13": "ageOfCredit",
"14": "ageOfCredit",
"15": "negativeMarks",
"16": "utilization",
"17": "paymentHistory",
"18": "utilization",
"21": "Age of Credit",
"22": "ageOfCredit",
"23": "ageOfCredit",
"29": "availableCredit",
"30": "availableCredit",
"31": "ageOfCredit",
"32": "utilization",
"33": "negativeMarks",
"34": "utilization",
"35": "utilization",
"36": "availableCredit",
"39": "availableCredit",
"40": "ageOfCredit",
"42": "Age of Credit",
"43": "ageOfCredit",
"44": "paymentHistory",
"45": "paymentHistory",
"47": "ageOfCredit",
"48": "paymentHistory",
"49": "ageOfCredit",
"53": "negativeMarks",
"54": "utilization",
"55": "utilization",
"57": "paymentHistory",
"58": "utilization",
"61": "ageOfCredit",
"62": "ageOfCredit",
"63": "ageOfCredit",
"64": "ageOfCredit",
"65": "ageOfCredit",
"66": "utilization",
"68": "ageOfCredit",
"69": "ageOfCredit",
"71": "paymentHistory",
"72": "paymentHistory",
"73": "negativeMarks",
"74": "paymentHistory",
"75": "ageOfCredit",
"76": "ageOfCredit",
"77": "paymentHistory",
"78": "utilization",
"79": "negativeMarks",
"81": "ageOfCredit",
"83": "ageOfCredit",
"84": "inquiries",
"85": "inquiries",
"86": "negativeMarks",
"87": "negativeMarks",
"88": "negativeMarks",
"90": "negativeMarks",
"93": "negativeMarks",
"94": "utilization",
"95": "negativeMarks",
"96": "utilization",
"97": "ageOfCredit",
"98": "negativeMarks",
"P04": "utilization",
"P05": "paymentHistory",
"P06": "ageOfCredit",
"P07": "ageOfCredit",
"P08": "paymentHistory",
"P09": "paymentHistory",
"P10": "paymentHistory",
"P11": "ageOfCredit",
"P12": "ageOfCredit",
"P13": "ageOfCredit",
"P14": "ageOfCredit",
"P15": "paymentHistory",
"P16": "utilization",
"P17": "utilization",
"P18": "paymentHistory",
"P21": "ageOfCredit",
"P22": "paymentHistory",
"P23": "ageOfCredit",
"P29": "availableCredit",
"P30": "availableCredit",
"P31": "ageOfCredit",
"P32": "utilization",
"P33": "negativeMarks",
"P34": "utilization",
"P35": "utilization",
"P36": "availableCredit",
"P39": "availableCredit",
"P40": "ageOfCredit",
"P42": "ageOfCredit",
"P43": "ageOfCredit",
"P44": "negativeMarks",
"P45": "negativeMarks",
"P47": "ageOfCredit",
"P48": "paymentHistory",
"P49": "ageOfCredit",
"P50": "ageOfCredit",
"P52": "ageOfCredit",
"P53": "ageOfCredit",
"P54": "utilization",
"P55": "utilization",
"P57": "ageOfCredit",
"P58": "utilization",
"P61": "ageOfCredit",
"P62": "ageOfCredit",
"P63": "ageOfCredit",
"P64": "ageOfCredit",
"P65": "ageOfCredit",
"P66": "utilization",
"P68": "ageOfCredit",
"P69": "ageOfCredit",
"P70": "ageOfCredit",
"P71": "paymentHistory",
"P72": "negativeMarks",
"P73": "negativeMarks",
"P74": "utilization",
"P75": "ageOfCredit",
"P76": "ageOfCredit",
"P77": "ageOfCredit",
"P78": "paymentHistory",
"P79": "negativeMarks",
"P81": "ageOfCredit",
"P83": "ageOfCredit",
"P85": "inquiries",
"P86": "negativeMarks",
"P87": "negativeMarks",
"P88": "negativeMarks",
"P90": "negativeMarks",
"P92": "ageOfCredit",
"P93": "negativeMarks",
"P94": "utilization",
"P95": "paymentHistory",
"P96": "negativeMarks",
"P98": "negativeMarks",


}
