<template>
  <div class="container mx-auto">
    <div>
      <div>
        <div class="mb-3 text-4xl font-medium">Profile</div>
      </div>

      <div class="mb-3 alert alert-error" v-if="errors.length > 0">
        <div v-for="(error, i) in errors" :key="i">{{ error }}</div>
      </div>

      <div class="grid grid-cols-1 gap-3 md:grid-cols-2">
        <div>
          <div>
            <div class="mb-3 text-xl font-medium">Personal Info</div>
          </div>
          <div class="mb-3">
            <label class="mb-1">Email Address (used for logging in)</label>
            <div><input type="text" class="max-w-lg" v-model="store.profile.email" /></div>
          </div>
          <div class="mb-3">
            <label class="mb-1">Email Address (used for communications)</label>
            <div><input type="text" class="max-w-lg" v-model="store.profile.alt_email" /></div>
          </div>
          <div class="mb-3">
            <label class="mb-1">First Name</label>
            <div><input type="text" class="max-w-lg" v-model="store.profile.firstName" /></div>
          </div>
          <div class="mb-3">
            <label class="mb-1">Last Name</label>
            <div><input type="text" class="max-w-lg" v-model="store.profile.lastName" /></div>
          </div>
          <button type="button" v-on:click="updateProfile()" v-if="!showProfileSpinner"
            class="mt-3 btn btn-lg btn-primary">
            Save Changes
          </button>
          <Spinner v-if="showProfileSpinner" />
        </div>
        <div>
          <div>
            <div class="mb-3 text-xl font-medium">Change Password</div>
          </div>
          <div class="mb-3">
            <label class="mb-1">Current Password</label>
            <div><input type="password" class="max-w-lg" v-model="currentPassword" /></div>
          </div>
          <div class="mb-3">
            <label class="mb-1">New Password</label>
            <div><input type="password" class="max-w-lg" v-model="newPassword" /></div>
          </div>
          <div class="mb-3">
            <label class="mb-1">Confirm New Password</label>
            <div><input type="password" class="max-w-lg" v-model="newPasswordConfirmation" /></div>
          </div>

          <button type="button" v-on:click="changePassword()" v-if="!showPasswordSpinner"
            class="mt-3 btn btn-lg btn-primary">
            Change Password
          </button>
          <Spinner v-if="showPasswordSpinner" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { store } from "./store";
import Spinner from "../shared/spinner_svg.vue";

export default {
  components: {
    Spinner,
  },
  props: [
  ],
  data: function () {
    return {
      currentPassword: "",
      newPassword: "",
      newPasswordConfirmation: "",
      errors: [],
      showProfileSpinner: false,
      showPasswordSpinner: false,
      store
    };
  },
  methods: {
    updateProfile() {
      this.showProfileSpinner = true;
      this.errors = [];
      axios
        .post("/portal/profile/update", {
          user: {
            first_name: store.profile.firstName,
            last_name: store.profile.lastName,
            email: store.profile.email,
            alt_email: store.profile.alt_email
          },
        })
        .then(() => {
          window.dispatchEvent(new CustomEvent("ss-global-notice", { detail: "Profile updated successfully." }));
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        })
        .finally(() => {
          this.showProfileSpinner = false;
        });
    },
    changePassword() {
      this.showPasswordSpinner = true;
      this.errors = [];
      axios
        .post("/portal/profile/change-password", {
          user: {
            current_password: this.currentPassword,
            password: this.newPassword,
            password_confirmation: this.newPasswordConfirmation
          },
        })
        .then(() => {
          window.dispatchEvent(new CustomEvent("ss-global-notice", { detail: "Password changed successfully." }));
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        })
        .finally(() => {
          this.showPasswordSpinner = false;
        });
    },
  },
};
</script>
