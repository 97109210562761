<template>
  <div id="dashboard-container" class="container mx-auto">
    <div class="flex flex-col gap-3 mt-3 md:flex-row" v-if="store.creditReport != null">
      <div class="w-full md:w-3/5">
        <!-- Credit Score Gauges -->
        <div class="p-3 border rounded-md">
          <div>
            <div>
              <div v-if="store.creditReport.singleBureauReport" class="grid grid-cols-1 gap-3 md:grid-cols-3">
                <div class="text-center">
                  <div class="w-full" id="dashboard.creditscore.transunion">
                    <ScoreGauge bureau="tuc" v-bind:score="store.creditReport.creditScore.tuc" />
                  </div>
                  <span class="text-xs text-gray-300" v-if="store.creditReport.creditScore.tuc > 0">
                    {{ lastUpdateText("tuc") }}
                  </span>
                </div>
                <div class="col-span-2 text-center bg-gray-100">
                  <h2 class="mt-10 mb-5 text-xl">Unlock your other credit scores!</h2>
                  <a href="#/" class="btn btn-xl btn-blue">Upgrade</a>
                </div>
              </div>
              <div v-else class="grid grid-cols-1 gap-3 text-center md:grid-cols-3">
                <div>
                  <div class="w-full" id="dashboard.creditscore.transunion">
                    <ScoreGauge bureau="tuc" v-bind:score="store.creditReport.creditScore.tuc" />
                  </div>
                  <span class="text-xs text-gray-300" v-if="store.creditReport.creditScore.tuc > 0">
                    {{ lastUpdateText("tuc") }}
                  </span>
                </div>
                <div>
                  <div class="w-full" id="dashboard.creditscore.experian">
                    <ScoreGauge bureau="exp" v-bind:score="store.creditReport.creditScore.exp" />
                  </div>
                  <span class="text-xs text-gray-300" v-if="store.creditReport.creditScore.exp > 0">
                    {{ lastUpdateText("exp") }}
                  </span>
                </div>
                <div>
                  <div class="w-full" id="dashboard.creditscore.equifax">
                    <ScoreGauge bureau="eqf" v-bind:score="store.creditReport.creditScore.eqf" />
                  </div>
                  <span class="text-xs text-gray-300" v-if="store.creditReport.creditScore.eqf > 0">
                    {{ lastUpdateText("eqf") }}
                  </span>
                </div>
              </div>
              <div class="mt-3 text-sm text-center text-gray-500" v-if="refreshReportAvailable && !fetchError">
                <div v-if="fetching">
                  <Spinner />
                </div>
                <div v-else>
                  Your new report is available!
                  <div class="my-1"><button class="btn btn-primary" @click="fetchNewReport()">Fetch New Report</button></div>
                </div>
              </div>
              <div v-else-if="fetchError" class="my-1 text-sm text-center text-red-600">
                An error occurred while fetching your new report. Please try again later.
              </div>
              <div v-else-if="reportRefreshDate" class="mt-3 text-sm text-center text-gray-600">See your new scores on {{ reportRefreshDate.format("MMMM D, YYYY") }}</div>
            </div>
          </div>
        </div>

        <div class="flex flex-col gap-3 mt-3 md:flex-row">
          <div class="w-full p-3 border rounded-md md:w-1/2">
            <CreditScoreHistoryGraph />
          </div>

          <div class="w-full p-3 border rounded-md md:w-1/2">
            <div class="text-lg font-semibold">Credit Score Factors</div>
            <CreditScoreFactors />
          </div>
        </div>
      </div>
      <div class="w-full md:w-2/5"><ImpactAreas /></div>
    </div>
    <MissingCreditReport v-if="!store.creditReport" :creditReportError="store.creditReportError" />
  </div>
</template>

<script>
import { store } from "../store.ts";
import moment from "moment";
import ImpactAreas from "./impact_areas.vue";
import CreditScoreFactors from "./credit_score_factors.vue";
import CreditScoreHistoryGraph from "./credit_score_history_graph.vue";
import ScoreGauge from "./score_gauge.vue";
import MissingCreditReport from "../missing_credit_report.vue";
import Spinner from "../../shared/spinner_svg.vue";

export default {
  components: {
    CreditScoreFactors,
    CreditScoreHistoryGraph,
    ImpactAreas,
    ScoreGauge,
    MissingCreditReport,
    Spinner,
  },
  props: [],
  computed: {
    // if reportRefreshDate is present and equal to or before today
    refreshReportAvailable() {
      return this.reportRefreshDate && this.reportRefreshDate.isSameOrBefore(new Date, "day");
    },
    reportRefreshDate() {
      if (store.profile.reportRefreshDate) {
        return moment(store.profile.reportRefreshDate);
      } else {
        return null;
      }
    },
  },
  methods: {
    lastUpdateText(bureau) {
      if (store.creditReport.reportDate[bureau]) {
        return `Last Update: ${moment(store.creditReport.reportDate[bureau]).format("MMMM D, YYYY")}`;
      } else {
        return "";
      }
    },
    newScoresDate() {
      if (store.profile.reportRefreshDate) {
        return `See your new scores on ${moment(store.creditReport.orderDate).add(1, "month").format("MMMM D, YYYY")}`;
      } else {
        return "";
      }
    },
    fetchNewReport() {
      // POST to /portal/credit-report/refresh
      this.fetching = true;
      fetch("/portal/credit-report/refresh", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": document.querySelector("meta[name=csrf-token]").content,
        },
        body: JSON.stringify({}),
      })
        .then((response) => response.text())
        .then(() => {
          window.location = "/portal";
        })
        .catch((error) => {
          console.log(error)
          this.fetchError = true;
          this.fetching = false;
        })
    },
  },
  data: function () {
    return {
      fetching: false,
      fetchError: false,
      store,
    };
  },
};
</script>
