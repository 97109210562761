<template>
  <div class="">
    <h3 class="mt-5 mb-1 text-base font-semibold md:text-lg">Recent Inquiries</h3>
    <div class="grid grid-cols-1 md:grid-cols-2">
      <template v-for="(inquiry, i) in filteredInquiries" :key="i">
        <div class="px-3 py-2 md:border-b md:py-3">
          <div class="text-lg font-semibold md:text-xl text-sky-500">{{ inquiry.name }}</div>
          <div class="font-sans text-xs uppercase">Creditor Name</div>
        </div>
        <div class="px-3 py-2 border-b md:py-3">
          <div class="text-lg font-semibold md:text-xl text-zinc-500">{{ formatDate(inquiry.date, "MM/DD/YYYY") }}</div>
          <div class="font-sans text-xs uppercase">Inquiry Date</div>
        </div>
      </template>
    </div>
    <div v-if="filteredInquiries.length == 0" class="mt-3 italic text-zinc-400">
      You have no credit inquiries in the last 2 years.
    </div>
  </div>
</template>

<script>
import { store } from '../store';

export default {
  data(){
    return {
      store,
    }
  },
  props: ["bureau"],
  computed: {
    filteredInquiries() {
      return store.creditReport.inquiries[this.bureau];
    },
  },
};
</script>
