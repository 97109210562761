<template>
  <div class="flex h-screen justify-center items-center">
    <div class="w-96 border shadow-md p-2 rounded-md" v-if="screen == 1">
      <img src="/images/logo.svg" class="w-48 mx-auto mt-6" />

      <div class="text-center my-3">
        <div class="text-lg text-gray-800 font-semibold">
          Let's begin by<br />finding your info.
        </div>

        <div class="text-gray-600 mt-1">
          We can pre-fill some of your info and<br />
          speed up the enrollment process.
        </div>
      </div>

      <div class="text-center">
        <input type="text" class="w-4/5 mx-auto my-1 border-slate-300 rounded-md" placeholder="Mobile Phone Number" />
        <input type="text" class="w-4/5 mx-auto my-1 border-slate-300 rounded-md" placeholder="Last 4 digits of your SSN" />
      </div>

      <div class="my-3">
        <div class="text-sm text-center mb-1 text-gray-600">By clicking Continue, you consent to receiving an SMS message for the purposes of verifying your identity</div>

        <button type="button" class="w-full bg-brand hover:bg-amber-400 p-3 text-lg font-semibold">
          Continue
        </button>
      </div>

      <div class="text-center mb-6">
        <a href="#" class="text-blue-600 font-bold underline">I don't have a mobile phone</a>

        <div class="my-3" />

        <a href="#" class="text-blue-600">Terms and Conditions</a> | <a href="#" class="text-blue-600">Privacy Policy</a>
      </div>
    </div>
    <div class="w-96 border shadow-md p-2 rounded-md" v-if="screen == 2">
      <img src="/images/logo.svg" class="w-48 mx-auto mt-6" />

      <div class="text-center my-3">
        <div class="text-lg text-gray-800 font-semibold">
          Check your phone.
        </div>

        <div class="text-gray-600 mt-1">
          A text message with a link was sent to the phone number ending in 8992
        </div>

        <div class="text-lg text-gray-800 font-semibold mt-1">
          The link will expire in 3:34
        </div>
      </div>

      <div class="my-3">
        <button type="button" class="w-full bg-brand hover:bg-amber-400 p-3 text-lg font-semibold">
          Resend Link
        </button>

        <button type="button" class="w-full border-brand border-4 text-brand p-3 mt-1 text-lg font-semibold">
          Go Back
        </button>
      </div>

      <div class="text-center mb-6">
        <a href="#" class="text-blue-600 font-bold underline">I didn't receive the text</a>

        <div class="my-3" />

        <a href="#" class="text-blue-600">Terms and Conditions</a> | <a href="#" class="text-blue-600">Privacy Policy</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: function(){
    return {
      screen: 2
    }
  }
}
</script>
