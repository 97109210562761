<template>
  <div class="container mx-auto">
    <div class="flex gap-3xl">
      <div class="flex-grow">
        <div class="flex md:flex-row flex-col gap-xl">
          <div class="flex-shrink">
            <h3>Credit Inquiries</h3>
            <p class="my-sm">
              The number of hard credit inquiries, often referred to as "hard pulls," is a significant factor in your
              credit report and can have an impact on your credit score and your ability to obtain credit.
            </p>
          </div>
          <div style="min-width: 200px">
            <div v-if="store.creditReport">
              <FactorSummary
                :displayValue="inquiriesLast6Months.length"
                :statusText="store.scoreFactors.creditInquiries.status"
                :lastUpdated="store.creditReport.reportDate[bureau]"
              />
            </div>
          </div>
        </div>

        <div class="my-sm">
          <BureauTabs :bureau="bureau" @select-bureau="(newBureau) => (bureau = newBureau)" />

          <div v-if="store.creditReport" class="bg-white p-xl">
            <table class="table table-noborder w-full table-fixed">
              <thead>
                <tr>
                  <th class="text-left">Name</th>
                  <th class="text-right">Date</th>
                </tr>
              </thead>
              <tbody class="text-pure-black">
                <tr v-for="(inquiry, i) in inquiriesLast6Months" :key="i">
                  <td class="font-bold">{{ inquiry.name }}</td>
                  <td class="text-right">{{ formatDate(inquiry.date, "MM/DD/YYYY") }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div>
          <div>
            <h3 class="my-md text-black">How are credit inquiries counted?</h3>
            <p class="my-sm">
              Usually inquiries clustered in a small time frame for a specific type of loan product will only count as a
              single inquiry in computing your credit score. In our website you'll see every inquiry is counted as an
              inquiry.
            </p>
            <p class="font-bold">To minimize the impact of hard inquiries on your credit</p>
            <ul class="ml-5 list-disc list-outside">
              <li>Be selective when applying for new credit, and only apply for credit that you truly need.</li>
              <li>
                Research and compare offers from different lenders before applying to minimize the number of inquiries.
              </li>
              <li>
                When shopping for credit (e.g., a mortgage or auto loan), try to complete your rate shopping within a
                short timeframe to ensure that multiple inquiries are treated as one.
              </li>
              <li>
                While hard inquiries can have a temporary impact on your credit, their significance tends to diminish
                over time. To maintain a strong credit profile, it's essential to manage your credit responsibly and
                keep hard inquiries to a reasonable minimum.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div style="min-width: 265px">
        <SideNav />

        <div class="border-l-4 border-gray-800 p-sm my-sm">
          <p class="comment-s font-bold mb-sm">
            Usually inquiries clustered in a small time frame for a specific type of loan product will only count as a
            single inquiry in computing your credit score. In our website you'll see every inquiry is counted
            separately.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { store } from "../store";
import SideNav from "./side_nav.vue";
import FactorSummary from "./factor_summary.vue";
import BureauTabs from "../bureau_tabs.vue";
import moment from "moment";

const sixMonthsAgo = moment().subtract(6, "months");

export default {
  components: {
    SideNav,
    FactorSummary,
    BureauTabs,
  },
  data() {
    return {
      bureau: "tuc",
      store,
    };
  },
  computed: {
    inquiriesLast6Months() {
      return store.creditReport.inquiries[this.bureau].filter((inquiry) => {
        return moment(inquiry.date).isAfter(sixMonthsAgo);
      });
    },
  },
};
</script>
