<template>
  <div>
    <div class="grid md:grid-cols-2 grid-cols-1">
      <div class="space-y-5">
        <div>
          <div class="comment-s text-muted">First Name</div>
          <div class="text-lg">{{ store.profile.firstName }}</div>
        </div>
        <div>
          <div class="comment-s text-muted">Last Name</div>
          <div class="text-lg">{{ store.profile.lastName }}</div>
        </div>
        <div>
          <div class="comment-s text-muted">Email Address</div>
          <div class="text-lg">{{ store.profile.email }}</div>
        </div>
        <div>
          <div class="comment-s text-muted">Phone Number</div>
          <div class="text-lg">{{ formattedPhoneNumber }}</div>
        </div>
      </div>
      <div class="space-y-5" v-if="store.profile.address">
        <div>
          <div class="comment-s text-muted">Address</div>
          <div class="text-lg">
            <div>{{ store.profile.address.line_1 }}</div>
            <div v-if="store.profile.address.line_2">{{ store.profile.address.line_2 }}</div>
          </div>
        </div>
        <div>
          <div class="comment-s text-muted">City</div>
          <div class="text-lg">{{ store.profile.address.city }}</div>
        </div>
        <div>
          <div class="comment-s text-muted">State</div>
          <div class="text-lg">{{ store.profile.address.state }}</div>
        </div>
        <div>
          <div class="comment-s text-muted">Zip</div>
          <div class="text-lg">{{ store.profile.address.zip }}</div>
        </div>
      </div>
    </div>
    <button type="button" v-on:click="$emit('edit-profile')" v-if="!showProfileSpinner" class="mt-5 btn btn-lg btn-primary">
      Edit
    </button>
  </div>

</template>
<script>
import { store } from "../store";

export default {
  data() {
    return {
      store,
    };
  },
  computed: {
    formattedPhoneNumber(){
      return this.store.profile.phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
    },
  }
}
</script>
