<template>
  <svg viewBox="6 6 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M26.1747 21.2421C25.6445 22.4958 24.8153 23.6007 23.7596 24.4598C22.7039 25.3191 21.4537 25.9067 20.1183 26.1711C18.7831 26.4356 17.4034 26.3689 16.0998 25.9769C14.7962 25.585 13.6085 24.8797 12.6405 23.9227C11.6725 22.9657 10.9537 21.7861 10.5469 20.4871C10.1401 19.1881 10.0577 17.8092 10.3069 16.471C10.5561 15.1328 11.1293 13.876 11.9764 12.8105C12.8235 11.745 13.9188 10.9032 15.1664 10.3588M26.1987 14.8114C26.5323 15.6168 26.7369 16.4683 26.8063 17.3345C26.8235 17.5485 26.8321 17.6555 26.7896 17.7519C26.7541 17.8324 26.6838 17.9086 26.6063 17.9504C26.5137 18.0004 26.3979 18.0004 26.1663 18.0004H19.1664C18.933 18.0004 18.8164 18.0004 18.7272 17.9551C18.6488 17.9151 18.585 17.8513 18.5451 17.7729C18.4997 17.6838 18.4997 17.5672 18.4997 17.3338V10.3338C18.4997 10.1022 18.4997 9.98647 18.5498 9.89378C18.5916 9.81637 18.6678 9.74604 18.7483 9.71054C18.8447 9.66805 18.9517 9.67663 19.1657 9.69378C20.0319 9.76323 20.8834 9.96787 21.6887 10.3015C22.6998 10.7203 23.6184 11.3341 24.3923 12.1079C25.1661 12.8817 25.7799 13.8004 26.1987 14.8114Z"
      stroke="currentColor"
      stroke-width="1.25"
      stroke-linecap="round"
      stroke-linejoin="round" />
  </svg>
</template>
<script>
export default {
  name: "PieIcon",
};
</script>
